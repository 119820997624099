import React from "react";
import { SkeletonLoader } from "v2/01-atoms";

type TableProps = {
  rows?: number;
  cols?: number;
};

export const LoadingTable = ({ rows = 3, cols = 5 }: TableProps) => {
  return (
    <>
      {new Array(rows).fill(" ").map((_, index) => (
        <LoadingRow key={`row-${index}`} cols={cols} rowIndex={index} />
      ))}
    </>
  );
};

type RowProps = {
  rowIndex: number;
  cols: number;
};

export const LoadingRow = ({ cols, rowIndex }: RowProps) => {
  return (
    <tr>
      {new Array(cols).fill(" ").map((_, colIndex) => (
        <LoadingCell key={`cell-${rowIndex}-${colIndex}`} />
      ))}
    </tr>
  );
};

type CellProps = {
  height?: number;
};

export const LoadingCell = ({ height = 24 }: CellProps) => {
  return (
    <td>
      <SkeletonLoader height={height} />
    </td>
  );
};
