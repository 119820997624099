import React, { useState } from "react";
import { Button, Card, H3, P } from "../../01-atoms";
import { Drawer } from "./Drawer";
import { useTheme } from "../../03-hooks";
import { DEFAULT_ELEVATION } from "v2/04-providers";

export const DrawerExamples = () => {
  const { theme } = useTheme();

  const [rdOpen, setRdOpen] = useState(false);
  const [rdLayoutOpen, setRdLayoutOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [overlayBdOpen, setOverlayBdOpen] = useState(false);

  const [lfOpen, setLfOpen] = useState(false);
  const [lfLayoutOpen, setLfLayoutOpen] = useState(false);

  const closeDrawer = () => {
    setRdOpen(false);
    setRdLayoutOpen(false);
    setBackdrop(false);
    setOverlayBdOpen(false);
    setLfOpen(false);
    setLfLayoutOpen(false);
  };

  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing.large,
        gap: theme.spacing.medium
      }}
    >
      <H3>Drawer open</H3>
      <Button variant="outlined" onClick={closeDrawer}>
        Close drawer
      </Button>
    </div>
  );

  return (
    <div style={{ display: "flex", flex: 1, position: "relative" }}>
      <div style={{ display: "grid", gridGap: "1rem", flex: 1 }}>
        <Button
          onClick={() => {
            setRdOpen(!rdOpen);
            setBackdrop(true);
          }}
        >
          Toggle right global overlay drawer
        </Button>
        <Button onClick={() => setLfOpen(!lfOpen)}>Toggle left global overlay drawer</Button>

        <Card>No highlight</Card>
        <Card
          style={{
            zIndex: backdrop ? DEFAULT_ELEVATION["saturn"] : undefined,
            background: "white"
          }}
        >
          With highlight
        </Card>

        <P style={{ marginTop: "30rem" }}>Just to make this view scroll</P>
      </div>

      <Drawer position="right" open={rdOpen} onClose={closeDrawer}>
        {content}
      </Drawer>
      <Drawer position="left" open={lfOpen} onClose={closeDrawer}>
        {content}
      </Drawer>
      <Drawer position="right" open={overlayBdOpen} onClose={closeDrawer}>
        {content}
      </Drawer>
    </div>
  );
};
