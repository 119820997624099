import { truncate } from "fs";
import React from "react";
import { ComponentSection } from "v2/09-routes/ComponentExamplesRoute/ComponentSection";
import { useToast } from ".";
import { Button } from "../..";

export const UseToastExamples = () => {
  const toast = useToast();

  return (
    <>
      <ComponentSection title="Default toast" description="Toast with close button">
        <Button onClick={() => toast.info("This is in top right")}>Show top right</Button>
        <Button
          onClick={() => toast.show({ title: "This is top left" }, { placement: "top-left" })}
        >
          Show top left
        </Button>
        <Button
          onClick={() =>
            toast.show({ title: "This is in bottom right" }, { placement: "bottom-right" })
          }
        >
          Show bottom right
        </Button>
        <Button
          onClick={() => toast.show({ title: "This is bottom left" }, { placement: "bottom-left" })}
        >
          Show bottom left
        </Button>
        <Button
          onClick={() => toast.show({ title: "This is top center" }, { placement: "top-center" })}
        >
          Show top center
        </Button>
        <Button
          onClick={() =>
            toast.show({ title: "This is bottom center" }, { placement: "bottom-center" })
          }
        >
          Show bottom center
        </Button>
        <Button
          onClick={() =>
            toast.show(
              { title: "This is bottom center" },
              { placement: "bottom-center", autoClose: false }
            )
          }
        >
          Show bottom center
        </Button>
      </ComponentSection>
      <ComponentSection
        title="Toast notifications"
        description="Toast can be notification without close button and shorter auto close."
      >
        <Button onClick={() => toast.notify("Notification")}>Notify</Button>
        <Button onClick={() => toast.notify("Notification", "success")}>Notify success</Button>
        <Button onClick={() => toast.notify("Notification", "error")}>Notify error</Button>
      </ComponentSection>
      <ComponentSection title="Toast types">
        <Button onClick={() => toast.info("Info")}>Info</Button>
        <Button onClick={() => toast.success("Success")}>Success</Button>
        <Button onClick={() => toast.error("Error")}>Error</Button>
        <Button onClick={() => toast.warning("Warning")}>Warning</Button>
      </ComponentSection>
    </>
  );
};
