import React, { useState } from "react";
import { TimePicker, TimeRange } from "./TimePicker";
import { Title } from "../../01-atoms";
import { Icon } from "../../00-assets";
import { TimeRangePicker } from "./TimeRangePicker";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";

export const TimePickerExamples = () => {
  const [time, setTime] = useState("10:00");
  const [another, setAnother] = useState("11:05");

  const [range, setRange] = useState<TimeRange>({ start: "10:00", end: "11:00" });
  const [anotherRange, setAnotherRange] = useState<TimeRange>({ start: "13:00", end: "15:00" });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TimePicker searchable time={time} onSelect={setTime} />
      <Title>5min interval and only office times</Title>
      <TimePicker
        searchable
        time={another}
        onSelect={setAnother}
        selectIntervalMinutes={5}
        timeWindow={{ start: "08:00", end: "16:00" }}
      />
      <ComponentSection title="Blended mode" description="TimePicker can have a blended mode">
        <TimePicker
          searchable
          time={another}
          onSelect={setAnother}
          selectIntervalMinutes={5}
          timeWindow={{ start: "08:00", end: "16:00" }}
          variant="blended"
          startAdornment={<Icon name="time" />}
          showAdornment={false}
        />
        <TimePicker
          searchable
          time={another}
          onSelect={setAnother}
          selectIntervalMinutes={5}
          timeWindow={{ start: "08:00", end: "16:00" }}
          variant="blended"
          element="button"
          startAdornment={<Icon name="time" />}
          showAdornment={false}
        />
      </ComponentSection>
      <Title>Time range which maintains duration</Title>
      <TimeRangePicker value={range} onSelect={setRange} />
      <Title>Time range with window</Title>
      <TimeRangePicker
        value={anotherRange}
        onSelect={setAnotherRange}
        timeWindow={{ start: "08:00", end: "16:00" }}
      />
    </div>
  );
};
