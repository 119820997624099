import React, { useEffect, useRef, useState } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import cx from "classnames";
import { Backdrop } from "../../../00-assets/Backdrop";
import ReactDOM from "react-dom";
import { DrawerProps } from "../Drawer";

import styles from "./DesktopDrawer.module.scss";

export const DesktopDrawer = ({
  position = "right",
  children,
  open,
  width,
  className,
  style,
  backdropStyles,
  lockScroll = true,
  onClose
}: DrawerProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (open) {
      setActive(true);
      if (lockScroll) {
        setTimeout(() => disableBodyScroll(scrollRef.current!));
      }
    }
    if (!open) {
      clearAllBodyScrollLocks();
      const timeout = setTimeout(() => setActive(false), 400);

      return () => {
        clearAllBodyScrollLocks();
        timeout && clearTimeout(timeout);
      };
    }
  }, [open]);

  // This safeguards that all scroll locks will be released when dismounted
  useEffect(() => clearAllBodyScrollLocks, []);

  const isClosing = !open && active;

  const drawer = (
    <div
      ref={scrollRef}
      className={cx(styles.Drawer, styles.Desktop, styles["overlay"], styles[position], className, {
        [styles.Global]: global,
        [styles.Closing]: isClosing
      })}
      style={{
        ...style,
        width
      }}
    >
      {children}
    </div>
  );

  if (!active) {
    return null;
  }

  return (
    <>
      <Backdrop isGlobal onClick={onClose} open={!isClosing} style={backdropStyles}>
        {drawer}
      </Backdrop>
    </>
  );
};
