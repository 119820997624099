import React, { FC, useRef, useState } from "react";
import styled, { css } from "styled-components";

import {
  Card,
  Button,
  scrollListStyle,
  Popover,
  Layout,
  Title,
  TextButton,
  Input
} from "../../../../01-atoms";
import { Icon } from "../../../../00-assets";
import { Modal } from "../../../../02-blocks";

import { AppMenuProps } from "../..";
import { useTheme } from "v2/03-hooks";
import { useAppMenuInternalContext } from "../../internalContext";
import { animated, useSpring, config } from "@react-spring/web";

interface IWorkspaceSelectorProps
  extends Pick<
    AppMenuProps,
    "createWorkspace" | "switchWorkspace" | "workspaces" | "organisation" | "user" | "onLogout"
  > {}

const StyledCard = styled(Card)`
  width: 18rem;
  max-height: 36rem;
  padding: var(--spacing-large);
  padding-top: var(--spacing-medium);
  ${scrollListStyle};

  * {
    user-select: none;
  }
`;

const LogoutButton = styled(Button)`
  && {
    padding: var(--spacing-small);
    span {
      flex: initial;
    }
  }
`;

export const LogoWrapper = styled.div`
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--rounding-medium);
  overflow: hidden;

  > img {
    width: 100%;
    object-fit: contain;
  }
`;

const FirstCharacter = styled(Title)`
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--palette-primary-subdued);
`;

const StyledWorkspaceSelector = styled.div``;

const WorkspaceButton = styled.div<{ $current: boolean }>`
  padding: var(--spacing-small);
  background-color: transparent;
  border: 1px solid var(--palette-border-subdued);
  border-radius: var(--rounding-medium);
  transition: border-color 160ms ease;
  cursor: pointer;

  :hover {
    border-color: var(--palette-primary-default);
  }

  ${({ $current }) =>
    $current &&
    css`
      border-color: var(--palette-primary-default);
    `}
`;

const BlueCheckIcon = styled(Icon).attrs({ name: "check" })`
  margin-left: auto;
  stroke: var(--palette-primary-default);
`;

const CreateWorkspaceTextButton = styled(TextButton)`
  padding-left: var(--spacing-small);
`;

const SelectorAnchorButton = styled.button<{ $active?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: 1.5rem auto;
  grid-column-gap: var(--spacing-small);
  width: 100%;
  padding: var(--spacing-small);
  border: 1px solid var(--palette-border-subdued);
  border-radius: var(--rounding-medium);
  background-color: transparent;
  transition: border-color 160ms ease;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: var(--palette-primary-default);
  }

  ${({ $active }) =>
    $active &&
    css`
      border-color: var(--palette-primary-default);
    `}
`;

const EllipsisWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: stretch;
`;

const EllipsisTitle = styled(Title)`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AnimatedWrapper = styled(animated.div)`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
`;

const getFirstCharacter = (input: string): string | null => {
  return input.length > 0 ? input[0].toUpperCase() : null;
};

export const WorkspaceSelector: FC<IWorkspaceSelectorProps> = ({
  user,
  workspaces,
  organisation,
  createWorkspace,
  switchWorkspace,
  onLogout,
  ...rest
}) => {
  const { theme } = useTheme();
  const { collapsed } = useAppMenuInternalContext();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [createWorkspaceOpen, setCreateWorkspaceOpen] = useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = useState("");

  const spring = useSpring({
    opacity: collapsed ? 0 : 1,
    transform: collapsed ? "translateX(0.5rem)" : "translateX(0rem)",
    config: { ...config.stiff, clamp: true }
  });

  return (
    <StyledWorkspaceSelector>
      <SelectorAnchorButton ref={anchorRef} onClick={() => setOpen(!open)} $active={open}>
        <LogoWrapper>
          {organisation.logo ? (
            <>{organisation.logo}</>
          ) : (
            <FirstCharacter variant="bold">{getFirstCharacter(organisation.name)}</FirstCharacter>
          )}
        </LogoWrapper>
        <AnimatedWrapper style={spring}>
          <EllipsisWrapper>
            <EllipsisTitle variant="bold" style={{ maxWidth: 106 }}>
              {organisation.name}
            </EllipsisTitle>
          </EllipsisWrapper>
          <Icon
            name="caretDown"
            color={theme.palette.foreground.subdued}
            style={{ marginLeft: "auto" }}
          />
        </AnimatedWrapper>
      </SelectorAnchorButton>
      <Popover
        {...rest}
        open={open}
        referenceElement={anchorRef.current}
        onClose={() => setOpen(false)}
      >
        <StyledCard elevated>
          <Layout.Group vertical gap="large">
            <EllipsisWrapper style={{ marginTop: 4 }}>
              <EllipsisTitle>{user.email}</EllipsisTitle>
            </EllipsisWrapper>
            <Layout.Divider />

            <Layout.Group vertical gap="small">
              {workspaces &&
                workspaces.length > 1 &&
                workspaces.map(workspace => {
                  const current = workspace.name === organisation.name;

                  return (
                    <WorkspaceButton
                      key={workspace.id}
                      $current={current}
                      onClick={() => switchWorkspace && switchWorkspace(workspace.id)}
                    >
                      <Layout.Group>
                        <LogoWrapper>
                          {workspace.logo ? (
                            <img src={workspace.logo} />
                          ) : (
                            <FirstCharacter variant="bold">
                              {getFirstCharacter(workspace.name)}
                            </FirstCharacter>
                          )}
                        </LogoWrapper>
                        <Title variant="bold">{workspace.name}</Title>
                        {current && <BlueCheckIcon />}
                      </Layout.Group>
                    </WorkspaceButton>
                  );
                })}
            </Layout.Group>
            {createWorkspace && (
              <CreateWorkspaceTextButton
                startIcon="plus"
                colorVariant="primary"
                onClick={() => setCreateWorkspaceOpen(true)}
              >
                Create workspace
              </CreateWorkspaceTextButton>
            )}
            <Layout.Divider />
            <LogoutButton
              variant="blended"
              startAdornment={<Icon name="logOut" />}
              onClick={onLogout}
            >
              Sign out
            </LogoutButton>
          </Layout.Group>
        </StyledCard>
      </Popover>
      {createWorkspace && (
        <Modal
          title="Create new workspace"
          open={createWorkspaceOpen}
          onClose={() => setCreateWorkspaceOpen(false)}
          style={{ width: "350px" }}
          primaryButton={{
            title: "Create",
            onClick: () => createWorkspace(newWorkspaceName)
          }}
          secondaryButton={{
            title: "Cancel",
            onClick: () => setCreateWorkspaceOpen(false)
          }}
        >
          <Input
            autoFocus
            placeholder="Workspace name"
            value={newWorkspaceName}
            onChange={setNewWorkspaceName}
          />
        </Modal>
      )}
    </StyledWorkspaceSelector>
  );
};
