import React from "react";
import { ComponentSection } from "../../../v2/09-routes/ComponentExamplesRoute/ComponentSection";

import { Caps2, H1, H2, H3, H4, P, P4, Title, ErrorMessage } from ".";

export const TypographyExample = () => (
  <>
    <ComponentSection title="Heading">
      <H1>Heading 1</H1>
      <H2>Heading 2</H2>
      <H3>Heading 3</H3>
      <H4>Heading 4</H4>
    </ComponentSection>
    <ComponentSection title="Paragraph">
      <P size="medium">
        Medium Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </P>
      <P>
        Paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </P>
      <P4>Paragraph 4</P4>
    </ComponentSection>
    <ComponentSection title="Title">
      <Title>Title</Title>
      <Title variant="bold">Title</Title>
    </ComponentSection>
    <ComponentSection title="Error message">
      <ErrorMessage>Error error everything is terrible!</ErrorMessage>
      <ErrorMessage variant="bold">Error error everything is terrible!</ErrorMessage>
    </ComponentSection>
    <ComponentSection title="Caps">
      <Caps2 size="semibold">Caps 2 - Semibold</Caps2>
      <Caps2>Caps 2 - Regular</Caps2>
    </ComponentSection>
  </>
);
