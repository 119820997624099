import React, { ComponentProps, ReactNode } from "react";
import { H4 } from "../../01-atoms";
import { Breadcrumb, BreadcrumbPath, IconButton } from "../../02-blocks";

import cx from "classnames";

import styles from "./TopBar.module.scss";

export interface TopBarProps extends ComponentProps<"div"> {
  navigationAction?: "back" | "menu";
  onNavigationAction?: () => unknown;
  title?: string;
  paths?: BreadcrumbPath[];
  actions?: ReactNode;
  sticky?: boolean;
}

export const TopBar = ({
  title,
  paths,
  actions,
  navigationAction,
  onNavigationAction,
  className,
  sticky,
  ...props
}: TopBarProps) => {
  return (
    <div className={cx(styles.Root, className, { [styles.Sticky]: sticky })} {...props}>
      {onNavigationAction && (
        <IconButton
          icon={navigationAction === "menu" ? "description" : "arrowLeft"}
          onClick={onNavigationAction}
        />
      )}
      {title && <H4>{title}</H4>}
      {paths && <Breadcrumb paths={paths} />}
      <div className={styles.Right}>{actions}</div>
    </div>
  );
};
