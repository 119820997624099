import React, {
  CSSProperties,
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { TableHeader } from "./TableHeader";
import { Card, Title } from "../../01-atoms";
import { SortFunction, useSortable } from "../../03-hooks";
import { EmptyState } from "./EmptyState";
import { LoadingTable } from "./LoadingTable";
import styled, { css } from "styled-components";

export type TableTextAlign = "left" | "center" | "right" | undefined;

export type TableColumn<DataType> = {
  key: string;
  title?: string;
  render?: (data: DataType) => ReactNode | string;
  disableClick?: boolean;
  sortable?: boolean;
  width?: string;
  textAlign?: TableTextAlign;
};

export type TableProps<DataType> = Pick<ComponentProps<"table">, "style"> & {
  columns: TableColumn<DataType>[];
  data?: DataType[];
  onClick?: (row: DataType, event?: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => unknown;
  emptyState?: string | ReactNode;
  sortFunction?: SortFunction<DataType>;
  loading?: boolean;
  rowHeightRem?: number;
  loadingRowAmount?: number;
};

export const TableCard = styled(Card)`
  padding: 0;
  overflow: hidden;
`;

const StyledTable = styled.table<{ $clickableRows: boolean; $rowHeightRem?: number }>`
  width: 100%;
  border-collapse: collapse;

  > tbody {
    tr {
      ${({ $clickableRows }) =>
        $clickableRows &&
        css`
          :hover {
            background: linear-gradient(to right, var(--palette-surface-subdued), transparent);
          }
        `}
      td {
        padding: var(--spacing-large);
        max-width: 0px;
        overflow: hidden;
        box-sizing: border-box;

        ${({ $rowHeightRem }) =>
          $rowHeightRem &&
          css`
            height: ${$rowHeightRem}rem;
            padding-top: 0;
            padding-bottom: 0;
          `}

        h5 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      > :first-child {
        padding-left: 2rem;
      }

      > :last-child {
        padding-right: 2rem;
      }
    }

    > tr:not(:last-of-type) {
      border-bottom: 1px solid var(--palette-border-dimmed);
    }
  }
`;

export const textAlignToFlex = (textAlign?: TableTextAlign) => {
  if (!textAlign) return "flex-start";
  return {
    left: "flex-start",
    center: "center",
    right: "flex-end"
  }[textAlign];
};

export const Table = <DataType extends { [index: string]: any }>({
  columns,
  data,
  onClick,
  emptyState = "No data to show",
  sortFunction,
  loading = false,
  loadingRowAmount = 5,
  rowHeightRem,
  ...props
}: TableProps<DataType>) => {
  const { data: sortedData, setData, sortedBy, sortDir, toggleSortDir, setSortedBy } = useSortable<
    DataType
  >(undefined, sortFunction);

  useEffect(() => {
    setData(data || []);
  }, [data]);

  const renderRow = useCallback(
    (entry: DataType, index: number) => (
      <tr key={`row-${index}`}>
        {columns.map((colInfo, colIndex) => {
          const columnValue = entry[colInfo.key];

          return (
            <td
              key={`cell-${index}-${colIndex}`}
              onClick={event => onClick && !colInfo.disableClick && onClick(entry, event)}
              style={
                {
                  textAlign: colInfo.textAlign,
                  width: colInfo.width,
                  cursor: onClick ? "pointer" : "default"
                } as CSSProperties
              }
            >
              {colInfo.render ? colInfo.render(entry) : <Title>{columnValue?.toString()}</Title>}
            </td>
          );
        })}
      </tr>
    ),
    [columns, onClick]
  );

  const renderTable = useMemo(() => {
    if (loading) {
      return <LoadingTable rows={loadingRowAmount} cols={columns.length} />;
    } else if (sortedData.length === 0 && emptyState) {
      return <EmptyState emptyState={emptyState} colspan={columns.length} />;
    } else {
      return sortedData.map(renderRow);
    }
  }, [loading, emptyState, columns, sortedData, renderRow]);

  if (data) {
    return (
      <StyledTable
        {...props}
        $clickableRows={!!onClick && sortedData.length > 0}
        $rowHeightRem={rowHeightRem}
      >
        <TableHeader
          columns={columns}
          sortedBy={sortedBy}
          sortDir={sortDir}
          setSortedBy={setSortedBy}
          toggleSortDir={toggleSortDir}
        />
        <tbody>{renderTable}</tbody>
      </StyledTable>
    );
  }
  return null;
};
