import React, { FC } from "react";
import { ITagProps } from "../../";
import { BaseTag } from "./BaseTag";
import { TagFilter } from "./presets/TagFilter";

type TCompound = {
  Filter: typeof TagFilter;
};

const TagComponent: FC<ITagProps> & TCompound = ({ ...rest }) => <BaseTag {...rest} />;
TagComponent.Filter = TagFilter;
export const Tag = TagComponent;
