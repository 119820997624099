import flatObject from "flat";

export const objectToCssVariableMap = (object: object) => {
  const flatTheme: { [key: string]: string } = flatObject(object, {
    delimiter: "-"
  });
  const variables = Object.keys(flatTheme).reduce(
    (map, key) => map.set(`--${key}`, flatTheme[key]),
    new Map<string, string>()
  );
  return variables;
};
