import React, { ComponentProps, ReactNode } from "react";
import ReactDOM from "react-dom";
import { useTransition, a, config } from "@react-spring/web";

import styled from "styled-components";
import { DEFAULT_ELEVATION, TElevationName } from "v2/04-providers";

interface Props extends Pick<ComponentProps<"div">, "onClick" | "className" | "style"> {
  portalElemId?: string;
  isGlobal?: boolean;
  open?: boolean;
  children?: ReactNode;
  elevation?: TElevationName;
  transparent?: boolean;
  disableAnimation?: boolean;
}

const StyledBackdrop = styled.div<{ $zIndex?: number; $global?: boolean; $transparent: boolean }>`
  position: ${p => (p.$global ? "fixed" : "absolute")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${p => (p.$transparent ? "transparent" : " rgba(0, 0, 0, 0.4)")};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${p => p.$zIndex};
`;

const BackdropClickArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Backdrop = ({
  onClick,
  className,
  children,
  open,
  disableAnimation = false,
  portalElemId = "root",
  isGlobal = true,
  elevation = "jupiter",
  transparent = false,
  ...props
}: Props) => {
  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { ...config.stiff, clamp: true, tension: 260 },
    immediate: disableAnimation
  });

  const backdrop = (
    <StyledBackdrop
      $global={isGlobal}
      {...props}
      $zIndex={DEFAULT_ELEVATION[elevation]}
      $transparent={transparent}
    >
      {onClick && <BackdropClickArea onClick={onClick} />}
      {children}
    </StyledBackdrop>
  );

  return transitions((style, tOpen) => {
    if (tOpen && isGlobal) {
      return ReactDOM.createPortal(
        <a.div style={{ ...style, zIndex: DEFAULT_ELEVATION[elevation], position: "relative" }}>
          {backdrop}
        </a.div>,
        document.getElementById(portalElemId)!
      );
    }
    if (tOpen) {
      return (
        <a.div style={{ ...style, zIndex: DEFAULT_ELEVATION[elevation], position: "relative" }}>
          {backdrop}
        </a.div>
      );
    }
    return null;
  });
};
