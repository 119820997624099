import "moment/locale/fi";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import React, { useState } from "react";
import { DayOfWeekShape, DayPickerSingleDateController } from "react-dates";
import { Icon } from "../../00-assets";
import { H4, Popover, IPopoverProps, Title } from "..";
import cx from "classnames";
import styles from "./DatePicker.module.scss";

interface CalendarProps {
  disablePast?: boolean;
  firstDayOfWeek?: DayOfWeekShape;
  date?: Date;
  onDateChange?: (day?: Date) => void;
}

export interface DatePickerProps
  extends CalendarProps,
    Omit<IPopoverProps, "sameWidthWithReference" | "closeOnContentClick"> {}

export const DatePicker = ({
  referenceElement,
  attachToRef = true,
  placement = "bottom",
  onClose,
  onDateChange,
  open,
  ...rest
}: DatePickerProps) => {
  const handleOnDateChange = (day?: Date) => {
    onDateChange && onDateChange(day);
    onClose();
  };
  return (
    <Popover
      onClose={onClose}
      referenceElement={referenceElement}
      closeOnContentClick={false}
      placement={placement}
      attachToRef={attachToRef}
      open={open}
    >
      <Calendar {...rest} onDateChange={handleOnDateChange} />
    </Popover>
  );
};

const Calendar = ({
  disablePast = false,
  firstDayOfWeek = 1,
  date,
  onDateChange
}: CalendarProps) => {
  const [focused, setFocused] = useState<boolean>(true);

  const isDayOfWeekWeekend = (dayOfWeek: number) => dayOfWeek === 0 || dayOfWeek === 6;

  const momentDate = moment(date);

  return (
    <span className={cx(styles.DatePicker, styles[`FirstDayOfWeek_${firstDayOfWeek}`])}>
      <DayPickerSingleDateController
        isDayBlocked={day => (disablePast ? day.isBefore(moment(), "day") : false)}
        initialVisibleMonth={() => (!!date && momentDate.isValid() ? momentDate : moment())}
        date={!!date ? momentDate : null}
        focused={focused}
        isFocused={true}
        keepOpenOnDateSelect={true}
        onFocusChange={() => setFocused(true)}
        hideKeyboardShortcutsPanel
        showKeyboardShortcuts={false}
        onDateChange={day => onDateChange && onDateChange(day?.toDate())}
        numberOfMonths={1}
        firstDayOfWeek={firstDayOfWeek}
        renderNavNextButton={({ onClick }) => (
          <div onClick={onClick} className={cx(styles.Nav, styles.Next)}>
            <Icon name="chevronRight" />
          </div>
        )}
        renderNavPrevButton={({ onClick }) => (
          <div onClick={onClick} className={cx(styles.Nav, styles.Prev)}>
            <Icon name="chevronLeft" />
          </div>
        )}
        renderMonthText={month => <H4>{month.format("MMMM YYYY")}</H4>}
        navPosition="navPositionTop"
        renderWeekHeaderElement={day => <Title className={cx(styles.WeekHeader)}>{day}</Title>}
        renderCalendarDay={({ day, modifiers, onDayClick }) => (
          <td
            onClick={e => onDayClick && day && onDayClick(day, e)}
            className={cx({
              [styles.Day]: day,
              [styles.Blocked]: modifiers?.has("blocked"),
              [styles.Selected]: modifiers?.has("selected"),
              [styles.Weekend]: day && isDayOfWeekWeekend(day.day())
            })}
          >
            <Title className={styles.DayTitle}>{day?.format("D")}</Title>
          </td>
        )}
      />
    </span>
  );
};
