import { Palette, TElevation, Theme } from "./types";

export const defaultSpacing = {
  xSmall: "0.25rem",
  small: "0.5rem",
  medium: "0.75rem",
  large: "1rem",
  xLarge: "1.5rem",
  xxLarge: "2rem"
};

export const defaultRounding = {
  small: "4px",
  medium: "6px",
  large: "12px"
};

export const defaultTypography = {
  font: {
    heading: "'Poppins', sans-serif",
    text: "'Poppins', sans-serif"
  },
  size: {
    h1: "2.75rem",
    h2: "2rem",
    h3: "1.5rem",
    h4: "1rem",
    p: "0.875rem",
    title: "0.813rem",
    p4: "0.6875rem"
  }
};

export const defaultBreakpoints = {
  mobileDown: "968px",
  tabletDown: "1024px"
};

export const defaultShadows = {
  default: "0px 4px 16px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  large: "0px 8px 24px rgba(0, 0, 0, 0.08)",
  smooth:
    "0px 4px 26px rgba(0, 0, 0, 0.03), 0px 1.89759px 9.90498px rgba(0, 0, 0, 0.0206994), 0px 1.24001px 4.66057px rgba(0, 0, 0, 0.0166887), 0px 0.817588px 2.1116px rgba(0, 0, 0, 0.0133113), 0px 0.428216px 0.743338px rgba(0, 0, 0, 0.00930055)"
};

export const DEFAULT_ELEVATION: TElevation = {
  mercury: 10,
  venus: 20,
  earth: 30,
  mars: 40,
  jupiter: 50,
  saturn: 60,
  uranus: 70,
  neptune: 80,
  pluto: 90
};

export const defaultPalette: Palette = {
  foreground: {
    pressed: "#0B161B",
    hovered: "#0D171C",
    default: "#0E181D",
    subdued: "#656B6E",
    dimmed: "#BCBEC0",
    ghosted: "#E7E8E8"
  },
  background: {
    pressed: "#E0E0E0",
    hovered: "#F0F0F0",
    default: "#FFFFFF",
    subdued: "#FFFFFF",
    dimmed: "#FFFFFF",
    ghosted: "#FFFFFF"
  },
  surface: {
    pressed: "#CCCED2",
    hovered: "#DADCE0",
    default: "#E8EAEE",
    subdued: "#F8F8F9",
    dimmed: "#F9F9FA",
    ghosted: "#FDFDFD"
  },
  border: {
    pressed: "#A9AEB5",
    hovered: "#B5BAC0",
    default: "#C1C6CC",
    subdued: "#D7DBDE",
    dimmed: "#EEEFF1",
    ghosted: "#F9F9FA"
  },
  primary: {
    pressed: "#004CE0",
    hovered: "#0455F0",
    default: "#105EFB",
    subdued: "#6698FC",
    dimmed: "#BCD2FE",
    ghosted: "#E7EFFF"
  },
  highlight: {
    pressed: "#33C2E0",
    hovered: "#3ECAE9",
    default: "#4AD2F0",
    subdued: "#8BE2F5",
    dimmed: "#EDFBFE",
    ghosted: "#CCF2FB"
  },
  warning: {
    pressed: "#E0B73E",
    hovered: "#F0C14A",
    default: "#FECA57",
    subdued: "#FEDD94",
    dimmed: "#FFF0D0",
    ghosted: "#FFFAEE"
  },
  success: {
    pressed: "#00A57B",
    hovered: "#08A980",
    default: "#10AC84",
    subdued: "#66CAB0",
    dimmed: "#BCE8DD",
    ghosted: "#E7F7F3"
  },
  danger: {
    pressed: "#DF3A3C",
    hovered: "#E74647",
    default: "#EE5253",
    subdued: "#F49091",
    dimmed: "#FACFCF",
    ghosted: "#FDEEEE"
  },
  purple: {
    pressed: "#9C3FE0",
    hovered: "#A54AED",
    default: "#AF57F4",
    subdued: "#CC94F8",
    dimmed: "#E9D0FC",
    ghosted: "#F7EEFE"
  }
};

export const defaultTheme: Theme = {
  palette: defaultPalette,
  typography: defaultTypography,
  breakpoints: defaultBreakpoints,
  spacing: defaultSpacing,
  rounding: defaultRounding,
  shadow: defaultShadows,
  elevation: DEFAULT_ELEVATION
};
