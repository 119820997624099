import React from "react";
import { Toast } from "./Toast";

export const ToastExamples = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem", width: "400px" }}>
      <Toast title="Toast here" onClose={() => {}} />
      <Toast type="success" title="That's a success!" onClose={() => {}} />
      <Toast type="warning" title="Warning, warning!" onClose={() => {}} />
      <Toast
        type="warning"
        title="Warning, warning!"
        message="This could explain the warning a bit more"
        onClose={() => {}}
      />
      <Toast
        type="error"
        title="Oops, something failed!"
        message="Now there is most likely something to tell about what went wrong"
        onClose={() => {}}
      />
    </div>
  );
};
