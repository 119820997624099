import React, {
  createContext,
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react";
import { v4 as uuid } from "uuid";
import ReactDOM from "react-dom";

import cx from "classnames";
import styles from "./PlacedToast.module.scss";
import { Toast, ToastProps, ToastType } from "../../02-blocks";
import { useTheme } from "../../03-hooks";

type ShowConfig = (props: ToastProps, config?: Partial<ToastConfigurations>) => void;
type TypedShowConfig = (title: string) => void;
type NotificationConfig = (title: string, type?: ToastType) => void;

export interface ToastContextData {
  show: ShowConfig;
  success: TypedShowConfig;
  info: TypedShowConfig;
  warning: TypedShowConfig;
  error: TypedShowConfig;
  notify: NotificationConfig;
}

export const ToastContext = createContext<ToastContextData>({
  show: () => {
    throw new Error("Tried to use show outside ToastProvider");
  },
  success: () => {
    throw new Error("Tried to use success outside ToastProvider");
  },
  info: () => {
    throw new Error("Tried to use info outside ToastProvider");
  },
  warning: () => {
    throw new Error("Tried to use warning outside ToastProvider");
  },
  error: () => {
    throw new Error("Tried to use error outside ToastProvider");
  },
  notify: () => {
    throw new Error("Tried to use notify outside ToastProvider");
  }
});

interface ToastIdentifier {
  toastId: string;
}

interface ToastConfigurations {
  placement: ToastPlacement;
  autoClose: boolean;
  closeDelay: number;
  closeOnOutsideClick?: boolean;
  closeButton?: boolean;
}

export type ToastPlacement =
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left"
  | "top-center"
  | "bottom-center";

export const ToastProvider = ({
  children,
  defaultPlacement
}: {
  children: ReactNode;
  defaultPlacement?: ToastPlacement;
}) => {
  const { theme } = useTheme();
  const [leaving, setLeaving] = useState(false);
  const [activeToast, showToast] = useState<
    (ToastProps & ToastIdentifier & ToastConfigurations) | undefined
  >();

  const close = useCallback(() => {
    setLeaving(true);
    const timeout = setTimeout(onToastClose, 300);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = activeToast?.autoClose && setTimeout(close, activeToast.closeDelay);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [activeToast]);

  const onToastClose = useCallback(() => {
    activeToast?.onClose && activeToast.onClose();
    setLeaving(false);
    showToast(undefined);
  }, []);

  const defaultToastConfigurations: ToastConfigurations = {
    placement: defaultPlacement || "top-right",
    autoClose: true,
    closeDelay: 4000,
    closeButton: true,
    closeOnOutsideClick: false
  };

  const show = (
    props: Omit<ToastProps, "onClose" | "onCloseOutsideClick">,
    config?: Partial<ToastConfigurations>
  ) => {
    showToast({
      ...props,
      ...defaultToastConfigurations,
      ...config,
      toastId: uuid()
    });
    setTimeout(() => setLeaving(false), 0);
  };

  const success = (title: string) => show({ title, type: "success" });

  const info = (title: string) => show({ title, type: "info" });

  const warning = (title: string) => show({ title, type: "warning" });

  const error = (title: string) => show({ title, type: "error" });

  const notify = (title: string, type?: ToastType) =>
    show(
      { title, type: type || "info" },
      { closeButton: false, closeOnOutsideClick: true, closeDelay: 2000 }
    );

  const thresholds = {
    "--toast-horizontal-threshold": theme.spacing.medium,
    "--toast-vertical-threshold": theme.spacing.medium
  } as CSSProperties;

  return (
    <ToastContext.Provider value={{ show, success, info, warning, error, notify }}>
      {children}
      {activeToast &&
        ReactDOM.createPortal(
          <div
            style={{ ...thresholds }}
            className={cx(styles.PlacedToast, styles[activeToast.placement], {
              [styles.Leaving]: leaving
            })}
          >
            <Toast
              key={activeToast.toastId}
              {...activeToast}
              onClose={activeToast.closeButton ? close : undefined}
              onOutsideClick={activeToast.closeOnOutsideClick ? close : undefined}
            />
          </div>,
          document.body
        )}
    </ToastContext.Provider>
  );
};
