import React from "react";

import { Button, Card } from "../../01-atoms";
import { useState } from "react";
import { Backdrop } from "./Backdrop";
import { useTheme } from "../../03-hooks";
import { DEFAULT_ELEVATION } from "v2/04-providers";

export const BackdropExamples = () => {
  const { theme } = useTheme();

  const [globalOpen, setGlobalOpen] = useState(false);
  const [localOpen, setLocalOpen] = useState(false);
  const [highlightOpen, setHighlightOpen] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        padding: theme.spacing.large,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.medium
      }}
    >
      <Button onClick={() => setGlobalOpen(true)}>Global backdrop</Button>
      <Button onClick={() => setLocalOpen(true)}>Local backdrop</Button>
      <Button onClick={() => setHighlightOpen(true)}>Local backdrop with highlight</Button>

      <Card
        style={{
          zIndex: highlightOpen ? DEFAULT_ELEVATION["mars"] : undefined,
          background: "white"
        }}
      >
        This card might be highlighted
      </Card>

      <Card
        style={{
          background: "white"
        }}
      >
        This card will not be highlighted
      </Card>

      <Backdrop
        open={globalOpen}
        onClick={() => {
          setGlobalOpen(false);
        }}
        isGlobal={true}
      />
      <Backdrop
        open={localOpen || highlightOpen}
        onClick={() => {
          setLocalOpen(false);
          setHighlightOpen(false);
        }}
        isGlobal={false}
      />

      <Card style={{ marginTop: "100rem" }}>This is to force scroll</Card>
    </div>
  );
};
