import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cx from "classnames";
import React, {
  ComponentProps,
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";
import { useResponsive } from "../../03-hooks/useResponsive";

import styles from "./Modal.module.scss";
import { Backdrop } from "../../00-assets/Backdrop";
import { Button, ButtonProps, Card, H4, Title } from "../../01-atoms";
import { CloseIconButton } from "..";
import { DEFAULT_ELEVATION, Icon, IconName, TElevationName, useTheme } from "../../";

interface ModalButtonProps extends ButtonProps {
  title?: string;
}

export interface ModalProps extends Omit<ComponentProps<"div">, "ref"> {
  open: boolean;
  onCloseWithOutsideClick?: () => unknown;
  mobile?: "fullscreen" | "centered";
  fullHeight?: boolean;
  icon?: IconName;
  title?: string;
  description?: ReactNode;
  onClose?: () => any;
  primaryButton?: ModalButtonProps;
  secondaryButton?: ModalButtonProps;
  maxWidth?: CSSProperties["width"];
  hideHeader?: boolean;
  maxContentHeight?: CSSProperties["height"];
  backdropElevation?: TElevationName;
}

export const Modal = ({
  className,
  title,
  open,
  onCloseWithOutsideClick,
  children,
  mobile = "fullscreen",
  fullHeight,
  style,
  icon,
  onClose,
  description,
  primaryButton,
  secondaryButton,
  hideHeader = false,
  backdropElevation = "earth",
  maxWidth,
  maxContentHeight,
  ...props
}: ModalProps) => {
  const { isMobileLayout } = useResponsive();
  const { theme } = useTheme();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState(false);
  const [leaving, setLeaving] = useState(false);

  useEffect(() => {
    if (open) {
      setVisible(true);
      setTimeout(() => disableBodyScroll(scrollRef.current!));
    }
    if (!open && visible) {
      enableBodyScroll(scrollRef.current!);
      setLeaving(true);
      const timeout = setTimeout(() => {
        setVisible(false);
        setLeaving(false);
      }, 250);

      return () => {
        timeout && clearTimeout(timeout);
      };
    }
  }, [open]);

  if (!visible) return null;

  const isMobileFullscreen = isMobileLayout && mobile === "fullscreen";

  const renderHeader = (!!title || !!icon || !!onClose) && !hideHeader;
  const renderActions = !!primaryButton || !!secondaryButton;

  return (
    <Backdrop
      className={cx(styles.ModalBackdrop, {
        [styles.Fullscreen]: isMobileFullscreen,
        [styles.Centered]: isMobileLayout && mobile === "centered"
      })}
      onClick={!isMobileFullscreen ? onCloseWithOutsideClick : undefined}
      open={open}
      style={{
        zIndex: DEFAULT_ELEVATION[backdropElevation]
      }}
    >
      <Card
        ref={scrollRef}
        className={cx(
          styles.Modal,
          {
            [styles.FullHeight]: fullHeight,
            [styles.Out]: leaving,
            [styles.Mobile]: isMobileLayout
          },
          className
        )}
        style={{
          borderRadius: isMobileLayout && mobile ? 0 : undefined,
          maxWidth,
          ...style
        }}
        {...props}
      >
        <div className={styles.Root}>
          {renderHeader && (
            <div className={styles.Header}>
              {icon && <Icon className={styles.Icon} name={icon} />}
              <H4 className={styles.HeaderH4}>{title}</H4>
              <CloseIconButton onClick={onClose} className={styles.CloseButton} />
            </div>
          )}
          <div className={styles.Content} style={{ maxHeight: maxContentHeight }}>
            {description && <Title color={theme.palette.foreground.subdued}>{description}</Title>}
            {children}
          </div>
          {renderActions && (
            <div className={styles.Actions}>
              {secondaryButton && (
                <Button variant="outlined" {...secondaryButton}>
                  {secondaryButton.title || secondaryButton.children}
                </Button>
              )}
              {primaryButton && (
                <Button {...primaryButton}>{primaryButton.title || primaryButton.children}</Button>
              )}
            </div>
          )}
        </div>
      </Card>
    </Backdrop>
  );
};
