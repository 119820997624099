import React, { ReactNode, useState } from "react";
import { InputV2 } from "./InputV2";
import { H1, P, Title } from "v2/01-atoms/Typography";
import { Layout } from "v2/01-atoms/Layout";

const InputExample = ({ title, children }: { title: string; children: ReactNode }) => (
  <Layout.Group vertical gap="small">
    <Title variant="bold">{title}</Title>
    {children}
  </Layout.Group>
);
export const InputV2Examples = () => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // If the new value is an empty string, set the state to empty
    // Otherwise, convert it to a number and set the state
    setInputValue(event.target.value);
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <Layout.Group vertical>
        <H1>Input</H1>
        <P>
          Input is a component that allows users to enter text. It can be used for text input,
          password input, or number input.
        </P>
      </Layout.Group>

      <InputV2 title="Label" endIcon="plus" placeholder="Placeholder" />
      <InputV2 title="Label" startIcon="plus" />

      <InputExample title="Error">
        <InputV2 title="Label" endIcon="plus" error="Error error everything is terrible" />
      </InputExample>

      <InputExample title="Disabled">
        <InputV2 disabled title="Label" />
      </InputExample>

      <InputExample title="Number input">
        <InputV2 title="Label" type="number" value={inputValue} onChange={handleInputChange} />
      </InputExample>
    </Layout.Group>
  );
};
