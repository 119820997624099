import React, { createContext, useEffect, useState } from "react";
import { ThemeContextData, ThemeProviderProps, Theme } from ".";
import { defaultTheme } from "./defaults";
import { objectToCssVariableMap } from "./utils";

export const ThemeContext = createContext<ThemeContextData>({
  theme: defaultTheme
});

export const ThemeProvider = ({ theme, children }: ThemeProviderProps) => {
  const [contextData, setContext] = useState<{ theme: Theme }>({
    theme: defaultTheme
  });

  useEffect(() => {
    const cssVariableMap = objectToCssVariableMap({ ...defaultTheme, ...theme });
    cssVariableMap.forEach((value, key) => document.documentElement.style.setProperty(key, value));
  }, [theme, defaultTheme]);

  useEffect(() => {
    setContext({ theme: { ...defaultTheme, ...theme } });
  }, [defaultTheme, theme]);

  return <ThemeContext.Provider value={contextData}>{children}</ThemeContext.Provider>;
};
