import React, { useMemo, useRef, useState } from "react";
import { Button, ButtonProps, Popover } from "../../01-atoms";
import { MultiOptionList, Option, OptionList } from "../OptionList";

export type CategorisedOption = Option & {
  category: string;
};

export type CategorySelectProps = Omit<ButtonProps, "onClick" | "value" | "onChange"> & {
  categories: Option[];
  options: CategorisedOption[];
  onChange: (value: CategorisedOption[]) => void;
  value: CategorisedOption[];
  popoverBackdrop?: boolean;
};

export const CategorySelect = ({
  categories,
  options,
  value,
  onChange,
  className,
  style,
  popoverBackdrop,
  ...rest
}: CategorySelectProps) => {
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [selectOpen, setSelectOpen] = useState(false);

  const [activeCategory, setActiveCategory] = useState<string | undefined>();

  const resolvedOptions = useMemo(() => {
    if (activeCategory) return options.filter(o => o.category === activeCategory);
    return categories;
  }, [activeCategory, categories, options]);

  const selectElement = useMemo(() => {
    if (!activeCategory) {
      return <OptionList options={categories} onItemClick={opt => setActiveCategory(opt.key)} />;
    } else {
      const categoryValues = value.filter(v => v.category === activeCategory);

      return (
        <MultiOptionList
          searchable={resolvedOptions.length > 10}
          options={resolvedOptions}
          selected={categoryValues.map(opt => opt.key)}
          autoFocus
          onItemClick={opt => {
            const catOpt = opt as CategorisedOption;
            if (value.find(val => val.category === catOpt.category && val.key === catOpt.key)) {
              onChange(value.filter(v => v.category !== catOpt.category || v.key !== opt.key));
            } else {
              onChange([...value, opt as CategorisedOption]);
            }
          }}
        />
      );
    }
  }, [activeCategory, categories, onChange, resolvedOptions, value]);

  return (
    <div ref={selectRef} className={className} style={style}>
      <Button
        onClick={() => {
          if (activeCategory) {
            setActiveCategory("");
          }
          setSelectOpen(!selectOpen);
        }}
        {...rest}
      >
        Filter
      </Button>

      <Popover
        referenceElement={selectRef.current}
        open={selectOpen}
        backdrop={popoverBackdrop}
        onClose={() => {
          setSelectOpen(false);
          setActiveCategory("");
        }}
      >
        {selectElement}
      </Popover>
    </div>
  );
};
