import React, { useState } from "react";
import { ConfirmationModal } from "./ConfirmationModal";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { useTheme, useToast } from "../../03-hooks";
import { Button, H3, H4 } from "../../01-atoms";

export const ConfirmationModalExamples = () => {
  const toast = useToast();
  const { theme } = useTheme();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNoIcon, setShowNoIcon] = useState(false);
  const [showWithWord, setShowWithWord] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      <Button onClick={() => setShowConfirmation(true)}>Action to confirm</Button>
      <ConfirmationModal
        show={showConfirmation}
        config={{
          icon: "info",
          title: "Unsaved changes",
          description: "You have unsaved changes. What would you like to do?",
          cancelTitle: "Close without saving",
          confirmTitle: "Save and close"
        }}
        onConfirm={async () => {
          await new Promise(res => setTimeout(res, 1000));
          setShowConfirmation(false);
          toast.info("That's affirmative!");
        }}
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        closeOnOutsideClick
      />
      <Button onClick={() => setShowNoIcon(true)}>No icon confirm</Button>
      <ConfirmationModal
        show={showNoIcon}
        config={{
          title: "Unsaved changes",
          description: "You have unsaved changes. What would you like to do?",
          cancelTitle: "Close without saving",
          confirmTitle: "Save and close"
        }}
        onConfirm={() => {
          setShowNoIcon(false);
          toast.info("That's affirmative!");
        }}
        onCancel={() => setShowNoIcon(false)}
        onClose={() => setShowNoIcon(false)}
        closeOnOutsideClick
        maxWidth="450px"
      />
      <ComponentSection
        title="Confirmation with a word"
        description="To confirm something even more carefully, you can request user to type in a word during the confirmation."
      >
        <Button destructive onClick={() => setShowWithWord(true)}>
          Delete something permanently
        </Button>
        <ConfirmationModal
          show={showWithWord}
          destructive
          config={{
            title: "Deleting something permanently",
            description: <H3>Do you really want to do this?</H3>,
            confirmTitle: "Delete",
            confirmationWord: "DELETE"
          }}
          onConfirm={() => {
            setShowWithWord(false);
            toast.info("That's affirmative!");
          }}
          onClose={() => setShowWithWord(false)}
          closeOnOutsideClick
          maxWidth="450px"
        />
      </ComponentSection>
    </div>
  );
};
