import React, { ComponentProps, CSSProperties, useCallback } from "react";
import { Stack, Title } from "../../01-atoms";
import { Icon } from "../../00-assets";

type SortDirection = "asc" | "desc";

import { TableColumn, TableTextAlign, textAlignToFlex } from "./Table";
import styled from "styled-components";

export interface TableHeaderProps extends Pick<ComponentProps<"thead">, "style"> {
  columns: TableColumn<any>[];
  sortedBy?: string;
  sortDir?: SortDirection;
  setSortedBy: (column: string) => void;
  toggleSortDir: () => void;
}

const StyledTableHeader = styled.thead`
  border-bottom: 1px solid var(--palette-border-dimmed);
  tr {
    > th {
      user-select: none;
      padding: var(--spacing-medium);
    }
    text-align: left;
    > :first-child {
      padding-left: 2rem;
    }

    > :last-child {
      padding-right: 2rem;
    }
  }
`;

export const TableHeader = ({
  columns,
  sortedBy,
  sortDir,
  setSortedBy,
  toggleSortDir,
  ...rest
}: TableHeaderProps) => {
  const renderHeader = useCallback(
    (column: TableColumn<any>) => (
      <TableHeaderColumn
        key={`header-${column.key}`}
        align={column.textAlign}
        sorted={sortedBy === column.key ? sortDir : undefined}
        onToggleSort={
          column.sortable
            ? () => {
                if (sortedBy !== column.key) setSortedBy(column.key);
                else toggleSortDir();
              }
            : undefined
        }
        style={{ width: column.width, textAlign: column.textAlign } as CSSProperties}
      >
        {column.title}
      </TableHeaderColumn>
    ),
    [setSortedBy, toggleSortDir]
  );

  return (
    <StyledTableHeader {...rest}>
      <tr>{columns.map(renderHeader)}</tr>
    </StyledTableHeader>
  );
};

interface TableHeaderColumnProps extends ComponentProps<"th"> {
  sorted?: SortDirection;
  onToggleSort?: () => unknown;
  align?: TableTextAlign;
}

const TableHeaderColumn = ({
  sorted,
  onToggleSort,
  children,
  style,
  align,
  ...props
}: TableHeaderColumnProps) => {
  const extendedStyles = {
    cursor: onToggleSort ? "pointer" : "default",
    ...style
  };

  return (
    <th style={extendedStyles} onClick={onToggleSort} {...props}>
      <Stack style={{ justifyContent: textAlignToFlex(align) }} gap="xSmall">
        <Title small as="div">
          {children}
        </Title>
        {sorted && <Icon subdued name={sorted === "asc" ? "caretUp" : "caretDown"} />}
        {onToggleSort && sorted === undefined && <Icon subdued name="caretUpDown" />}
      </Stack>
    </th>
  );
};
