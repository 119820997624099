import React from "react";
import { useTheme } from "v2/03-hooks";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { SplitButton, SplitButtonOption } from "./SplitButton";

export const SplitButtonExamples = () => {
  const theme = useTheme();
  const buttonOptions: SplitButtonOption[] = [
    {
      key: "merge",
      title: "Merge",
      onClick: () => {
        alert("All commits from this branch will be added to the base branch via a merge commit!");
      }
    },
    {
      key: "Rebase",
      onClick: () => {
        alert("The 1 commit from this branch will be rebased and added to the base branch!");
      }
    },
    {
      key: "Squash",
      onClick: () => {
        alert("The 1 commit from this branch will be added to the base branch!");
      }
    }
  ];

  const promisedButtonOptions = [
    {
      key: "merge",
      title: "Merge",
      onClickWithLoading: async () => {
        await new Promise(res => {
          setTimeout(res, 2000);
        });
        alert("All commits from this branch will be added to the base branch via a merge commit!");
      }
    },
    {
      key: "Rebase",
      onClickWithLoading: async () => {
        await new Promise(res => {
          setTimeout(res, 2000);
        });
        alert("The 1 commit from this branch will be rebased and added to the base branch!");
      }
    },
    {
      key: "Squash",
      onClickWithLoading: async () => {
        await new Promise(res => {
          setTimeout(res, 2000);
        });
        alert("The 1 commit from this branch will be added to the base branch!");
      }
    }
  ];
  return (
    <div>
      <ComponentSection title="Split Button">
        <SplitButton options={buttonOptions}></SplitButton>
        <SplitButton variant="blended" options={buttonOptions}></SplitButton>
        <SplitButton variant="outlined" options={buttonOptions}></SplitButton>
      </ComponentSection>

      <ComponentSection title="Split Button with onClickWithLoading">
        <SplitButton options={promisedButtonOptions}></SplitButton>
        <SplitButton variant="blended" options={promisedButtonOptions}></SplitButton>
        <SplitButton variant="outlined" options={promisedButtonOptions}></SplitButton>
      </ComponentSection>

      <ComponentSection title="Split Button with tooltips">
        <SplitButton
          options={buttonOptions}
          tooltips={{ main: "Do merge commit", icon: "See more" }}
        ></SplitButton>
        <SplitButton variant="blended" options={buttonOptions}></SplitButton>
        <SplitButton
          variant="outlined"
          options={buttonOptions}
          tooltips={{ icon: "See more" }}
        ></SplitButton>
      </ComponentSection>

      <ComponentSection title="Split Button Small">
        <SplitButton options={buttonOptions} size="small"></SplitButton>
        <SplitButton variant="blended" options={buttonOptions} size="small"></SplitButton>
        <SplitButton variant="outlined" options={buttonOptions} size="small"></SplitButton>
      </ComponentSection>

      <ComponentSection title="Split Button Loading">
        <SplitButton options={buttonOptions} loading></SplitButton>
        <SplitButton variant="blended" options={buttonOptions} loading></SplitButton>
        <SplitButton variant="outlined" options={buttonOptions} loading></SplitButton>
      </ComponentSection>

      <ComponentSection title="Split Button Disabled">
        <SplitButton options={buttonOptions} disabled></SplitButton>
        <SplitButton variant="blended" options={buttonOptions} disabled></SplitButton>
        <SplitButton variant="outlined" options={buttonOptions} disabled></SplitButton>
      </ComponentSection>
    </div>
  );
};
