import React, { createContext, ReactNode, useState } from "react";
import { ConfirmationConfig, ConfirmationModal, ToastProps, useToast } from "../..";
import { ToastProvider } from "..";

type ErrorHandler = (e: Error) => unknown;
type ErrorMessage = string;
type ErrorMessageAndDescription = Pick<ToastProps, "title" | "message">;
type ErrorHandling = ErrorHandler | ErrorMessage | ErrorMessageAndDescription;

type OnActionConfig = <T extends any[]>(
  actionFunc: (...args: T) => any,
  errorHandling?: ErrorHandling,
  throwOnError?: boolean
) => (...args: T) => Promise<any>;

type OnConfirmActionConfig = <T extends any[]>(
  actionFunc: (...args: T) => any,
  confirmConfig?: Partial<ConfirmationConfig>,
  errorHandling?: ErrorHandling
) => (...args: T) => void;

export interface ActionContextData {
  onAction: OnActionConfig;
  onConfirmAction: OnConfirmActionConfig;
}

export const ActionContext = createContext<ActionContextData>({
  onAction: () => {
    throw new Error("Tried to use onAction outside ActionProvider");
  },
  onConfirmAction: () => {
    throw new Error("Tried to use onConfirmAction outside ActionProvider");
  }
});

interface ToastableActionProviderProps {
  children: ReactNode;
  defaultErrorMessage: string;
}

interface ActiveConfirm {
  actionFunc: (...args: any[]) => any;
  actionArgs: any[];
  confirmConfig?: Partial<ConfirmationConfig>;
  errorHandling?: ErrorHandling;
  visible: boolean;
}

const ToastableActionProvider = ({
  children,
  defaultErrorMessage
}: ToastableActionProviderProps) => {
  const toast = useToast();
  const [activeConfirm, setActiveConfirm] = useState<ActiveConfirm | undefined>();

  const defaultErrorHandler = (
    err: Error,
    message?: ErrorMessage | ErrorMessageAndDescription,
    throwOnError?: boolean
  ) => {
    if (message && (message as any).title) {
      const errorConfig = message as ErrorMessageAndDescription;
      toast.show({ type: "error", title: errorConfig.title, message: errorConfig.message });
    } else if (message) {
      toast.error(message as ErrorMessage);
    } else {
      toast.error(defaultErrorMessage);
    }

    if (throwOnError) throw err;
  };

  const catchFunction = (err: Error, errorHandling?: ErrorHandling, throwOnError?: boolean) => {
    if (errorHandling && typeof errorHandling === "function") {
      return errorHandling(err);
    } else {
      return defaultErrorHandler(err, errorHandling, throwOnError);
    }
  };

  const onAction = <T extends any[]>(
    actionFunc: (...args: T) => any,
    errorHandling?: ErrorHandling,
    throwOnError?: boolean
  ) => async (...args: T): Promise<any> => {
    try {
      return await actionFunc(...args);
    } catch (err) {
      catchFunction(err as Error, errorHandling, throwOnError);
    }
  };

  const onConfirmAction = <T extends any[]>(
    actionFunc: (...args: T) => any,
    confirmConfig?: Partial<ConfirmationConfig>,
    errorHandling?: ErrorHandling
  ) => (...args: T) => {
    setActiveConfirm({
      actionFunc,
      actionArgs: args,
      confirmConfig,
      errorHandling,
      visible: true
    });
  };

  const onActionConfirmed = async () => {
    const { actionFunc, actionArgs, errorHandling } = activeConfirm!;

    try {
      const response = await actionFunc(...actionArgs);
      setActiveConfirm({ ...activeConfirm!, visible: false });
      return response;
    } catch (err) {
      catchFunction(err as Error, errorHandling);
    }
  };

  return (
    <ActionContext.Provider value={{ onAction, onConfirmAction }}>
      {children}
      {activeConfirm && (
        <ConfirmationModal
          config={activeConfirm.confirmConfig}
          show={activeConfirm.visible}
          onCancel={() => setActiveConfirm({ ...activeConfirm, visible: false })}
          onClose={() => setActiveConfirm({ ...activeConfirm, visible: false })}
          onConfirm={onActionConfirmed}
        />
      )}
    </ActionContext.Provider>
  );
};

ToastableActionProvider.defaultProps = {
  defaultErrorMessage: "Oops, something went wrong."
};

export const ActionProvider = (props: ToastableActionProviderProps) => (
  <ToastableActionProvider {...props} />
);
