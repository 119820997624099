import { Checkbox, CheckboxBaseProps } from ".";
import cx from "classnames";
import styles from "./Checkbox.module.scss";
import { Title } from "..";
import React, { ComponentProps } from "react";

interface LabelledCheckboxProps extends CheckboxBaseProps, Omit<ComponentProps<"div">, "onChange"> {
  label: string;
}

export const LabelledCheckbox = ({
  label,
  disabled,
  readonly,
  checked,
  className,
  onChange,
  ...rest
}: LabelledCheckboxProps) => {
  const stateStyles = cx({
    [styles.Disabled]: disabled,
    [styles.Checked]: checked
  });

  const checkboxBase = (
    <Checkbox checked={checked} disabled={disabled} readonly={readonly} onChange={onChange} />
  );

  return label ? (
    <div className={cx(styles.Wrapper, className)} {...rest}>
      {checkboxBase}
      <Title className={cx(styles.Title, stateStyles)}>{label}</Title>
    </div>
  ) : (
    checkboxBase
  );
};
