import React from "react";
import styled from "styled-components";
import { Button, Card, Layout, Title } from "../../01-atoms";
import { useHistory, useLocation } from "react-router-dom";
import { ModalV2, ConfirmationModalV2 } from ".";

const StyledModalV2Example = styled.div``;

const MOCK_LIST_ITEMS = [
  { id: "1", title: "Item 1" },
  { id: "2", title: "Item 2" },
  { id: "3", title: "Item 3" },
  { id: "4", title: "Item 4" },
  { id: "5", title: "Item 5" },
  { id: "6", title: "Item 6" },
  { id: "7", title: "Item 7" },
  { id: "8", title: "Item 8" },
  { id: "9", title: "Item 9" },
  { id: "10", title: "Item 10" },
  { id: "11", title: "Item 11" },
  { id: "12", title: "Item 12" },
  { id: "13", title: "Item 13" },
  { id: "14", title: "Item 14" },
  { id: "15", title: "Item 15" },
  { id: "16", title: "Item 16" },
  { id: "17", title: "Item 17" },
  { id: "18", title: "Item 18" },
  { id: "19", title: "Item 19" },
  { id: "20", title: "Item 20" }
];

export const ModalV2Example = ({ ...rest }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <StyledModalV2Example {...rest}>
      <Button onClick={() => history.push("#modal")}>Open</Button>
      <ModalV2
        title="Example modal"
        open={location.hash.includes("#modal")}
        onClose={() => history.goBack()}
        preContent={<Title>Liiba laaba hibabasjahs</Title>}
        afterContent={<Title>Liiba laaba hibabasjahs</Title>}
        footer={
          <Layout.Group justifyContent="flex-end">
            <Button onClick={() => history.goBack()}>Close</Button>
          </Layout.Group>
        }
      >
        <Layout.Group vertical gap="small">
          {MOCK_LIST_ITEMS.map(item => (
            <Card key={item.id}>
              <Title variant="bold">{item.title}</Title>
            </Card>
          ))}
        </Layout.Group>
      </ModalV2>

      <Button onClick={() => history.push("#confirmation-modal")}>Open Confirmation Modal</Button>
      <ConfirmationModalV2
        destructive
        title="Delete item"
        confirmationWord="DELETE"
        onCancel={() => history.goBack()}
        onConfirm={async () => {
          await new Promise(res => {
            setTimeout(res, 2000);
          });
          alert(
            "All commits from this branch will be added to the base branch via a merge commit!"
          );
          history.goBack();
        }}
        open={location.hash.includes("#confirmation-modal")}
        description="Are you sure you want to delete this item?"
      />
      {MOCK_LIST_ITEMS.map(item => (
        <Card key={item.id}>
          <Title variant="bold">{item.title}</Title>
        </Card>
      ))}
    </StyledModalV2Example>
  );
};
