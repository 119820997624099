import React from "react";

import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Title, Tooltip } from "../../01-atoms";
import { useTheme } from "../../03-hooks";
import { ColorVariants } from "../../04-providers";

const ColorExample = ({ title, color }: { title: string; color: string }) => {
  return (
    <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
      <Tooltip tooltip={color}>
        <div style={{ width: "32px", height: "32px", borderRadius: "16px", background: color }} />
      </Tooltip>
      <Title>{title}</Title>
    </div>
  );
};

const ColorVariantsExample = ({
  colorVariant,
  title
}: {
  colorVariant: ColorVariants;
  title: string;
}) => {
  const { theme } = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing.small,
        marginTop: theme.spacing.large
      }}
    >
      <ColorExample title={`${title}/Pressed`} color={colorVariant.pressed} />
      <ColorExample title={`${title}/Hovered`} color={colorVariant.hovered} />
      <ColorExample title={`${title}/Default`} color={colorVariant.default} />
      <ColorExample title={`${title}/Subdued`} color={colorVariant.subdued} />
      <ColorExample title={`${title}/Dimmed`} color={colorVariant.dimmed} />
      <ColorExample title={`${title}/Ghosted`} color={colorVariant.ghosted} />
    </div>
  );
};

export const ColorsExamples = () => {
  const { theme } = useTheme();

  return (
    <div>
      <ComponentSection
        title="Theme colors"
        description="This is the color palette available by default through theme"
        columns={3}
      >
        <ColorVariantsExample title="Foreground" colorVariant={theme.palette.foreground} />
        <ColorVariantsExample title="Background" colorVariant={theme.palette.background} />
        <ColorVariantsExample title="Surface" colorVariant={theme.palette.surface} />
        <ColorVariantsExample title="Border" colorVariant={theme.palette.border} />
        <ColorVariantsExample title="Primary" colorVariant={theme.palette.primary} />
        <ColorVariantsExample title="Highlight" colorVariant={theme.palette.highlight} />
        <ColorVariantsExample title="Warning" colorVariant={theme.palette.warning} />
        <ColorVariantsExample title="Success" colorVariant={theme.palette.success} />
        <ColorVariantsExample title="Danger" colorVariant={theme.palette.danger} />
        <ColorVariantsExample title="Purple" colorVariant={theme.palette.purple} />
      </ComponentSection>
    </div>
  );
};
