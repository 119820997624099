import React, { useRef, useState } from "react";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Table, TableColumn } from "./Table";
import { Button, Card, Popover, Stack, Tag, Title } from "../../01-atoms";
import { useTheme, useToast } from "../../03-hooks";
import { ActionMenu } from "../ActionMenu";
import { TableHeader } from "./TableHeader";
import { TableActionBar } from "./TableActionBar";
import { CategorisedOption } from "../CategorySelect";
import styled from "styled-components";

interface SampleTableData {
  name: string;
  description: string;
  label: string;
  count: number;
}

const StyledTableExamples = styled.div`
  padding: 2rem;
  max-width: 1440px;
  margin: 0 auto;
`;

export const TableExamples = () => {
  const { theme } = useTheme();
  const toast = useToast();

  const sampleCols: TableColumn<any>[] = [
    { key: "name", title: "Name", sortable: true, width: "20%" },
    { key: "description", title: "Description", sortable: false, width: "50%" },
    { key: "label", title: "Label", sortable: true },
    { key: "count", title: "Count", sortable: true, textAlign: "center", width: "20%" }
  ];

  const sampleRenderedCols: TableColumn<any>[] = [
    {
      key: "name",
      title: "Name",
      sortable: true,
      width: "20%",
      render: (value: SampleTableData) => <Title variant="bold">{value.name}</Title>
    },
    { key: "description", title: "Description", sortable: false, width: "50%" },
    {
      key: "label",
      title: "Label",
      sortable: true,
      disableClick: true,
      render: (value: SampleTableData) => (
        <Tag onClick={() => toast.info("Tag clicked")}>{value.label}</Tag>
      )
    },
    { key: "count", title: "Count", sortable: true, textAlign: "center" },
    { key: "actions", render: () => <ActionMenu options={[]} />, disableClick: true }
  ];

  const sampleData: SampleTableData[] = [
    {
      name: "Test row",
      description: "Some description for testing",
      label: "onboarding",
      count: 4
    },
    { name: "Another row", description: "More row data", label: "onboarding", count: 5 },
    { name: "Third row", description: "Yet another row data", label: "offboarding", count: 2 }
  ];

  const [filters, setFilters] = useState<CategorisedOption[]>([
    {
      key: "onboarding",
      title: "Onboarding",
      category: "label"
    },
    {
      key: "offboarding",
      title: "Offboarding",
      category: "label"
    }
  ]);

  const filterCategories = [
    { key: "onboarding", title: "Onboarding", category: "label" },
    { key: "offboarding", title: "Offboarding", category: "label" }
  ];

  const filterOptions: CategorisedOption[] = [
    { key: "onboarding", title: "Onboarding", category: "label" },
    { key: "offboarding", title: "Offboarding", category: "label" }
  ];

  const popoverRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <StyledTableExamples>
      <ComponentSection
        title="Simple data table"
        description="Table can be set up with simple object data set"
      >
        <TableActionBar
          title="Hiihoi"
          description="wdap"
          filter={{
            categories: filterCategories,
            value: filters,
            options: filterOptions,
            onFilterChange: setFilters
          }}
          actions={
            <Button variant="outlined" size="small">
              Actions
            </Button>
          }
          endActions={
            <Button variant="outlined" size="small">
              End actions
            </Button>
          }
          search={{
            value: "",
            onChange: () => {}
          }}
        />

        <Table
          columns={sampleCols.map(col => ({ ...col }))}
          data={sampleData}
          onClick={(_, event) => console.debug(event)}
        />
      </ComponentSection>
      <ComponentSection title="Empty table" description="Table can show a state when no data">
        <Table columns={sampleCols.map(col => ({ ...col, sortable: false }))} data={[]} />
        <Table
          columns={sampleCols.map(col => ({ ...col, sortable: false }))}
          data={[]}
          emptyState={
            <Title variant="bold">
              hiihoi ja halojatapäivää hiihoi ja halojatapäivää hiihoi ja halojatapäivää hiihoi ja
              halojatapäivää
            </Title>
          }
        />
        <Table
          columns={sampleCols.map(col => ({ ...col, sortable: false }))}
          data={[]}
          emptyState={
            <Title variant="bold" style={{ margin: theme.spacing.xxLarge, textAlign: "center" }}>
              Custom component as empty state
            </Title>
          }
        />
      </ComponentSection>
      <ComponentSection title="Custom components" description="Any cell can use custom renderer">
        <Table
          columns={sampleRenderedCols.map(col => ({ ...col, sortable: false }))}
          data={sampleData}
        />
      </ComponentSection>

      <ComponentSection
        title="Sortable table"
        description="Table can be sorted by selected columns"
      >
        <Table columns={sampleCols} data={sampleData} />
      </ComponentSection>
      <ComponentSection
        title="Clickable rows"
        description="Table can have a click handler for rows"
      >
        <div ref={popoverRef}>
          <button onClick={() => setOpen(!open)}>Popover</button>
          <Popover
            backdrop
            open={open}
            referenceElement={popoverRef.current}
            onClose={() => setOpen(false)}
          >
            <Card>
              <Stack vertical>
                <Title>moi</Title>
                <Title>moi</Title>
                <Title>moi</Title>
                <Title>moi</Title>
                <Title>moi</Title>
                <Title>moi</Title>
              </Stack>
            </Card>
          </Popover>
        </div>
        <Table
          columns={sampleRenderedCols}
          data={sampleData}
          rowHeightRem={3}
          onClick={row => toast.info(`${row.name} clicked!`)}
        />
      </ComponentSection>
      <ComponentSection title="Loading state" description="Table can be set loading with a prop">
        <Table loadingRowAmount={7} columns={sampleRenderedCols} data={sampleData} loading />
      </ComponentSection>

      <ComponentSection
        title="Table header"
        description="One can have a table header without table"
      >
        <TableHeader
          style={{ width: "100%" }}
          setSortedBy={() => {}}
          toggleSortDir={() => {}}
          columns={sampleRenderedCols}
        />
      </ComponentSection>
    </StyledTableExamples>
  );
};
