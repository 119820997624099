import React, { ReactNode, useMemo, useState } from "react";
import { ModalV2, ModalV2Props } from "../ModalV2";
import { Button, Layout } from "../../../01-atoms";
import { InputV2 } from "v2/01-atoms/InputV2";
import { useKeyDownListener } from "v2/03-hooks";

type Props = Omit<ModalV2Props, "preContent" | "title"> & {
  description?: ReactNode;
  destructive?: boolean;
  title: string;
  confirmButtonText?: string;
  confirmationWord?: string;
  onConfirm: VoidFunction;
  onConfirmed?: VoidFunction;
  onCancel: VoidFunction;
};

export const ConfirmationModalV2 = ({
  description,
  confirmationWord,
  confirmButtonText = "Confirm",
  destructive,
  onConfirm,
  onConfirmed,
  onCancel,
  ...rest
}: Props) => {
  const [confirming, setConfirming] = useState(false);
  const [confirmationWordInputValue, setConfirmationWordInputValue] = useState<string>("");

  const confirmDisabled = useMemo(() => {
    if (!confirmationWord) {
      return true;
    }

    return confirmationWord !== confirmationWordInputValue;
  }, [confirmationWord, confirmationWordInputValue]);

  const getPreContent = () => {
    if (!confirmationWord) {
      return description;
    }

    return (
      <Layout.Group vertical gap="small">
        {description}
        <InputV2
          fullWidth
          autoFocus
          value={confirmationWordInputValue}
          title={`Type the word "${confirmationWord}" to continue`}
          onChange={e => setConfirmationWordInputValue(e.target.value)}
        />
      </Layout.Group>
    );
  };

  const onConfirmWithLoading = async () => {
    setConfirming(true);
    return Promise.resolve(onConfirm()).finally(() => {
      setConfirming(false);
      setConfirmationWordInputValue("");
      onConfirmed && onConfirmed();
    });
  };

  useKeyDownListener([
    {
      key: "Enter",
      callback: onConfirmWithLoading,
      disabled: confirmDisabled
    }
  ]);

  return (
    <ModalV2
      {...rest}
      hideCloseButton
      preContent={getPreContent()}
      onClose={onCancel}
      footer={
        <Layout.Group justifyContent="flex-end">
          <Button variant="outlined" disabled={confirming} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            loading={confirming}
            destructive={destructive}
            disabled={confirmDisabled || confirming}
            onClickWithLoading={onConfirmWithLoading}
          >
            {confirmButtonText}
          </Button>
        </Layout.Group>
      }
    />
  );
};
