import cx from "classnames";
import React, { ComponentProps, ReactNode } from "react";

import styles from "./Loader.module.scss";

export type LoaderSize = "tiny" | "small" | "medium" | "large";

export interface LoaderProps extends ComponentProps<"div"> {
  color?: string;
  size?: LoaderSize;
  fillParent?: boolean;
  icon: ReactNode;
}

export const Loader = ({ size = "medium", fillParent, icon, className, ...rest }: LoaderProps) => {
  return (
    <div
      className={cx(styles.Loader, styles[size], { [styles.FillParent]: fillParent }, className)}
      {...rest}
    >
      {icon}
    </div>
  );
};
