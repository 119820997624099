import React, { useRef, useState } from "react";
import { Icon, IconButton, Option, OptionList, Popover, Tooltip } from "../../";
import styles from "./SplitButton.module.scss";
import { Button, ButtonProps, useButtonForegroundColor } from "../../01-atoms/Button";
import cx from "classnames";

export interface SplitButtonOption extends Option {
  onClick?: () => any;
  onClickWithLoading?: () => Promise<any>;
}

export interface SplitButtonProps
  extends Pick<ButtonProps, "variant" | "size" | "loading" | "disabled"> {
  options: SplitButtonOption[];
  tooltips?: {
    main?: string;
    icon?: string;
  };
}

export interface ButtonWrapperProps {
  children: any;
  tooltip?: string;
}

const ButtonWrapper = ({ children, tooltip }: ButtonWrapperProps) => {
  if (!tooltip) {
    return children;
  }

  return <Tooltip tooltip={tooltip}>{children}</Tooltip>;
};

export const SplitButton = ({ options, tooltips, ...buttonProps }: SplitButtonProps) => {
  const [referenceElement, setRefElement] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [main, ...optionals] = options;

  const { foregroundColor } = useButtonForegroundColor(
    buttonProps.variant ?? "filled",
    !!buttonProps.disabled
  );

  const buildOptions = () => {
    return optionals.map(option => {
      return {
        ...option,
        onClick: async () => {
          setOpen(false);
          if (!!option.onClick) return option.onClick();
          if (!!option.onClickWithLoading) {
            setLoading(true);
            await option.onClickWithLoading();
            setLoading(false);
            return;
          }
        }
      };
    });
  };

  return (
    <div ref={setRefElement} className={styles.SplitButton}>
      <ButtonWrapper tooltip={tooltips?.main}>
        <Button
          onClick={main.onClick}
          onClickWithLoading={main.onClickWithLoading}
          className={cx(styles.Main, styles[buttonProps.variant ?? "NO-VARIANT"], {
            [styles.Tooltip]: !!tooltips?.main
          })}
          {...buttonProps}
        >
          {main.title ?? main.key}
        </Button>
      </ButtonWrapper>

      <ButtonWrapper tooltip={tooltips?.icon}>
        <Button
          className={cx(styles.IconButton, styles[buttonProps.variant ?? "NO-VARIANT"], {
            [styles.Tooltip]: !!tooltips?.icon
          })}
          onClick={() => setOpen(!open)}
          {...buttonProps}
          loading={loading}
          disabled={buttonProps.loading || buttonProps.disabled}
        >
          <Icon name="chevronDown" color={foregroundColor}></Icon>
        </Button>
      </ButtonWrapper>

      <Popover
        referenceElement={referenceElement}
        onClose={function () {
          setOpen(false);
        }}
        attachToRef
        open={open}
        sameWidthWithReference
      >
        <OptionList options={buildOptions()}></OptionList>
      </Popover>
    </div>
  );
};
