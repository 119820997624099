import React, { ComponentProps, useEffect } from "react";
import { DirectoryComponent } from "../ComponentDirectoryRoute";
import { Button, H2, Title } from "../../01-atoms";
import { Icon } from "../../00-assets";

import styles from "./ComponentExamplesRoute.module.scss";
import cx from "classnames";
import { TopBar } from "../../05-templates";
import { ComponentNav } from "../ComponentDirectoryRoute/ComponentNav";
import { ComponentDirectoryList } from "../ComponentDirectoryRoute/ComponentDirectoryList";

export interface ComponentPageProps extends ComponentProps<"div"> {
  component: DirectoryComponent;
  components: DirectoryComponent[];
  disableContainerStyles?: boolean;
}

export const ComponentExamplesRoute = ({
  component,
  components,
  className,
  disableContainerStyles = false,
  ...rest
}: ComponentPageProps) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const containerStyles = !disableContainerStyles
    ? {
        maxWidth: "760px",
        margin: "2rem auto",
        width: "100%"
      }
    : undefined;

  return (
    <div className={cx(styles.Root, className)} {...rest}>
      <div className={styles.Content}>
        <TopBar sticky title={component.name} style={{ marginLeft: "270px" }} />
        <div style={containerStyles}>
          <component.examples />
        </div>
      </div>
    </div>
  );
};
