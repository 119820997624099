import moment from "moment";
import React, { useState } from "react";
import { ComponentSection } from "v2/09-routes/ComponentExamplesRoute/ComponentSection";

import { DateRangeSelect, Dates } from "./";

export const DateRangeSelectExamples: React.VFC = () => {
  const [dates, setDates] = useState<Dates>({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: null
  });

  return (
    <ComponentSection
      title="Default"
    >
      <DateRangeSelect onChangeDates={setDates} dates={dates} />
    </ComponentSection>
  );
};
