import React from "react";
import { DirectoryComponent } from "./index";

import { ReactComponent as Logo } from "./logo-all-white.svg";
import { CollapsibleNav } from "../../05-templates/CollapsibleNav";

import styles from "./ComponentNav.module.scss";
import cx from "classnames";

export interface ComponentNavProps {
  components: DirectoryComponent[];
  onLogoClick?: () => unknown;
  selectedKey?: string;
}

export const ComponentNav = ({ components, onLogoClick, selectedKey }: ComponentNavProps) => {
  const componentItems = components.map(c => ({
    key: c.name,
    section: c.category || "default",
    href: `/${c.name}`
  }));

  return (
    <div className={styles.Root}>
      <div className={cx(styles.Logo, { [styles.Clickable]: onLogoClick })}>
        <Logo onClick={onLogoClick} />
      </div>
      <CollapsibleNav items={componentItems} selectedKey={selectedKey} />
    </div>
  );
};
