import React, { ComponentProps, useCallback } from "react";

import styles from "./Breadcrumb.module.scss";
import cx from "classnames";
import { Title } from "../../01-atoms";
import { useTheme } from "../../03-hooks";
import styled from "styled-components";

export interface BreadcrumbPath {
  key: string;
  title: string;
  onClick?: () => void;
  startAdornment?: React.ReactNode;
}

export interface BreadcrumbProps extends ComponentProps<"div"> {
  paths: BreadcrumbPath[];
}

const StyledLayout = styled.div<{ $clickable: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: var(--spacing-small);
  ${({ $clickable }) => $clickable && `cursor: pointer;`}
`;

export const Breadcrumb = ({ paths, className, ...rest }: BreadcrumbProps) => {
  const { theme } = useTheme();

  const withSeparators = useCallback((pathElems: any[]) => {
    return pathElems.flatMap((elem, index) => {
      if (index < pathElems.length - 1)
        return [
          elem,
          <Title key={`breadcrumb-separator-${index}`} color={theme.palette.foreground.dimmed}>
            /
          </Title>
        ];
      return elem;
    });
  }, []);

  return (
    <div className={cx(styles.Root, className)} {...rest}>
      {withSeparators(
        paths.map((p, index) => {
          const isCurrent = index === paths.length - 1;
          const isClickable = !isCurrent && !!p.onClick;

          return (
            <StyledLayout
              key={p.key}
              onClick={isClickable ? p.onClick : undefined}
              $clickable={isClickable}
            >
              {p.startAdornment}
              <Title
                variant="bold"
                color={
                  isCurrent ? theme.palette.foreground.default : theme.palette.foreground.subdued
                }
              >
                {p.title}
              </Title>
            </StyledLayout>
          );
        })
      )}
    </div>
  );
};
