import React, { useState } from "react";
import { DayOfWeekShape } from "react-dates";
import { Button, Option, Select } from "../..";
import { DatePicker, DatePickerProps } from "./DatePicker";

interface PickerExampleProps
  extends Omit<DatePickerProps, "onClose" | "open" | "referenceElement"> {
  buttonTitle?: string;
  button?: JSX.Element;
}

const DatePickerExample = ({ buttonTitle, button }: PickerExampleProps) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={e => {
          setRefElement(e.currentTarget);
          setOpen(true);
        }}
      >
        {buttonTitle || "Open datepicker"}
      </Button>
      <DatePicker referenceElement={refElement} open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const DatePickerExamples = () => {
  const [sundayElement, setSundayElement] = useState<HTMLElement | null>(null);
  const [selectedFirstDayOfWeek, setFirstDayOfWeek] = useState<Option>();

  const handleClose = (dispatch: any) => () => dispatch(null);

  const handleOpen = (dispatch: any) => (event: any) => dispatch(event.currentTarget);

  return (
    <div style={{ display: "grid", gap: "2rem", justifyContent: "center" }}>
      <div style={{ display: "grid", gap: "1rem", width: "fit-content" }}>
        <DatePickerExample buttonTitle="Default" />
        <DatePickerExample buttonTitle="Disabled past" disablePast />
      </div>

      <div style={{ display: "grid", gap: "1rem", width: "fit-content" }}>
        <Select
          sameWidthWithReference
          options={[
            { key: "1", title: "Monday" },
            { key: "2", title: "Tuesday" },
            { key: "3", title: "Wednesday" },
            { key: "4", title: "Thursday" },
            { key: "5", title: "Friday" },
            { key: "6", title: "Saturday" },
            { key: "0", title: "Sunday" }
          ]}
          value={selectedFirstDayOfWeek?.key}
          onSelect={_ => setFirstDayOfWeek(_)}
        />
        <Button onClick={handleOpen(setSundayElement)}>{`${
          selectedFirstDayOfWeek?.title || "Monday"
        } as first day of week`}</Button>
        <DatePicker
          firstDayOfWeek={
            selectedFirstDayOfWeek && (parseInt(selectedFirstDayOfWeek.key, 10) as DayOfWeekShape)
          }
          referenceElement={sundayElement}
          onClose={handleClose(setSundayElement)}
        />
      </div>
    </div>
  );
};
