import React, { useState } from "react";
import { Field } from "./Field";
import { Input } from "../../01-atoms";
import { Select } from "../Select";

export const FieldExamples = () => {
  const [readonly, setReadonly] = useState(true);

  return (
    <div style={{ display: "grid", gridGap: "1rem" }}>
      <Field>
        <Input placeholder="This is plain example" />
      </Field>
      <Field title="Label">
        <Input placeholder="This is title example" />
      </Field>
      <Field title="Label" required>
        <Input placeholder="This is title example with required" />
      </Field>
      <Field error="error">
        <Input placeholder="This is error example" error />
      </Field>
      <Field title="Another title" error="Ups something went totally wrong!">
        <Input placeholder="This has it all" error value="New bond premier 2020" />
      </Field>
      <Field title="Select example">
        <Select options={[{ key: "1", title: "Option 1" }]} onSelect={() => {}} />
      </Field>
      <Field title="Label" required>
        <Input variant="blended" placeholder="Blended example" />
      </Field>
      <Field title="Readonly">
        <Input
          value="Readonly value"
          readonly={readonly}
          onClick={() => setReadonly(false)}
          onBlur={() => setReadonly(true)}
        />
      </Field>
    </div>
  );
};
