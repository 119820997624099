import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface ISidebarAppLayout {
  appMenu: ReactNode;
  breakpoint?: string;
  appMenuCollapsed?: boolean;
  children?: ReactNode;
}

const StyledSidebarAppLayout = styled.div`
  display: flex;
`;

const View = styled.div`
  flex: 1;
`;

export const SidebarAppLayout: FC<ISidebarAppLayout> = ({
  appMenu,
  appMenuCollapsed,
  children,
  ...rest
}) => {
  return (
    <StyledSidebarAppLayout {...rest}>
      {appMenu}
      <View>{children}</View>
    </StyledSidebarAppLayout>
  );
};
