import React, { useEffect, useRef } from "react";

import logo from "./logo.svg";

import styled, { css } from "styled-components";
import { Title } from "v2/01-atoms/Typography";
import gsap from "gsap";

const lerp = (start: number, end: number, t: number): number => {
  return start * (1 - t) + end * t;
};

export type IntroistLoaderProps = {
  fillParent?: boolean;
  globalCenter?: boolean;
};

const StyledIntroistLoader = styled.div<{ $fillParent: boolean; $gloablCenter: boolean }>`
  ${p =>
    p.$fillParent &&
    css`
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${p =>
    p.$gloablCenter &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

const LogoBackground = styled.div`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background-color: white;
  box-shadow: var(--shadow-default);
  z-index: 1;

  > img {
    width: 2rem;
  }

  > h5 {
    left: calc(50% + 16px);
    transform: translateX(-50%);
    position: absolute;
    width: 10rem;
    bottom: -4rem;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Ripple = styled.svg`
  position: absolute;
  display: block;
  transform-origin: center;
  circle {
    stroke: var(--palette-primary-dimmed);
  }
`;

export const IntroistLoader = ({ fillParent, globalCenter }: IntroistLoaderProps) => {
  const numCircles = 6;
  const circleRefs = useRef(new Array(numCircles).fill(null));

  useEffect(() => {
    circleRefs.current.forEach((ref, index) => {
      gsap.fromTo(
        ref,
        { r: 0, autoAlpha: 1 },
        { r: 200, autoAlpha: 0, repeat: -1, delay: index * 1, duration: 4 }
      );
    });
  }, []);

  return (
    <StyledIntroistLoader $fillParent={!!fillParent} $gloablCenter={!!globalCenter}>
      <ImageWrapper>
        {circleRefs.current.map((_, index) => (
          <Ripple key={index} width="1000" height="1000" xmlns="http://www.w3.org/2000/svg">
            <circle
              ref={el => (circleRefs.current[index] = el)}
              cx="500"
              cy="500"
              r="0"
              stroke="black"
              strokeWidth="1"
              fill="none"
            />
          </Ripple>
        ))}
        <LogoBackground>
          <img src={logo} alt="introist-logo" />
          <Title>Loading workspace...</Title>
        </LogoBackground>
      </ImageWrapper>
    </StyledIntroistLoader>
  );
};
