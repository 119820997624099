import React, { ReactNode, useEffect, useState } from "react";
import { IconName } from "../../00-assets";

import { Field, Modal, ModalProps } from "../../02-blocks";
import { Input } from "../../01-atoms";
import { ColorVariants } from "../../04-providers";

export interface ConfirmationConfig {
  icon?: IconName;
  title: string;
  description: ReactNode;
  confirmTitle: string;
  cancelTitle: string;
  confirmationWord?: string;
}

const defaultConfig = {
  title: "Confirm",
  description: "Confirm the action",
  confirmTitle: "Confirm",
  cancelTitle: "Cancel"
};

interface Props
  extends Omit<
    ModalProps,
    | "open"
    | "onCloseWithOutsideClick"
    | "mobile"
    | "onClose"
    | "primaryButton"
    | "secondaryButton"
    | "description"
    | "title"
    | "icon"
    | "color"
  > {
  show: boolean;
  config?: Partial<ConfirmationConfig>;
  onConfirm?: () => any;
  onCancel?: () => any;
  onClose?: () => any;
  closeOnOutsideClick?: boolean;
  destructive?: boolean;
}

export const ConfirmationModal = ({
  show,
  onConfirm,
  onCancel,
  onClose,
  config,
  closeOnOutsideClick = false,
  destructive,
  ...rest
}: Props) => {
  const [confirming, setConfirming] = useState(false);
  const [primaryActionEnabled, setPrimaryActionEnabled] = useState(!config?.confirmationWord);

  const [confirmationWord, setConfirmationWord] = useState("");

  useEffect(() => {
    if (config?.confirmationWord) {
      setPrimaryActionEnabled(
        confirmationWord.toLowerCase() === config?.confirmationWord.toLowerCase()
      );
    }
  }, [confirmationWord, setPrimaryActionEnabled]);

  const fullConfig: ConfirmationConfig = { ...defaultConfig, ...config };

  const doConfirmWithLoading = async () => {
    if (!onConfirm) return;
    setConfirming(true);
    return Promise.resolve(onConfirm()).finally(() => {
      setConfirmationWord("");
      setConfirming(false);
    });
  };

  return (
    <Modal
      open={show}
      onCloseWithOutsideClick={closeOnOutsideClick && !confirming ? onClose : undefined}
      mobile="centered"
      onClose={onClose}
      primaryButton={{
        disabled: !primaryActionEnabled,
        onClickWithLoading: doConfirmWithLoading,
        children: fullConfig.confirmTitle,
        destructive
      }}
      secondaryButton={
        onCancel && {
          onClick: () => !confirming && onCancel && onCancel(),
          children: fullConfig.cancelTitle
        }
      }
      {...fullConfig}
      {...rest}
    >
      {config?.confirmationWord && (
        <Field title={`Type "${config?.confirmationWord}" to confirm this action`}>
          <Input
            value={confirmationWord}
            onChange={setConfirmationWord}
            placeholder={config?.confirmationWord}
          />
        </Field>
      )}
    </Modal>
  );
};
