import cx from "classnames";
import React, { ComponentProps } from "react";

interface StyledComponentProps {
  tag: keyof JSX.IntrinsicElements;
}

const StyledComponent: React.FunctionComponent<
  StyledComponentProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ tag: Tag, ...rest }) => {
  return <Tag {...rest} />;
};

export const styled = (
  tag: keyof JSX.IntrinsicElements,
  { className, style, ...rest }: ComponentProps<any>,
  ...styles: (string | object)[]
) => {
  const objectStyles = styles.filter(s => typeof s === "object").map(s => s as object);
  const classStyles = styles.filter(s => typeof s === "string").map(s => s as string);

  const styleObject = objectStyles.reduce((merged, next) => ({ ...merged, ...next }), {});

  return (
    <StyledComponent
      tag={tag}
      className={cx(classStyles, className)}
      style={{ ...styleObject, ...style }}
      {...rest}
    />
  );
};
