import React, { useMemo, useState } from "react";
import { useTheme } from "../../03-hooks";

import { Icon } from "./Icon";
import { Input, Layout, Tooltip } from "../../01-atoms";
import { Option, Select } from "../../02-blocks";
import { IconName, icons } from "./icons";

export const IconsExample = () => {
  const { theme } = useTheme();
  const [color, selectedColor] = useState<string | undefined>();
  const [search, setSearch] = useState<string | undefined>();

  const iconKeys = Object.keys(icons) as IconName[];

  const sortedIcons = iconKeys.sort((a, b) => a.localeCompare(b));

  const filteredIcons = useMemo(
    () =>
      sortedIcons.filter(name => {
        if (!search || search === "") return true;
        return name.toLowerCase().includes(search.toLowerCase());
      }),
    [sortedIcons, search]
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Layout.Group>
        <Select
          options={Object.keys(theme.palette).map(key => ({ key, title: key }))}
          onSelect={(option?: Option) => {
            selectedColor(option?.key);
          }}
          value={color}
          placeholder="Select color"
        />
        <Input value={search} onChange={setSearch} startAdornment={<Icon name="search" />} />
      </Layout.Group>

      <div
        style={{
          marginTop: theme.spacing.large,
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        {filteredIcons.map(name => (
          <Tooltip tooltip={name}>
            <div
              style={{
                padding: theme.spacing.xLarge,
                width: 16,
                height: 16,
                border: "1px solid #ECECEC"
              }}
            >
              <Icon
                //@ts-ignore
                color={color ? theme.palette[color].default : undefined}
                name={name}
              />
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
