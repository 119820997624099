import React from "react";
import { TopBar } from "./TopBar";
import { Button } from "../../01-atoms";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { useToast } from "../../03-hooks";
import { Icon } from "v2/00-assets";

export const TopBarExamples = () => {
  const toast = useToast();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem", width: "960px" }}>
      <ComponentSection title="Simple" description="Simple bar without any actions">
        <TopBar title="Journeys" />
      </ComponentSection>

      <ComponentSection
        title="Navigation"
        description="Top bar can have either back or menu navigation on the left"
      >
        <TopBar title="Journeys" navigationAction="back" onNavigationAction={() => {}} />
        <TopBar title="Journeys" navigationAction="menu" onNavigationAction={() => {}} />
      </ComponentSection>

      <ComponentSection title="Actions" description="Top bar can have actions on the right">
        <TopBar
          title="Journeys"
          actions={
            <Button variant="outlined" size="small">
              Settings
            </Button>
          }
        />
        <TopBar title="Journeys" actions={<Button variant="outlined">Settings</Button>} />
        <TopBar
          title="Journeys"
          actions={
            <Button variant="outlined" size="large">
              Settings
            </Button>
          }
        />
      </ComponentSection>
      <ComponentSection title="With breadcrumb" description="Top bar can use breadcrumb">
        <TopBar
          navigationAction="back"
          onNavigationAction={() => {}}
          paths={[
            {
              key: "employees",
              title: "Employees",
              onClick: () => toast.info("Employees clicked")
            },
            {
              key: "employee",
              title: "John Doe",
              onClick: () => toast.info("John Doe clicked"),
              startAdornment: <Icon name="folder" />
            },
            {
              key: "journey",
              title: "Onboarding",
              onClick: () => toast.info("Onboarding clicked")
            }
          ]}
        />
      </ComponentSection>
    </div>
  );
};
