import { ReactComponent as PersonSyncIcon } from "./v2/personSync.svg";
import { ReactComponent as TriggerCrossIcon } from "./v2/triggerCross.svg";
import { ReactComponent as PreviewIcon } from "./v2/preview.svg";
import { ReactComponent as AddFolderIcon } from "./v2/addFolder.svg";
import { ReactComponent as ArchiveIcon } from "./v2/archive.svg";
import { ReactComponent as CalendarAddIcon } from "./v2/calendarAdd.svg";
import { ReactComponent as ReconnectIcon } from "./v2/reconnect.svg";
import { ReactComponent as GoogleCalendarIcon } from "./v2/googleCalendar.svg";
import { ReactComponent as OutlookIcon } from "./v2/outlook.svg";
import { ReactComponent as MoveToFolderIcon } from "./v2/moveToFolder.svg";
import { ReactComponent as MoveToIcon } from "./v2/moveTo.svg";
import { ReactComponent as FolderIcon } from "./v2/folder.svg";
import { ReactComponent as DeleteFolderIcon } from "./v2/folderDelete.svg";
import { ReactComponent as CalendarTargetIcon } from "./v2/calendarTarget.svg";
import { ReactComponent as BellIcon } from "./v2/bell.svg";
import { ReactComponent as AddRectangleIcon } from "./v2/addRectangle.svg";
import { ReactComponent as ArrowsLeftRightIcon } from "./v2/arrowsLeftRight.svg";
import { ReactComponent as ArrowLeftIcon } from "./v2/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "./v2/arrowRight.svg";
import { ReactComponent as AtTheRateIcon } from "./v2/atTheRate.svg";
import { ReactComponent as AttributeIcon } from "./v2/attribute.svg";
import { ReactComponent as BookIcon } from "./v2/book.svg";
import { ReactComponent as BulbIcon } from "./v2/bulb.svg";
import { ReactComponent as CalendarCheckIcon } from "./v2/calendarCheck.svg";
import { ReactComponent as CalendarCrossIcon } from "./v2/calendarCross.svg";
import { ReactComponent as CalendarIcon } from "./v2/calendar.svg";
import { ReactComponent as CaretDownIcon } from "./v2/caretDown.svg";
import { ReactComponent as CaretLeftIcon } from "./v2/caretLeft.svg";
import { ReactComponent as CaretRightIcon } from "./v2/caretRight.svg";
import { ReactComponent as CaretUpIcon } from "./v2/caretUp.svg";
import { ReactComponent as CaretUpDownIcon } from "./v2/caretUpDown.svg";
import { ReactComponent as CheckCircleIcon } from "./v2/checkCircle.svg";
import { ReactComponent as CheckIcon } from "./v2/check.svg";
import { ReactComponent as CheckRectangleIcon } from "./v2/checkRectangle.svg";
import { ReactComponent as ChevronDownIcon } from "./v2/chevronDown.svg";
import { ReactComponent as ChevronLeftIcon } from "./v2/chevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "./v2/chevronRight.svg";
import { ReactComponent as ChevronUpIcon } from "./v2/chevronUp.svg";
import { ReactComponent as CcIcon } from "./v2/cc.svg";
import { ReactComponent as CommentIcon } from "./v2/comment.svg";
import { ReactComponent as ConditionIcon } from "./v2/condition.svg";
import { ReactComponent as ConfigureIcon } from "./v2/configure.svg";
import { ReactComponent as CrossCircleIcon } from "./v2/crossCircle.svg";
import { ReactComponent as CrossLargeIcon } from "./v2/crossLarge.svg";
import { ReactComponent as CrossSmallIcon } from "./v2/crossSmall.svg";
import { ReactComponent as DescriptionIcon } from "./v2/description.svg";
import { ReactComponent as DoorIcon } from "./v2/door.svg";
import { ReactComponent as EmailCrossIcon } from "./v2/emailCross.svg";
import { ReactComponent as EmailIcon } from "./v2/email.svg";
import { ReactComponent as ExclamationCircleIcon } from "./v2/exclamationCircle.svg";
import { ReactComponent as FileIcon } from "./v2/file.svg";
import { ReactComponent as FilesIcon } from "./v2/files.svg";
import { ReactComponent as GlowIcon } from "./v2/glow.svg";
import { ReactComponent as GoogleIcon } from "./v2/google.svg";
import { ReactComponent as HashtagIcon } from "./v2/hashtag.svg";
import { ReactComponent as HomeIcon } from "./v2/home.svg";
import { ReactComponent as HourglassIcon } from "./v2/hourglass.svg";
import { ReactComponent as InfoFilledIcon } from "./v2/infoFilled.svg";
import { ReactComponent as InfoIcon } from "./v2/info.svg";
import { ReactComponent as KeyIcon } from "./v2/key.svg";
import { ReactComponent as LabelIcon } from "./v2/label.svg";
import { ReactComponent as LinkIcon } from "./v2/link.svg";
import { ReactComponent as LockIcon } from "./v2/lock.svg";
import { ReactComponent as LockOpenIcon } from "./v2/lockOpen.svg";
import { ReactComponent as LogOutIcon } from "./v2/logout.svg";
import { ReactComponent as MapIcon } from "./v2/map.svg";
import { ReactComponent as MinusSmallIcon } from "./v2/minusSmall.svg";
import { ReactComponent as MoreIcon } from "./v2/more.svg";
import { ReactComponent as NotificationIcon } from "./v2/notification.svg";
import { ReactComponent as PaperclipIcon } from "./v2/paperclip.svg";
import { ReactComponent as PencilIcon } from "./v2/pencil.svg";
import { ReactComponent as PersonAddIcon } from "./v2/personAdd.svg";
import { ReactComponent as PersonCheckIcon } from "./v2/personCheck.svg";
import { ReactComponent as PersonCircleIcon } from "./v2/personCircle.svg";
import { ReactComponent as PersonCrossIcon } from "./v2/personCross.svg";
import { ReactComponent as PersonIcon } from "./v2/person.svg";
import { ReactComponent as PersonsIcon } from "./v2/persons.svg";
import { ReactComponent as PhoneMobileIcon } from "./v2/phoneMobile.svg";
import { ReactComponent as PlayCircleIcon } from "./v2/playCircle.svg";
import { ReactComponent as PlusIcon } from "./v2/plus.svg";
import { ReactComponent as PlusSmallIcon } from "./v2/plusSmall.svg";
import { ReactComponent as ProgressIcon } from "./v2/progress.svg";
import { ReactComponent as QuestionMarkIcon } from "./v2/questionmark.svg";
import { ReactComponent as ReplyToIcon } from "./v2/replyto.svg";
import { ReactComponent as ReturnIcon } from "./v2/return.svg";
import { ReactComponent as RobotIcon } from "./v2/robot.svg";
import { ReactComponent as SearchCircleIcon } from "./v2/searchCircle.svg";
import { ReactComponent as SearchIcon } from "./v2/search.svg";
import { ReactComponent as SettingsIcon } from "./v2/settings.svg";
import { ReactComponent as SidebarCloseIcon } from "./v2/sidebarClose.svg";
import { ReactComponent as SlackIcon } from "./v2/slack.svg";
import { ReactComponent as SmileIcon } from "./v2/smile.svg";
import { ReactComponent as StarIcon } from "./v2/star.svg";
import { ReactComponent as StepTypeIcon } from "./v2/stepType.svg";
import { ReactComponent as TargetAfterIcon } from "./v2/targetAfter.svg";
import { ReactComponent as TargetBeforeIcon } from "./v2/targetBefore.svg";
import { ReactComponent as TimeCheckIcon } from "./v2/timeCheck.svg";
import { ReactComponent as TimeCrossIcon } from "./v2/timeCross.svg";
import { ReactComponent as TimeIcon } from "./v2/time.svg";
import { ReactComponent as TrashIcon } from "./v2/trash.svg";
import { ReactComponent as TriggerFilledIcon } from "./v2/triggerFilled.svg";
import { ReactComponent as TriggerIcon } from "./v2/trigger.svg";
import { ReactComponent as UrgencyHighIcon } from "./v2/urgencyHigh.svg";
import { ReactComponent as UrgencyLowIcon } from "./v2/urgencyLow.svg";
import { ReactComponent as UrgencyMidIcon } from "./v2/urgencyMid.svg";
import { ReactComponent as WarningIcon } from "./v2/warning.svg";
import { ReactComponent as WorkflowsIcon } from "./v2/workflows.svg";
import { ReactComponent as ZapierIcon } from "./v2/zapier.svg";
import { ReactComponent as GlobeIcon } from "./v2/globe.svg";
import { ReactComponent as UploadIcon } from "./v2/upload.svg";
import { ReactComponent as CalendarForwardIcon } from "./v2/calendarForward.svg";
import { ReactComponent as CalendarBackwardIcon } from "./v2/calendarBackward.svg";
import { ReactComponent as CycleAddIcon } from "./v2/cycleAdd.svg";
import { ReactComponent as CycleCompletedIcon } from "./v2/cycleCompleted.svg";
import { ReactComponent as CycleIcon } from "./v2/cycle.svg";
import { ReactComponent as CycleUpcomingIcon } from "./v2/cycleUpcoming.svg";
import { ReactComponent as CycleCanceledIcon } from "./v2/cycleCanceled.svg";
import { ReactComponent as RecurringIcon } from "./v2/recurring.svg";
import { ReactComponent as TemplatesIcon } from "./v2/templates.svg";
import { ReactComponent as ChartIcon } from "./v2/chart.svg";
import { ReactComponent as SparkIcon } from "./v2/spark.svg";
import { ReactComponent as SenderIcon } from "./v2/sender.svg";
import { ReactComponent as ReceiverIcon } from "./v2/receiver.svg";
import { ReactComponent as FormIcon } from "./v2/form.svg";
import { ReactComponent as DuplicateIcon } from "./v2/duplicate.svg";
import { ReactComponent as FileCrossIcon } from "./v2/fileCross.svg";
import { ReactComponent as MicrosoftTeamsIcon } from "./v2/microsoftTeams.svg";
import { ReactComponent as GmailIcon } from "./v2/gmail.svg";
import { ReactComponent as CalendarSharedIcon } from "./v2/calendarShared.svg";
import { ReactComponent as WorkflowEditorIcon } from "./v2/workflowEditor.svg";
import { ReactComponent as PauseIcon } from "./v2/pause.svg";
import { ReactComponent as OfficeIcon } from "./v2/office.svg";
import { ReactComponent as HistoryIcon } from "./v2/history.svg";
import { ReactComponent as EventsIcon } from "./v2/events.svg";
import { ReactComponent as FilterIcon } from "./v2/filter.svg";
import { ReactComponent as RoutinesIcon } from "./v2/routines.svg";
import { ReactComponent as LocationIcon } from "./v2/location.svg";
import { ReactComponent as CalendarAttributeIcon } from "./v2/calendarAttribute.svg";
import { ReactComponent as CollapseDrawerIcon } from "./v2/collapseDrawer.svg";
import { ReactComponent as CalendarRecurringIcon } from "./v2/calendarRecurring.svg";
import { ReactComponent as GrabIcon } from "./v2/grab.svg";
import { ReactComponent as AttributeFilledIcon } from "./v2/attributeFilled.svg";
import { ReactComponent as AttributeGroupIcon } from "./v2/attributeGroup.svg";
import { ReactComponent as GridIcon } from "./v2/grid.svg";
import { ReactComponent as ListIcon } from "./v2/list.svg";
import { ReactComponent as ChevronUpDownIcon } from "./v2/chevronUpDown.svg";

export type IconName =
  | "chevronUpDown"
  | "list"
  | "grid"
  | "attributeGroup"
  | "attributeFilled"
  | "grab"
  | "calendarRecurring"
  | "collapseDrawer"
  | "calendarAttribute"
  | "location"
  | "featureRoutines"
  | "filter"
  | "events"
  | "history"
  | "office"
  | "pause"
  | "personSync"
  | "triggerCross"
  | "workflowEditor"
  | "preview"
  | "addFolder"
  | "calendarShared"
  | "gmail"
  | "microsoftTeams"
  | "fileCross"
  | "duplicate"
  | "form"
  | "receiver"
  | "sender"
  | "spark"
  | "chart"
  | "templates"
  | "recurring"
  | "cycleCanceled"
  | "cycleUpcoming"
  | "cycle"
  | "cycleCompleted"
  | "cycleAdd"
  | "calendarForward"
  | "calendarBackward"
  | "upload"
  | "archive"
  | "calendarAdd"
  | "reconnect"
  | "googleCalendar"
  | "globe"
  | "outlook"
  | "folderMoveTo"
  | "moveTo"
  | "folder"
  | "folderDelete"
  | "calendarTarget"
  | "bell"
  | "addRectangle"
  | "arrowsLeftRight"
  | "arrowLeft"
  | "arrowRight"
  | "atTheRate"
  | "attribute"
  | "book"
  | "bulb"
  | "calendar"
  | "calendarCheck"
  | "calendarCross"
  | "caretDown"
  | "caretLeft"
  | "caretRight"
  | "caretUp"
  | "caretUpDown"
  | "cc"
  | "check"
  | "checkCircle"
  | "checkRectangle"
  | "chevronDown"
  | "chevronLeft"
  | "chevronRight"
  | "chevronUp"
  | "comment"
  | "condition"
  | "configure"
  | "crossCircle"
  | "crossLarge"
  | "crossSmall"
  | "description"
  | "door"
  | "email"
  | "emailCross"
  | "exclamationCircle"
  | "file"
  | "files"
  | "glow"
  | "google"
  | "hashtag"
  | "home"
  | "hourglass"
  | "info"
  | "infoFilled"
  | "key"
  | "label"
  | "link"
  | "lock"
  | "lockOpen"
  | "logOut"
  | "map"
  | "minusSmall"
  | "more"
  | "notification"
  | "paperclip"
  | "pencil"
  | "person"
  | "personAdd"
  | "personCheck"
  | "personCircle"
  | "personCross"
  | "persons"
  | "phoneMobile"
  | "playCircle"
  | "plus"
  | "plusSmall"
  | "progress"
  | "questionMark"
  | "replyTo"
  | "return"
  | "robot"
  | "search"
  | "searchCircle"
  | "settings"
  | "sidebarClose"
  | "slack"
  | "smile"
  | "star"
  | "stepType"
  | "targetAfter"
  | "targetBefore"
  | "time"
  | "timeCheck"
  | "timeCross"
  | "trash"
  | "trigger"
  | "triggerFilled"
  | "urgencyHigh"
  | "urgencyLow"
  | "urgencyMid"
  | "warning"
  | "workflows"
  | "zapier";

export const icons = {
  chevronUpDown: ChevronUpDownIcon,
  grid: GridIcon,
  list: ListIcon,
  attributeGroup: AttributeGroupIcon,
  attributeFilled: AttributeFilledIcon,
  grab: GrabIcon,
  calendarRecurring: CalendarRecurringIcon,
  collapseDrawer: CollapseDrawerIcon,
  calendarAttribute: CalendarAttributeIcon,
  location: LocationIcon,
  featureRoutines: RoutinesIcon,
  filter: FilterIcon,
  events: EventsIcon,
  history: HistoryIcon,
  office: OfficeIcon,
  pause: PauseIcon,
  personSync: PersonSyncIcon,
  triggerCross: TriggerCrossIcon,
  workflowEditor: WorkflowEditorIcon,
  preview: PreviewIcon,
  addFolder: AddFolderIcon,
  calendarShared: CalendarSharedIcon,
  gmail: GmailIcon,
  microsoftTeams: MicrosoftTeamsIcon,
  fileCross: FileCrossIcon,
  duplicate: DuplicateIcon,
  form: FormIcon,
  receiver: ReceiverIcon,
  sender: SenderIcon,
  spark: SparkIcon,
  chart: ChartIcon,
  templates: TemplatesIcon,
  recurring: RecurringIcon,
  cycleCanceled: CycleCanceledIcon,
  cycleUpcoming: CycleUpcomingIcon,
  cycle: CycleIcon,
  cycleCompleted: CycleCompletedIcon,
  cycleAdd: CycleAddIcon,
  calendarForward: CalendarForwardIcon,
  calendarBackward: CalendarBackwardIcon,
  upload: UploadIcon,
  archive: ArchiveIcon,
  calendarAdd: CalendarAddIcon,
  reconnect: ReconnectIcon,
  googleCalendar: GoogleCalendarIcon,
  globe: GlobeIcon,
  outlook: OutlookIcon,
  folderMoveTo: MoveToFolderIcon,
  moveTo: MoveToIcon,
  folder: FolderIcon,
  calendarTarget: CalendarTargetIcon,
  bell: BellIcon,
  addRectangle: AddRectangleIcon,
  arrowsLeftRight: ArrowsLeftRightIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  atTheRate: AtTheRateIcon,
  attribute: AttributeIcon,
  book: BookIcon,
  bulb: BulbIcon,
  calendar: CalendarIcon,
  calendarCheck: CalendarCheckIcon,
  calendarCross: CalendarCrossIcon,
  caretDown: CaretDownIcon,
  caretLeft: CaretLeftIcon,
  caretRight: CaretRightIcon,
  caretUp: CaretUpIcon,
  caretUpDown: CaretUpDownIcon,
  cc: CcIcon,
  check: CheckIcon,
  checkCircle: CheckCircleIcon,
  checkRectangle: CheckRectangleIcon,
  chevronDown: ChevronDownIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  chevronUp: ChevronUpIcon,
  comment: CommentIcon,
  condition: ConditionIcon,
  configure: ConfigureIcon,
  crossCircle: CrossCircleIcon,
  crossLarge: CrossLargeIcon,
  crossSmall: CrossSmallIcon,
  description: DescriptionIcon,
  door: DoorIcon,
  email: EmailIcon,
  emailCross: EmailCrossIcon,
  exclamationCircle: ExclamationCircleIcon,
  file: FileIcon,
  files: FilesIcon,
  folderDelete: DeleteFolderIcon,
  glow: GlowIcon,
  google: GoogleIcon,
  hashtag: HashtagIcon,
  home: HomeIcon,
  hourglass: HourglassIcon,
  info: InfoIcon,
  infoFilled: InfoFilledIcon,
  key: KeyIcon,
  label: LabelIcon,
  link: LinkIcon,
  lock: LockIcon,
  lockOpen: LockOpenIcon,
  logOut: LogOutIcon,
  map: MapIcon,
  minusSmall: MinusSmallIcon,
  more: MoreIcon,
  notification: NotificationIcon,
  paperclip: PaperclipIcon,
  pencil: PencilIcon,
  person: PersonIcon,
  personAdd: PersonAddIcon,
  personCheck: PersonCheckIcon,
  personCircle: PersonCircleIcon,
  personCross: PersonCrossIcon,
  persons: PersonsIcon,
  phoneMobile: PhoneMobileIcon,
  playCircle: PlayCircleIcon,
  plus: PlusIcon,
  plusSmall: PlusSmallIcon,
  progress: ProgressIcon,
  questionMark: QuestionMarkIcon,
  replyTo: ReplyToIcon,
  return: ReturnIcon,
  robot: RobotIcon,
  search: SearchIcon,
  searchCircle: SearchCircleIcon,
  settings: SettingsIcon,
  sidebarClose: SidebarCloseIcon,
  slack: SlackIcon,
  smile: SmileIcon,
  star: StarIcon,
  stepType: StepTypeIcon,
  targetAfter: TargetAfterIcon,
  targetBefore: TargetBeforeIcon,
  time: TimeIcon,
  timeCheck: TimeCheckIcon,
  timeCross: TimeCrossIcon,
  trash: TrashIcon,
  trigger: TriggerIcon,
  triggerFilled: TriggerFilledIcon,
  urgencyHigh: UrgencyHighIcon,
  urgencyLow: UrgencyLowIcon,
  urgencyMid: UrgencyMidIcon,
  warning: WarningIcon,
  workflows: WorkflowsIcon,
  zapier: ZapierIcon
};
