import React from "react";
import { ComponentSection } from "../../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Title } from "./Title";
import { useTheme } from "../../../03-hooks";

export const TitleExamples = () => {
  const { theme } = useTheme();

  return (
    <div>
      <ComponentSection
        title="Basic title"
        description="Probably the most widely used text component"
      >
        <Title>Lorem ipsum</Title>
        <Title color={theme.palette.primary.default}>Lorem ipsum</Title>
        <Title color={theme.palette.danger.default}>Lorem ipsum</Title>
      </ComponentSection>
      <ComponentSection title="Variant" description="Typography can have different styles">
        <Title>This is regular variant title</Title>
        <Title variant="bold">This is bold title</Title>
        <Title variant="uppercase">This is uppercase title</Title>
      </ComponentSection>
      <ComponentSection title="Size" description="Typography can be of different size">
        <Title>This is regular sized title</Title>
        <Title small as="div">
          This is small title
        </Title>
      </ComponentSection>
    </div>
  );
};
