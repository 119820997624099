import "moment/locale/fi";

import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { InputProps, DatePicker, Input, Icon } from "../../";
import { DatePickerProps } from "../../01-atoms";

const { language } = window.navigator;
moment.locale(language ? language.split("-")[0] : "en");

export interface DateInputProps
  extends Omit<InputProps, "type" | "value" | "onChange">,
    Pick<DatePickerProps, "disablePast" | "firstDayOfWeek"> {
  value?: string;
  onChange: (value?: string) => void;
  format?: string;
  displayFormat?: string;
  invalidDateMessage?: string;
  clearable?: boolean;
  attachToRef?: boolean;
}

export const DateInput = ({
  value,
  onChange,
  format = "YYYY-MM-DD",
  displayFormat = format,
  invalidDateMessage = "Invalid date",
  disablePast,
  firstDayOfWeek,
  attachToRef = true,
  clearable = true,
  ...props
}: DateInputProps) => {
  const [refElement, setRefElement] = useState<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isPickerOpen, setPickerOpen] = useState(false);

  const updateInputValue = useCallback(() => {
    if (value) {
      const valueMoment = moment(value, format);
      if (valueMoment.isValid()) setInputValue(moment(value, format).format(displayFormat));
      else setInputValue(invalidDateMessage);
    } else setInputValue("");
  }, [value]);

  useEffect(() => {
    updateInputValue();
  }, [updateInputValue]);

  const handleInputChange = (changedValue: string) => {
    setPickerOpen(false);
    setInputValue(changedValue);
  };

  const onInputBlur = () => {
    if (isPickerOpen) return;
    if (inputValue === "") {
      if (clearable) onChange(undefined);
      else updateInputValue();
      return;
    }

    const parsed = moment(inputValue, displayFormat);

    if (parsed.isValid()) {
      onChange(parsed.format(format));
      setInputValue(parsed.format(displayFormat));
    } else updateInputValue();
  };

  const onPickerDateChanged = (changedValue?: Date) => {
    if (!clearable && !changedValue) return;
    onChange(changedValue ? moment(changedValue).format(format) : undefined);
  };

  const valueMoment = moment(value, format);

  return (
    <div ref={setRefElement}>
      <Input
        value={inputValue}
        type="text"
        endAdornment={<Icon name="calendar" onClick={() => setPickerOpen(true)} />}
        onChange={handleInputChange}
        onBlur={onInputBlur}
        onClick={() => setPickerOpen(true)}
        {...props}
      />

      <DatePicker
        referenceElement={refElement}
        onClose={() => {
          setPickerOpen(false);
        }}
        disablePast={disablePast}
        firstDayOfWeek={firstDayOfWeek}
        onDateChange={onPickerDateChanged}
        date={valueMoment.isValid() ? valueMoment.toDate() : undefined}
        open={isPickerOpen}
        attachToRef={attachToRef}
      />
    </div>
  );
};
