import React, { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { animated, config, useSpring, useTransition } from "@react-spring/web";
import useMeasure from "react-use-measure";
import isEmpty from "lodash.isempty";

import {
  AlphaTag,
  BetaTag,
  Caps2,
  Card,
  Layout,
  NewTag,
  Popover,
  Title,
  Tooltip
} from "v2/01-atoms";
import { Icon } from "v2/00-assets";
import { useTheme } from "v2/03-hooks";

import { useAppMenuInternalContext } from "../../internalContext";
import { AppMenuItem as AppMenuItemType } from "../..";

export type AppMenuMainItem = {
  title: string;
  icon: ReactNode;
  path?: string;
  divider?: boolean;
  isNew?: boolean;
  isBeta?: boolean;
  isAlpha?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  category?: string;
  closable?: boolean;
};

export type AppMenuSubItem = Omit<AppMenuMainItem, "icon" | "divider"> & {};

export interface IAppMenuItemProps extends Omit<AppMenuItemType, "icon"> {
  icon?: ReactNode;
}

const AppMenuItemWrapper = styled.div`
  overflow: hidden;
  user-select: none;
`;

const StyledAppMenuItem = styled.div<{
  $highlight?: boolean;
  $subItemsOpen?: boolean;
  $collapsed: boolean;
}>`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: var(--spacing-medium);
  align-items: center;
  width: 100%;
  padding: 0 var(--spacing-large);
  box-sizing: border-box;
  position: relative;
  height: 36px;

  ::after {
    background-color: var(--palette-surface-default);
    position: absolute;
    top: 0;
    left: calc(var(--spacing-large) - 0.5rem);
    right: calc(var(--spacing-large));
    border-radius: var(--rounding-medium);
    bottom: 0;
    z-index: -1;

    ${({ $collapsed }) =>
      $collapsed &&
      css`
        width: 36px;
        left: 1.125rem;
        height: 36px;
      `}
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      ::after {
        content: "";
      }
    `}
`;

const StyledTooltip = styled(Tooltip)`
  left: 2.5rem;
`;

const AnimatedIconWrapper = styled(animated.span)`
  min-width: 1.5rem;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OneLineTitle = styled(Title)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
`;

const Divider = styled(Layout.Divider)`
  width: calc(100% - 2rem);
  margin: var(--spacing-medium) auto;
`;

const AnimatedCaret = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubItems = styled.div`
  padding-left: 1.75rem;
`;

const SubMenuItem = styled.div<{ $highlight?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-small);
  margin-right: var(--spacing-large);
  margin-left: var(--spacing-large);
  border-radius: var(--rounding-medium);
  height: 2rem;
  cursor: pointer;
  transition: background-color 160ms;

  :hover {
    h5 {
      color: var(--palette-foreground-default);
    }
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: var(--palette-surface-default);
      h5 {
        color: var(--palette-foreground-default);
      }
    `}
`;

const PopoverSubMenuItem = styled(SubMenuItem)`
  margin: 0;
  padding: 0 var(--spacing-medium);

  > :first-child {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: var(--palette-surface-default);
    `}
`;

const Category = styled(Caps2)`
  font-size: 11px;
  padding: 0 var(--spacing-large);
  color: var(--palette-foreground-subdued);
  opacity: 0.7;
  margin-bottom: var(--spacing-medium);
`;

function matchesPattern(pattern: string, str: string): boolean {
  // Convert the pattern string into a regex pattern
  // Here, we replace '*' with '.*' to match any sequence of characters
  const regexPattern = new RegExp(`^${pattern.split("*").join(".*")}$`);

  // Directly check if the pattern is same as the string or it matches the regex pattern
  return pattern === str || str.startsWith(pattern) || regexPattern.test(str);
}

const isCurrentPath = (currentPath: string, path: string) => {
  return currentPath.startsWith(path);
};

export const AppMenuItem: FC<IAppMenuItemProps> = ({
  icon,
  title,
  path,
  divider,
  subItems,
  category,
  isNew,
  isBeta,
  isAlpha,
  onClick,
  closable,
  ...rest
}) => {
  const { theme } = useTheme();
  const { collapsed } = useAppMenuInternalContext();
  const [subItemsOpen, setSubItemsOpen] = useState(false);
  const [subItemsRef, { height: subItemsHeight }] = useMeasure();

  const currentPath = useMemo(() => window.location?.pathname + window.location?.hash, [
    window.location.pathname,
    window.location.hash
  ]);

  const subMenuItemIsNew = subItems?.find(subItem => subItem.isNew);
  const subMenuItemIsBeta = subItems?.find(subItem => subItem.isBeta);
  const subMenuItemIsAlpha = subItems?.find(subItem => subItem.isAlpha);

  const matchingSubItemPath =
    subItems && subItems.find(subItem => matchesPattern(subItem.path!, currentPath))?.path;

  const parentHighlight = isCurrentPath(currentPath, path!);

  const ref = useRef<HTMLDivElement | null>(null);

  const spring = useSpring({
    opacity: collapsed ? 0 : 1,
    transform: collapsed ? "translateX(0.5rem)" : "translateX(0rem)",
    config: { ...config.stiff, clamp: true }
  });

  const iconSpring = useSpring({
    paddingLeft: collapsed ? "0.5rem" : "0rem",
    config: { ...config.stiff, clamp: true }
  });

  const categorySpring = useSpring({
    paddingLeft: collapsed ? "1rem" : "0rem",
    config: { ...config.stiff, clamp: true }
  });

  const subItemsSpring = useSpring({
    height: subItemsOpen ? subItemsHeight : 0,
    config: {
      tension: 180
    }
  });

  const caretSpring = useSpring({
    transform: collapsed ? "translateX(0px)" : subItemsOpen ? "rotate(180deg)" : "rotate(0deg)",
    transformOrigin: "center",
    right: collapsed ? "0.5rem" : "0rem",
    immediate: collapsed,
    config: config.stiff
  });

  const categoryTransitions = useTransition(collapsed, {
    from: { opacity: 0, transform: "translateX(0.5rem)" },
    enter: { opacity: 1, transform: "translateX(0rem)" },
    leave: { opacity: 0, transform: "translare(0.5rem)", position: "absolute" },
    config: { ...config.stiff, clamp: true }
  });

  useEffect(() => {
    setSubItemsOpen(false);
  }, [collapsed]);

  const renderSubItems = () => {
    if (!subItems || isEmpty(subItems)) return null;
    if (collapsed) return null;

    const subItemElems = (
      <SubItems ref={subItemsRef}>
        {subItems.map(subItem => {
          const highlight = subItem.path === matchingSubItemPath;
          return (
            <SubMenuItem
              key={`app-menu-sub-item-${subItem.title}`}
              onClick={subItem.onClick}
              $highlight={highlight}
            >
              <OneLineTitle>
                <span>{subItem.title}</span>
                {subItem.isNew && <NewTag />}
                {subItem.isBeta && <BetaTag />}
                {subItem.isAlpha && <AlphaTag />}
              </OneLineTitle>
            </SubMenuItem>
          );
        })}
      </SubItems>
    );

    if (closable) {
      return <animated.div style={subItemsSpring}>{subItemElems}</animated.div>;
    }
    return subItemElems;
  };

  return (
    <>
      <AppMenuItemWrapper>
        {category && (
          <div style={{ overflow: "hidden", position: "relative" }}>
            {categoryTransitions((style, tCollapsed) => (
              <animated.div
                style={{
                  ...style,
                  paddingLeft: tCollapsed ? categorySpring.paddingLeft : undefined
                }}
              >
                <Category size="semibold">{tCollapsed ? category[0] : category}</Category>
              </animated.div>
            ))}
          </div>
        )}

        <StyledAppMenuItem
          {...rest}
          ref={ref}
          onClick={(e) => {
            onClick && onClick(e);
            !isEmpty(subItems) && setSubItemsOpen(!closable ? true : !subItemsOpen);
          }}
          $highlight={parentHighlight}
          $subItemsOpen={subItemsOpen && collapsed}
          $collapsed={collapsed}
        >
          <StyledTooltip tooltip={collapsed && isEmpty(subItems) ? title : ""} placement="right">
            <AnimatedIconWrapper className="icon" style={iconSpring}>
              {icon}
            </AnimatedIconWrapper>
          </StyledTooltip>

          <animated.div style={spring}>
            <OneLineTitle variant="bold">
              <span>{title}</span>
              {isNew && <NewTag />}
              {isBeta && <BetaTag />}
              {isAlpha && <AlphaTag />}

              <Layout.Group gap="xSmall">
                {subMenuItemIsNew && !subItemsOpen && <NewTag minimized />}
                {subMenuItemIsBeta && !subItemsOpen && <BetaTag minimized />}
                {subMenuItemIsAlpha && !subItemsOpen && <AlphaTag minimized />}
              </Layout.Group>
            </OneLineTitle>
          </animated.div>

          {!isEmpty(subItems) && (closable || collapsed) && (
            <AnimatedCaret style={{ ...caretSpring, position: collapsed ? "absolute" : "static" }}>
              <Icon
                name={collapsed ? "caretRight" : "caretDown"}
                color={theme.palette.foreground.default}
              />
            </AnimatedCaret>
          )}
        </StyledAppMenuItem>
        {renderSubItems()}
      </AppMenuItemWrapper>
      {divider && <Divider />}
      {!isEmpty(subItems) && subItems && collapsed && (
        <Popover
          referenceElement={ref.current}
          open={subItemsOpen}
          onClose={() => setSubItemsOpen(false)}
          placement="right-start"
        >
          <Card elevated>
            {subItems.map(subItem => {
              const highlight = subItem.path === matchingSubItemPath;
              return (
                <PopoverSubMenuItem
                  key={`app-menu-popover-sub-item-${subItem.title}`}
                  onClick={(e) => {
                    subItem.onClick && subItem.onClick(e);
                  }}
                  $highlight={highlight}
                >
                  <Title variant={highlight ? "bold" : "regular"}>
                    <span>{subItem.title}</span>

                    {subItem.isNew && <NewTag />}
                    {subItem.isBeta && <BetaTag />}
                    {subItem.isAlpha && <AlphaTag />}
                  </Title>
                </PopoverSubMenuItem>
              );
            })}
          </Card>
        </Popover>
      )}
    </>
  );
};
