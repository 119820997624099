import React from "react";

import { Button, H4, Title } from "../../01-atoms";
import { useState } from "react";
import { Modal } from "./Modal";
import { useTheme } from "../../03-hooks";
import { TypographyExample } from "../../01-atoms/Typography/example";
import { Select } from "..";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { BaseModal, BaseModalProps } from "./BaseModal";
import { ColorsExamples } from "../../00-assets/Colors/examples";

export const ModalExamples = () => {
  const { theme } = useTheme();

  const [open, setOpen] = useState(false);
  const [openWithIcon, setOpenWithIcon] = useState(false);
  const [openWithoutIcon, setOpenWithoutIcon] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const [customChildrenOpen, setCustomChildrenOpen] = useState(false);

  const [baseModalOpen, setBaseModalOpen] = useState(false);
  const [baseModalProps, setBaseModalProps] = useState<Partial<BaseModalProps>>({});

  return (
    <div>
      <ComponentSection
        title="Basic modal"
        description="At minimum you can use bare-bone modal without any content"
      >
        <Button
          variant="outlined"
          onClick={() => {
            setBaseModalProps({
              closeButton: false,
              closeOnOutsideClick: true,
              onClose: () => setBaseModalOpen(false)
            });
            setBaseModalOpen(true);
          }}
        >
          Open simple modal without any tooling
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setBaseModalProps({
              closeOnOutsideClick: true,
              onClose: () => setBaseModalOpen(false)
            });
            setBaseModalOpen(true);
          }}
        >
          Open simple modal with only close button
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setBaseModalProps({
              closeOnOutsideClick: true,
              onClose: () => setBaseModalOpen(false),
              header: <H4>Title for the modal</H4>
            });
            setBaseModalOpen(true);
          }}
        >
          Open simple modal with header
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setBaseModalProps({
              closeOnOutsideClick: true,
              onClose: () => setBaseModalOpen(false),
              header: <H4>Title for the modal</H4>,
              footer: (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button>Close</Button>
                </div>
              )
            });
            setBaseModalOpen(true);
          }}
        >
          Open simple modal with footer
        </Button>
      </ComponentSection>
      <BaseModal open={baseModalOpen} style={{ height: "70vh" }} {...baseModalProps}>
        <ColorsExamples />
      </BaseModal>

      <div style={{ display: "grid", gap: "1rem" }}>
        <Button onClick={() => setOpen(true)}>Open simple modal</Button>
        <Button onClick={() => setOpenWithoutIcon(true)}>Open modal without icon</Button>
        <Button onClick={() => setOpenWithIcon(true)}>Open modal with icon</Button>
        <Button onClick={() => setCustomChildrenOpen(true)}>Open modal with custom children</Button>

        <Modal open={open} onCloseWithOutsideClick={() => setOpen(false)} mobile="centered">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: theme.spacing.medium
            }}
          >
            <Title>Close modal by clicking outside</Title>
            <Button variant="outlined" onClick={() => setSecondOpen(true)}>
              Open another
            </Button>
          </div>
        </Modal>
        <Modal
          open={secondOpen}
          onCloseWithOutsideClick={() => setSecondOpen(false)}
          mobile="centered"
        >
          <div>
            <Title>Close modal by clicking outside</Title>
            <Select
              onSelect={() => {}}
              options={[{ key: "1" }, { key: "2" }, { key: "3" }, { key: "4" }, { key: "5" }]}
            />
          </div>
        </Modal>

        <Modal
          open={openWithoutIcon}
          onClose={() => setOpenWithoutIcon(false)}
          title="Title"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed magna ac faucibus consectetur cras neque vitae egestas."
          secondaryButton={{ title: "Label" }}
          primaryButton={{ title: "Label" }}
          maxWidth="400px"
        />

        <Modal
          icon="info"
          open={openWithIcon}
          onClose={() => setOpenWithIcon(false)}
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          description="Lorem ipsum dolor sit amet."
          secondaryButton={{ title: "Label" }}
          primaryButton={{ title: "Label" }}
        />

        <Modal
          icon="info"
          open={customChildrenOpen}
          onClose={() => setCustomChildrenOpen(false)}
          title="Title"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed magna ac faucibus consectetur cras neque vitae egestas."
          secondaryButton={{ title: "Label" }}
          primaryButton={{ title: "Label" }}
        >
          <TypographyExample />
          <Select
            onSelect={() => {}}
            options={[{ key: "1" }, { key: "2" }, { key: "3" }, { key: "4" }, { key: "5" }]}
          />
        </Modal>
      </div>

      <Title style={{ marginTop: "2000px" }}>This is to make view scrollable</Title>
    </div>
  );
};
