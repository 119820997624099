import React from "react";
import moment from "moment";
import { Option } from "../OptionList";
import { Select, SelectProps } from "../Select";

export type Time = string;
export interface TimeRange {
  start: Time;
  end?: Time;
}

export const TimeFormat = "HH:mm";

export interface TimePickerProps extends Omit<SelectProps, "onSelect" | "value" | "options"> {
  time?: Time;
  timeWindow?: TimeRange;
  selectIntervalMinutes?: number;
  onSelect: (time: Time) => unknown;
}

export const toMoment = (time: Time) => moment(time, TimeFormat);

export const buildOptions = (window: TimeRange, intervalMinutes: number): Option[] => {
  const options: Option[] = [];

  const start = toMoment(window.start);
  const end = toMoment(window.end!);

  do {
    options.push({ key: start.format(TimeFormat), title: start.format(TimeFormat) });
    start.add(intervalMinutes, "minutes");
  } while (!start.isAfter(end));

  return options;
};

export const TimePicker = ({
  time,
  selectIntervalMinutes = 15,
  timeWindow = { start: "00:00", end: "23:59" },
  onSelect,
  style,
  element,
  ...props
}: TimePickerProps) => {
  return (
    <Select
      value={time}
      onSelect={option => onSelect(option.key)}
      options={buildOptions(timeWindow, selectIntervalMinutes)}
      markSelected={false}
      element={element}
      style={{ maxWidth: "105px", ...style }}
      elementStyle={{ width: element === "button" ? "100%" : undefined }}
      {...props}
    />
  );
};
