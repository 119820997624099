import React from "react";
import { Icon, useTheme, useToast } from "../..";
import { AlphaTag, BetaTag, NewTag, ProductTag, Tag } from ".";
import { ComponentSection } from "v2/09-routes/ComponentExamplesRoute/ComponentSection";

export const TagExamples = () => {
  const { theme } = useTheme();
  const toast = useToast();

  return (
    <div style={{ display: "grid", gap: "1rem" }}>
      <ComponentSection title="Color variants">
        <Tag>Surface (default)</Tag>
        <Tag colorVariant="primary">Primary</Tag>
        <Tag colorVariant="danger">Danger</Tag>
        <Tag colorVariant="success">Success</Tag>
        <Tag colorVariant="warning">Warning</Tag>
        <Tag colorVariant="outlined">Outlined</Tag>
      </ComponentSection>
      <ComponentSection title="Pointer events">
        <Tag onClick={() => toast.info("onClick")}>onClick</Tag>
        <Tag onRemove={() => toast.info("onRemove")}>onRemove</Tag>
      </ComponentSection>
      <ComponentSection title="start / end icon">
        <Tag startIcon="warning" colorVariant="danger">
          Error error!!
        </Tag>
        <Tag endIcon="smile" colorVariant="primary">
          Happy
        </Tag>
      </ComponentSection>
      <ComponentSection
        title="Start adornment"
        description="Tag can have a start adornment (ReactNode)"
      >
        <Tag startAdornment={<Icon name="checkCircle" color={theme.palette.success.default} />}>
          Label
        </Tag>
      </ComponentSection>
      <ComponentSection title="Product tag">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: "0.5rem",
            alignItems: "flex-start",
            justifyItems: "flex-start"
          }}
        >
          <NewTag minimized />
          <NewTag size="small" />
          <NewTag />
          <BetaTag minimized />
          <BetaTag size="small" />
          <BetaTag />
          <AlphaTag minimized />
          <AlphaTag size="small" />
          <AlphaTag />
        </div>
      </ComponentSection>
      <ComponentSection title="Filter tag" description="Usage: <Tag.Filter {...}  />">
        <Tag.Filter
          filter={{
            filterName: "Name",
            method: "is",
            value: ["Bond"]
          }}
          onRemove={() => alert("onRemove Filter")}
        />
      </ComponentSection>
    </div>
  );
};
