import React, { useState } from "react";
import { DateInput } from "./DateInput";
import { Button, Card, Popover, Title } from "../../";
import { Modal } from "../Modal";

export const DateInputExamples = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [popoverRef, setPopoverRef] = useState<HTMLButtonElement | null>(null);
  const [day, setDay] = useState<string | undefined>("2022-01-01");

  return (
    <div style={{ display: "grid", gap: "2rem" }}>
      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Default</Title>
        <DateInput value={day} onChange={value => setDay(value)} />
      </div>
      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Default with custom display format</Title>
        <DateInput displayFormat="DD.MM.YYYY" value={day} onChange={value => setDay(value)} />
      </div>
      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Disabled past</Title>
        <DateInput value={day} onChange={value => setDay(value)} disablePast />
      </div>
      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Sunday as first day of week</Title>
        <DateInput value={day} onChange={value => setDay(value)} firstDayOfWeek={0} />
      </div>

      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Custom invalid date message</Title>
        <DateInput
          onChange={setDay}
          value={"invalid day"}
          format="DD.MM.YYYY"
          invalidDateMessage="Date is incorrect 👆"
        />
      </div>

      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Blended mode</Title>
        <DateInput variant="blended" onChange={setDay} format="DD.MM.YYYY" />
      </div>

      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Readonly</Title>
        <DateInput readonly onChange={setDay} value={day} />
      </div>

      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Readonly & blended</Title>
        <DateInput readonly onChange={setDay} value={day} variant="blended" />
      </div>

      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Button onClick={e => setPopoverRef(e.currentTarget)}>Inside a popover</Button>
        <Popover
          referenceElement={popoverRef}
          onClose={() => setPopoverRef(null)}
          closeOnContentClick={false}
        >
          <Card>
            <DateInput onChange={setDay} value={day} variant="blended" />
          </Card>
        </Popover>
      </div>
      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Button onClick={e => setModalOpen(true)}>Inside a modal</Button>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onCloseWithOutsideClick={() => setModalOpen(false)}
        >
          <div style={{ overflow: "hidden", position: "relative" }}>
            <DateInput attachToRef={false} onChange={setDay} value={day} variant="blended" />
          </div>
        </Modal>
      </div>

      <div style={{ display: "grid", width: "fit-content", gap: "1rem" }}>
        <Title>Unclearable date input</Title>
        <DateInput onChange={setDay} value={day} clearable={false} />
      </div>
    </div>
  );
};
