import { useCallback, useEffect, useState } from "react";
import orderBy from "lodash.orderby";

export type SortDirection = "asc" | "desc";

export type SortFunction<T> = (data: T[], sortedBy: string, sortDir: SortDirection) => T[];

export const oppositeSort = (dir: SortDirection): SortDirection => (dir === "asc" ? "desc" : "asc");

export interface UseSortableOptions<T> {
  sortedBy?: string;
  sortDir?: SortDirection;
  initialData?: T[];
}

const defaultSortFunction = (data: any[], sortedBy: string, sortDir: SortDirection) => {
  return orderBy(data, sortedBy, sortDir);
};

export const useSortable = <T extends { [index: string]: any }>(
  options?: UseSortableOptions<T>,
  sort: SortFunction<T> = defaultSortFunction
) => {
  const [data, setRenderedData] = useState<T[]>(options?.initialData || []);
  const [sortedBy, setSortedBy] = useState(options?.sortedBy || "");
  const [sortDir, setSortDir] = useState<SortDirection>(options?.sortDir || "desc");

  const setSort = (sortedBy: string, sortDir: SortDirection) => {
    setSortedBy(sortedBy);
    setSortDir(sortDir);
  };

  const doSort = useCallback(() => {
    setRenderedData(previousData => sort(previousData, sortedBy, sortDir as SortDirection));
  }, [sortedBy, sortDir]);

  useEffect(() => {
    doSort();
  }, [sortedBy, sortDir]);

  const setData = (data: T[]) => {
    setRenderedData(data);
    doSort();
  };

  const toggleSortDir = () => setSortDir(oppositeSort(sortDir));

  return { data, setData, sortedBy, sortDir, setSort, setSortedBy, setSortDir, toggleSortDir };
};
