import React, { ChangeEvent, ComponentProps, ReactNode, useState } from "react";
import styled, { css } from "styled-components";

import { IconName, Icon } from "../../00-assets/Icons";
import { ErrorMessage, Title } from "../Typography";
import isEmpty from "lodash.isempty";

type BaseInputProps = Pick<
  ComponentProps<"input">,
  | "onChange"
  | "onMouseOver"
  | "onMouseLeave"
  | "onMouseEnter"
  | "onFocus"
  | "onBlur"
  | "type"
  | "value"
  | "autoFocus"
  | "disabled"
  | "placeholder"
>;

type InputProps = BaseInputProps & {
  startIcon?: IconName;
  endIcon?: IconName;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  size?: "medium" | "small";
  title?: ReactNode;
  fullWidth?: boolean;
  error?: string;
  hideErrorMessage?: boolean;
};

const Label = styled.label`
  display: grid;
  grid-gap: var(--spacing-small);
`;

const StyledInput = styled.input<{
  $size: "medium" | "small";
  $error?: boolean;
  $fullWidth: boolean;
  $disabled?: boolean;
}>`
  border-radius: var(--rounding-medium);
  border: 1px solid transparent;
  font-family: var(--typography-font-text);
  font-size: var(--typography-size-title);
  letter-spacing: 0.01em;
  width: ${p => (p.$fullWidth ? "100%" : "auto")};
  width: 100%;
  border-color: var(--palette-border-subdued);
  background-color: transparent;
  box-sizing: border-box;
  padding: var(--spacing-small) var(--spacing-medium);

  :hover {
    border-color: var(--palette-primary-default);
  }

  ${({ $size }) => {
    if ($size === "small") {
      return css`
        line-height: 2rem;
        background-color: var(--palette-surface-subdued);
      `;
    } else {
      return css`
        line-height: 2.5rem;
        padding: 0 var(--spacing-medium);
      `;
    }
  }}

  :focus {
    outline: none;
    border-color: var(--palette-primary-default);
    box-shadow: 0 0 0 3px var(--palette-primary-ghosted);
  }

  ${({ $error }) =>
    $error &&
    css`
      background-color: var(--palette-danger-ghosted);
      border-color: var(--palette-danger-default);
      :hover {
        border-color: var(--palette-danger-hovered);
      }
      :focus {
        border-color: var(--palette-danger-default);
        box-shadow: 0 0 0 3px var(--palette-danger-ghosted);
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: var(--palette-surface-dimmed);
      border-color: var(--palette-border-subdued);

      :hover {
        border-color: var(--palette-border-subdued);
      }

      :focus {
        border-color: transparent;
        box-shadow: none;
      }
    `}


  /* Hide the default arrows for number inputs */
  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none; /* WebKit browsers like Safari and Chrome */
      margin: 0;
    }
  }
`;

const InputWrapper = styled.div<{ $type: string }>`
  position: relative;

  ${({ $type }) =>
    $type === "number" &&
    css`
      max-width: 6rem;
    `}
`;

const StartIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
`;

const EndIconWrapper = styled(StartIconWrapper)`
  left: auto;
  right: 1rem;
`;

const EndAdornment = styled.span<{ $hasEndIcon?: boolean }>`
  position: absolute;
  display: flex;
  top: 50%;
  right: ${({ $hasEndIcon }) => ($hasEndIcon ? "40px" : "14px")};
  transform: translateY(-50%);
`;

const NumberArrowsWrapper = styled(EndIconWrapper)`
  flex-direction: column;
  right: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
`;

const ArrowButton = styled.button`
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 21px;
  height: 20px;
  padding: 0;
  background-color: var(--palette-surface-default);

  :hover {
    background-color: var(--palette-surface-hovered);
  }

  :active {
    background-color: var(--palette-surface-pressed);
  }
`;

const getSidePadding = (hasIcon: boolean, hasEndAdornment: boolean) => {
  if (hasIcon && hasEndAdornment) return 64;
  if (hasIcon || hasEndAdornment) return 48;
  return 14;
};

export const InputV2 = ({
  title,
  size = "medium",
  endIcon,
  startIcon,
  fullWidth = false,
  startAdornment,
  error,
  disabled,
  placeholder,
  type = "text",
  endAdornment,
  onChange,
  value,
  ...rest
}: InputProps) => {
  if (type === "number") {
    const allPropsExceptType = {
      ...rest,
      title,
      size,
      endIcon,
      startIcon,
      fullWidth,
      startAdornment,
      error,
      disabled,
      placeholder,
      endAdornment,
      onChange,
      value
    };
    return <NumberInput {...allPropsExceptType} />;
  }

  return (
    <Label>
      {title && <Title>{title}</Title>}
      <InputWrapper $type={type}>
        {startIcon && (
          <StartIconWrapper>
            <Icon name={startIcon} />
          </StartIconWrapper>
        )}
        <StyledInput
          {...rest}
          disabled={disabled}
          value={value}
          $size={size}
          style={{
            paddingLeft: getSidePadding(!!startIcon, !!startAdornment),
            paddingRight: getSidePadding(!!endIcon, !!endAdornment)
          }}
          $fullWidth={fullWidth}
          type={type}
          $error={!!error}
          $disabled={disabled}
          placeholder={type === "number" ? "0" : placeholder}
          onChange={onChange}
        />
        {endAdornment && <EndAdornment $hasEndIcon={!!endIcon}>{endAdornment}</EndAdornment>}
        {endIcon && (
          <EndIconWrapper>
            <Icon name={endIcon} />
          </EndIconWrapper>
        )}
      </InputWrapper>
    </Label>
  );
};

export const NumberInput = ({
  title,
  size = "medium",
  endIcon,
  startIcon,
  fullWidth = false,
  startAdornment,
  error,
  disabled,
  placeholder,
  endAdornment,
  onChange,
  value,
  ...rest
}: Omit<InputProps, "type">) => {
  const type = "number";

  const isNumber = !!value && value !== "" && !isNaN(Number(value));

  const handleNumberButtonChange = (newValue: number) => {
    const event = {
      target: {
        value: String(newValue),
        type: "change"
      }
    } as ChangeEvent<HTMLInputElement>;

    if (onChange) {
      onChange(event);
    }
  };

  const increment = () => {
    // try cast value to number
    const number = Number(value);
    const newValue = number + 1;
    handleNumberButtonChange(newValue);
  };

  const decrement = () => {
    const number = Number(value);
    const newValue = number - 1;
    handleNumberButtonChange(newValue);
  };

  return (
    <Label>
      {title && <Title>{title}</Title>}
      <InputWrapper $type="number">
        {startIcon && (
          <StartIconWrapper>
            <Icon name={startIcon} />
          </StartIconWrapper>
        )}
        <StyledInput
          {...rest}
          disabled={disabled}
          value={value}
          $size={size}
          style={{
            paddingLeft: getSidePadding(!!startIcon, !!startAdornment),
            paddingRight: getSidePadding(!!endIcon, !!endAdornment) + 22
          }}
          $fullWidth={fullWidth}
          type={type}
          $error={!!error}
          $disabled={disabled}
          placeholder={"0"}
          onChange={onChange}
        />
        {endAdornment && <EndAdornment $hasEndIcon={!!endIcon}>{endAdornment}</EndAdornment>}
        {endIcon && (
          <EndIconWrapper>
            <Icon name={endIcon} />
          </EndIconWrapper>
        )}
        {type === "number" && (
          <NumberArrowsWrapper>
            <ArrowButton onClick={increment}>
              <svg
                width="8"
                height="4"
                viewBox="0 0 8 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.14645 3.14645L4.42426 0.424264C4.18995 0.18995 3.81005 0.189949 3.57574 0.424263L0.853553 3.14645C0.538571 3.46143 0.761454 4 1.20711 4H6.79289C7.23835 4 7.46143 3.46143 7.14645 3.14645Z"
                  fill="black"
                />
              </svg>
            </ArrowButton>
            <ArrowButton onClick={decrement}>
              <svg
                width="8"
                height="4"
                viewBox="0 0 8 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="rotate(180)"
              >
                <path
                  d="M7.14645 3.14645L4.42426 0.424264C4.18995 0.18995 3.81005 0.189949 3.57574 0.424263L0.853553 3.14645C0.538571 3.46143 0.761454 4 1.20711 4H6.79289C7.23835 4 7.46143 3.46143 7.14645 3.14645Z"
                  fill="black"
                />
              </svg>
            </ArrowButton>
          </NumberArrowsWrapper>
        )}
      </InputWrapper>
      {!isNumber && !isEmpty(value) && <ErrorMessage>Value must be a number</ErrorMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Label>
  );
};
