import React, { ComponentProps } from "react";
import { ColorVariants } from "../../";
import styles from "./Indicator.module.scss";
import cx from "classnames";

export interface IndicatorProps extends Omit<ComponentProps<"div">, "color"> {
  color: ColorVariants;
  vertical?: boolean;
}

export const Indicator = ({ color, vertical, className, style, ...rest }: IndicatorProps) => {
  return (
    <div
      className={cx(styles.Indicator, { [styles.Vertical]: vertical }, className)}
      style={{ backgroundColor: color.default, ...style }}
      {...rest}
    />
  );
};
