import React from "react";
import { SchemaDefinition } from "validate";
import { useForm } from "./useForm";
import { Button, H3, Input } from "../../01-atoms";
import { useToast } from "../useToast";
import { useTheme } from "../useTheme";

const formSchema: SchemaDefinition = {
  first: {
    type: "string",
    required: true
  },
  second: {
    type: "string",
    required: false
  }
};

const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export const UseFormExamples = () => {
  const toast = useToast();
  const { theme } = useTheme();

  const form = useForm(formSchema, undefined, true);
  const asyncForm = useForm();

  const onSubmit = () => {
    try {
      form.submit();
      toast.success("Submitted!");
    } catch (err) {
      toast.error("Whoops, that's not valid!");
    }
  };

  const onSubmitAsync = async () => {
    try {
      asyncForm.submit(formSchema);
      await wait(1000);
      toast.success("Submitted!");
    } catch (err) {
      toast.error("Whoops, that's not valid!");
    }
  };

  const fill = () => {
    form.setMany({ first: "First", second: "Second" });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.small,
          width: "400px",
          margin: "0 auto"
        }}
      >
        <H3>Sync form</H3>
        <Button onClick={fill} variant="outlined">
          Autocomplete form
        </Button>
        <Input
          value={form.get("first")}
          onChange={form.set("first")}
          error={form.isError("first")}
          placeholder="First required item"
        />
        <Input
          value={form.get("second")}
          onChange={form.set("second")}
          placeholder="Second optional item"
          error={form.isError("second")}
        />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button onClick={onSubmit}>Submit sync</Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.small,
          width: "400px",
          margin: "0 auto",
          marginTop: "4rem"
        }}
      >
        <H3>Async form</H3>
        <Input
          value={asyncForm.get("first")}
          onChange={asyncForm.set("first")}
          error={asyncForm.isError("first")}
          placeholder="First required item"
        />
        <Input
          value={asyncForm.get("second")}
          onChange={asyncForm.set("second")}
          placeholder="Second optional item"
          error={asyncForm.isError("second")}
        />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button variant="outlined" onClickWithLoading={onSubmitAsync}>
            Submit async
          </Button>
        </div>
      </div>
    </div>
  );
};
