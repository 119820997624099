import React, { useState } from "react";
import styled, { css } from "styled-components";

import { Icon } from "../../00-assets";

import { H1, H4, Title, TypographyVariant } from "../Typography";
import { TPopoverPlacement } from "../Popover";
import { Tag } from "../Tag";
import { Input } from "../Input";
import { Button } from "../Button";

import { Tooltip } from "./Tooltip";

const placements: TPopoverPlacement[] = [
  "bottom",
  "bottom-start",
  "left",
  "left-start",
  "right",
  "top"
];

const typographyVariants: TypographyVariant[] = ["bold", "regular", "uppercase"];

const StyledTitle = styled(Title)`
  padding: 2rem;
  border-radius: 6px;
  border: 1px dashed var(--palette-border-subdued);
`;

const Wrapper = styled.section`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 26rem;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;

    > :first-child {
      flex: 0 0 100%;
    }
  }

  > section {
    padding-top: 1.5rem;
    display: grid;
    grid-row-gap: 1rem;
    border-top: 1px solid var(--palette-border-subdued);
  }
`;

const StyledTag = styled(Tag)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    css`
      background-color: var(--palette-primary-default);

      :hover {
        background-color: var(--palette-primary-default);
      }
      > h5 {
        color: white;
      }
    `}
`;

export const TooltipExamples = () => {
  const [placement, setPlacement] = useState<TPopoverPlacement>("bottom");
  const [variant, setVariant] = useState<TypographyVariant>("regular");
  const [enterDelayMs, setEnterDelayMs] = useState(100);

  const props = { placement, variant, enterDelayMs };

  return (
    <Wrapper>
      <H4 style={{ marginBottom: "1rem" }}>Props</H4>
      <div>
        <Title variant="bold">Alignment</Title>
        {placements.map(plcmnt => (
          <StyledTag onClick={() => setPlacement(plcmnt)} $active={plcmnt === placement}>
            {plcmnt}
          </StyledTag>
        ))}
      </div>
      <div>
        <Title variant="bold">Typography variant</Title>
        {typographyVariants.map(vrnt => (
          <StyledTag onClick={() => setVariant(vrnt)} $active={vrnt === variant}>
            {vrnt}
          </StyledTag>
        ))}
      </div>
      <div>
        <Title variant="bold">Enter delay ms</Title>
        <Input
          style={{ width: "6rem" }}
          type="number"
          defaultValue={enterDelayMs}
          onChange={value => setEnterDelayMs(Number(value))}
        />
      </div>
      <section>
        <H4 style={{ marginBottom: "0.5rem" }}>Examples</H4>
        <div style={{ justifySelf: "flex-start" }}>
          <Tooltip tooltip="Very informative stuff" {...props}>
            <Icon
              style={{
                display: "block"
              }}
              name="infoFilled"
            />
          </Tooltip>
        </div>

        <Tooltip tooltip="Another tooltip" {...props}>
          <StyledTitle>This is some content</StyledTitle>
        </Tooltip>

        <Tooltip
          style={{ maxWidth: "150px" }}
          tooltip="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac dui nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac dui nulla."
          {...props}
        >
          <StyledTitle>This should have large tooltip</StyledTitle>
        </Tooltip>

        <Title style={{ marginBottom: "0.75rem" }}>
          Note: in this case Typography variant doesn't affect on tooltip text as it's a separate
          component
        </Title>
        <Tooltip
          tooltip={
            <div style={{ padding: "5rem" }}>
              <H1 inverted>I won't</H1>
            </div>
          }
          {...props}
        >
          <Button>Some other component with tooltip</Button>
        </Tooltip>
      </section>
    </Wrapper>
  );
};
