import { Pick } from "@react-spring/web";
import React, { ComponentProps } from "react";

import styled, { css } from "styled-components";

type Props = Pick<ComponentProps<"label">, "style"> & {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  small?: boolean;
  readOnly?: boolean;
};

const Label = styled.label<{ $small?: boolean; $disabled?: boolean }>`
  position: relative;
  display: inline-block;
  cursor: pointer;
  flex-shrink: 0;
  width: ${({ $small }) => ($small ? "24px" : "35px")};
  height: ${({ $small }) => ($small ? "14px" : "20px")};

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `}
`;

const Slider = styled.span<{ $small?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--palette-border-default);
  transition: all 140ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 100px;

  ::before {
    position: absolute;
    content: "";
    width: ${({ $small }) => ($small ? "10px" : "16px")};
    height: ${({ $small }) => ($small ? "10px" : "16px")};
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: all 140ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 50%;
  }
`;

const HiddenInput = styled.input<{ $small?: boolean }>`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + ${Slider}.Disabled {
    background-color: var(--palette-primary-ghosted);
  }

  :checked + ${Slider} {
    background-color: var(--palette-primary-default);

    ::before {
      transform: ${({ $small }) => ($small ? "translateX(10px)" : "translateX(15px)")};
    }
  }
`;

export const Toggle = ({ checked, onChange, small, disabled, ...props }: Props) => {
  return (
    <Label {...props} $disabled={disabled} $small={small}>
      <HiddenInput readOnly type="checkbox" checked={checked} disabled={disabled} $small={small} />
      <Slider onClick={() => !disabled && onChange(!checked)} $small={small} />
    </Label>
  );
};
