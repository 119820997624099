import React, { useRef, useState } from "react";
import { OptionListItem } from ".";
import { Card } from "..";
import { Icon } from "../../";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";

export const OptionListItemExamples = () => {
  const [checked, setChecked] = useState(false);
  const testRef = useRef<HTMLButtonElement | null>(null);
  return (
    <div style={{ width: "400px" }}>
      <ComponentSection title="Simple" description="Simple list with options">
        <Card style={{ padding: "0.5rem" }} elevated>
          <div style={{ display: "grid", gap: ".5rem" }}>
            <OptionListItem
              ref={testRef}
              label="Really long text to mess up the space and make sure that the text "
              selected={checked}
              onMouseEnter={() =>
                console.debug("mouse enter", testRef.current?.getBoundingClientRect().top)
              }
              onMouseLeave={() => console.debug("mouse leave")}
              onClick={() => setChecked(!checked)}
            />
            <OptionListItem label="Text" selected={!checked} onClick={() => setChecked(!checked)} />
            <OptionListItem
              label="Text"
              disabled
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
          </div>
        </Card>
      </ComponentSection>

      <ComponentSection title="Adornments" description="Item can have icon as adornment">
        <Card style={{ padding: "0.5rem" }} elevated>
          <div style={{ display: "grid", gap: ".5rem" }}>
            <OptionListItem
              label="Really long text to mess up the space and make sure that the text "
              endAdornment={<Icon name="trigger" />}
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
            <OptionListItem
              label="Text"
              endAdornment={<Icon name="trigger" />}
              selected={!checked}
              onClick={() => setChecked(!checked)}
            />
            <OptionListItem
              label="Text"
              endAdornment={<Icon name="trigger" />}
              disabled
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
          </div>
        </Card>

        <Card style={{ padding: "0.5rem" }} elevated>
          <div style={{ display: "grid", gap: ".5rem" }}>
            <OptionListItem
              label="Really long text to mess up the space and make sure that the text "
              startAdornment={<Icon name="trigger" />}
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
            <OptionListItem
              label="Text"
              startAdornment={<Icon name="trigger" />}
              selected={!checked}
              onClick={() => setChecked(!checked)}
            />
            <OptionListItem
              label="Text"
              startAdornment={<Icon name="trigger" />}
              disabled
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
          </div>
        </Card>
      </ComponentSection>

      <ComponentSection title="Multiselect" description="Allow selecting multiple options">
        <Card style={{ padding: "0.5rem" }} elevated>
          <div style={{ display: "grid", gap: ".5rem" }}>
            <OptionListItem
              label="Text"
              selectable
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
            <OptionListItem
              label="Text"
              selected={!checked}
              onClick={() => setChecked(!checked)}
              selectable
            />
            <OptionListItem
              label="Text"
              disabled
              selectable
              selected={checked}
              onClick={() => setChecked(!checked)}
            />
          </div>
        </Card>
      </ComponentSection>

      <ComponentSection
        title="Multiselect with icons"
        description="Multiselect can have icons as start adornment"
      >
        <Card style={{ padding: "0.5rem" }} elevated>
          <div style={{ display: "grid", gap: ".5rem" }}>
            <OptionListItem
              label="Really long text to mess up the space and make sure that the text "
              startAdornment={<Icon name="trigger" />}
              selected={checked}
              onClick={() => setChecked(!checked)}
              selectable
            />
            <OptionListItem
              label="Text"
              startAdornment={<Icon name="description" />}
              selected={!checked}
              onClick={() => setChecked(!checked)}
              selectable
            />
            <OptionListItem
              label="Text"
              startAdornment={<Icon name="persons" />}
              disabled
              selected={checked}
              onClick={() => setChecked(!checked)}
              selectable
            />
          </div>
        </Card>
      </ComponentSection>
    </div>
  );
};
