import React, { ChangeEvent, ComponentProps, useRef, useState } from "react";
import cx from "classnames";

import { styled } from "../styled";
import styles from "./TextArea.module.scss";

export interface TextAreaProps extends Omit<ComponentProps<"textarea">, "onChange"> {
  error?: boolean;
  onChange?: (value: string, event: ChangeEvent) => void;
  blurOnEnter?: boolean;
  fullWidth?: boolean;
  readonly?: boolean;
  active?: boolean;
  variant?: "default" | "blended";
}

export const TextArea = ({
  error,
  onChange,
  fullWidth,
  readonly,
  disabled,
  active,
  className,
  style,
  blurOnEnter,
  variant = "default",
  ...props
}: TextAreaProps) => {
  const [focus, setFocus] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const eventHandlers = {
    onFocus: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocus(true);
      props.onFocus && props.onFocus(e);
    },
    onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocus(false);
      props.onBlur && props.onBlur(e);
    },
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange && onChange(e.target.value, e);
    }
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (blurOnEnter && e.key === "Enter") textareaRef.current?.blur();
  };

  const stateStyles = {
    [styles.Disabled]: disabled,
    [styles.Readonly]: readonly,
    [styles.Focus]: focus,
    [styles.Error]: error,
    [styles.Active]: active !== undefined ? active : focus // Allows overriding focus by giving active = false
  };

  return styled(
    "textarea",
    {
      ...props,
      ...eventHandlers,
      readOnly: readonly,
      ref: textareaRef,
      onKeyDown: handleKeydown,
      disabled: disabled || readonly
    },
    cx(styles.TextArea, styles[variant], stateStyles, className),
    {
      width: fullWidth ? "100%" : undefined,
      ...style
    }
  );
};
