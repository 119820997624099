import { styled } from "../../styled";
import React, { ComponentProps, ReactNode } from "react";

import styles from "./Title.module.scss";
import { TypographyVariant } from "../index";
import { css } from "styled-components";

export interface TitleProps {
  variant?: TypographyVariant;
  color?: string;
  style?: React.CSSProperties;
  as?: keyof JSX.IntrinsicElements;
  small?: boolean;
  children?: ReactNode;
  className?: string;
}

export const titleFontStyle = css`
  font-family: var(--typography-font-heading);
  font-weight: 400;
  font-size: var(--typography-size-title);
  line-height: 1.25rem;
  letter-spacing: 0.01em;
`;

export const boldTitleFontStyle = css`
  ${titleFontStyle};
  font-weight: 500;
`;

export const Title = ({
  variant = "regular",
  color,
  style,
  as = "h5",
  small = false,
  ...props
}: TitleProps) =>
  styled(as, props, styles.Title, styles[variant], small ? styles["small"] : [], {
    color,
    ...style
  });
