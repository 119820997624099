import React, { ComponentProps, CSSProperties, ReactNode, useEffect, useState } from "react";
import { Backdrop } from "../../00-assets/Backdrop";
import cx from "classnames";
import { Card } from "../../01-atoms";
import { CloseIconButton } from "../IconButton";

import styles from "./BaseModal.module.scss";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

export interface BaseModalProps extends Omit<ComponentProps<"div">, "ref"> {
  open: boolean;
  onClose?: () => any;
  closeButton?: boolean;
  closeOnOutsideClick?: boolean;
  maxWidth?: CSSProperties["width"];
  maxHeight?: CSSProperties["height"];
  header?: ReactNode;
  footer?: ReactNode;
}

export const BaseModal = ({
  open,
  onClose,
  closeOnOutsideClick = true,
  closeButton = true,
  maxWidth = "95vw",
  maxHeight = "95vh",
  className,
  style,
  header,
  footer,
  children,
  ...props
}: BaseModalProps) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);
  const [leaving, setLeaving] = useState(false);

  useEffect(() => {
    if (open) {
      setVisible(true);
      element && disableBodyScroll(element);
    }
    if (!open && visible) {
      clearAllBodyScrollLocks();
      setLeaving(true);
      const timeout = setTimeout(() => {
        setVisible(false);
        setLeaving(false);
      }, 250);

      return () => {
        timeout && clearTimeout(timeout);
      };
    }
  }, [open, element]);

  if (!visible) {
    return null;
  }

  const renderHeader = (closeButton && onClose) || header;

  return (
    <Backdrop
      className={cx(styles.ModalBackdrop)}
      onClick={closeOnOutsideClick ? onClose : undefined}
      open={open}
    >
      <Card
        className={cx(
          styles.Modal,
          {
            [styles.Out]: leaving
          },
          className
        )}
        style={{
          maxWidth,
          maxHeight,
          ...style
        }}
        {...props}
      >
        <div
          ref={setElement}
          className={cx(styles.Root, {
            [styles.WithHeader]: renderHeader,
            [styles.WithFooter]: !!footer
          })}
        >
          {renderHeader && (
            <div className={styles.Header}>
              {header || <div />}
              {closeButton && onClose ? (
                <CloseIconButton onClick={onClose} className={styles.CloseButton} />
              ) : (
                <div />
              )}
            </div>
          )}
          {children}
          {footer && <div className={styles.Footer}>{footer}</div>}
        </div>
      </Card>
    </Backdrop>
  );
};
