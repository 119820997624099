import React, { ReactElement, useState } from "react";
import styled, { css } from "styled-components";

import { Popover, IPopoverProps, TPopoverPlacement } from "../Popover";
import { Title, TypographyVariant } from "../Typography";
import { Card, ICardProps } from "../Card";

export type TooltipProps = Pick<IPopoverProps, "children"> &
  Omit<ICardProps, "variant"> & {
    tooltip: string | ReactElement;
    variant?: TypographyVariant;
    enterDelayMs?: number;
    placement?: TPopoverPlacement;
  };

const TooltipPointer = styled.span<{ $placement: TPopoverPlacement }>`
  display: block;
  position: absolute;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: inherit;
  border-radius: 1px;
  z-index: -1;

  // Note: The order of the transforms matter
  ${({ $placement }) => {
    switch ($placement) {
      case "bottom-start":
        return css`
          top: -0.25rem;
          left: 1rem;
          transform: rotateZ(45deg);
        `;
      case "left":
        return css`
          top: 50%;
          transform: rotateZ(45deg) translateY(-50%);
          transform-origin: top;
          left: auto;
          right: -0.25rem;
        `;
      case "left-start":
        return css`
          top: 1rem;
          transform: rotateZ(45deg);
          left: auto;
          right: -0.25rem;
        `;
      case "right":
        return css`
          top: 50%;
          transform: rotateZ(45deg) translateY(-50%);
          transform-origin: top;
          left: -0.25rem;
        `;
      case "top":
        return css`
          transform-origin: center;
          bottom: -0.25rem;
          transform: translateX(-50%) rotate(45deg);
        `;
      case "bottom":
      default:
        return css`
          top: -0.25rem;
          transform-origin: center;
          transform: translateX(-50%) rotate(45deg);
        `;
    }
  }}
`;

const StyledCard = styled(Card)`
  position: relative;
  border-radius: var(--rounding-small);
  padding: var(--spacing-small) var(--spacing-medium);
  color: var(--palette-background-default);
  pointer-events: none;

  * {
    color: inherit;
  }
`;

export const Tooltip = ({
  children,
  tooltip,
  placement: placementProp = "bottom",
  variant = "regular",
  enterDelayMs = 100,
  ...cardProps
}: TooltipProps) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [enter, setEnterTimeout] = useState<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const [placement, setPlacement] = useState(placementProp);

  const handleMouseEnter = () => {
    const start = () =>
      setEnterTimeout(
        setTimeout(() => {
          setOpen(true);
          setEnterTimeout(null);
        }, enterDelayMs)
      );
    if (tooltip && !enter) start();
  };

  const handleMouseLeave = () => {
    if (enter) {
      clearTimeout(enter);
      setEnterTimeout(null);
    }
    setOpen(false);
  };

  const content =
    typeof tooltip === "string" ? <Title variant={variant}>{tooltip}</Title> : tooltip;

  return (
    <>
      <div
        ref={setRefElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ display: "grid" }}
      >
        {children}
      </div>
      <Popover
        onPlacementChange={placement => setPlacement(placement as TPopoverPlacement)}
        open={open}
        referenceElement={refElement}
        onClose={() => {}}
        placement={placementProp}
        style={{ pointerEvents: "none" }}
      >
        <StyledCard mode="dark" {...cardProps}>
          {content}
          <TooltipPointer $placement={placement} />
        </StyledCard>
      </Popover>
    </>
  );
};
