import React, { useCallback, useMemo, useRef, useState } from "react";
import { Popover } from "../../01-atoms";

import { Focus, Dates } from "..";
import { MomentDates, RangeCalendar } from "./components/RangeCalendar";
import { DateInputs } from "./components/DateInputs";

import styles from "./DateRangeSelect.module.scss";
import moment from "moment";

interface Props {
  dates: Dates;
  onChangeDates: (dates: Dates) => void;
}

export const DateRangeSelect: React.VFC<Props> = ({ onChangeDates, dates }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState<Focus>(null);

  const momentDates: MomentDates = useMemo(() => {
    return {
      startDate: dates.startDate === null ? null : moment(dates.startDate, "YYYY-MM-DD"),
      endDate: dates.endDate === null ? null : moment(dates.endDate, "YYYY-MM-DD")
    };
  }, [dates]);

  const onChangeMomentDates = useCallback(
    (momentDates: MomentDates) => {
      onChangeDates({
        startDate:
          momentDates.startDate === null ? null : momentDates.startDate.format("YYYY-MM-DD"),
        endDate: momentDates.endDate === null ? null : momentDates.endDate.format("YYYY-MM-DD")
      });
    },
    [dates, onChangeDates]
  );

  return (
    <>
      <div ref={ref} className={styles.Container}>
        <DateInputs dates={momentDates} onChangeDates={onChangeMomentDates} setFocus={setFocus} />
      </div>
      <Popover
        referenceElement={ref.current}
        open={focus !== null}
        onClose={() => setFocus(null)}
        closeOnContentClick={false}
        placement="bottom-start"
      >
        <RangeCalendar
          dates={momentDates}
          onChangeDates={onChangeMomentDates}
          focus={focus}
          onFocusChange={setFocus}
        />
      </Popover>
    </>
  );
};
