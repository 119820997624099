import React, { useMemo } from "react";
import { ButtonProps, Button, useButtonForegroundColor } from "../../01-atoms/Button";
import { Icon, IconName } from "../../00-assets";
import styled, { css } from "styled-components";
import { Tooltip, TooltipProps } from "v2/01-atoms";

export interface IconButtonProps
  extends Omit<ButtonProps, "colors" | "startAdornment" | "endAdornment"> {
  icon: IconName | React.ReactNode;
  tooltip?: string;
  dimmed?: boolean;
  tooltipPlacement?: Pick<TooltipProps, "placement">;
}

const StyledIconButton = styled(Button)<{
  $size: "small" | "large" | "medium";
  $dimmed?: boolean;
  $variant: ButtonProps["variant"];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-height: auto;
  box-shadow: none;
  :active {
    transform: none;
  }

  h5 {
    display: flex;
  }

  ${({ $variant, $dimmed }) => {
    if (($variant === "blended" || $variant === "outlined") && $dimmed) {
      return css`
        svg {
          opacity: 0.6;
        }

        :hover svg {
          opacity: 1;
        }
      `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          width: 20px;
          height: 20px;
          border-radius: var(--rounding-small);
        `;
      case "medium":
        return css`
          width: 26px;
          height: 26px;
        `;
      case "large":
        return css`
          height: 30px;
          width: 30px;
        `;
    }
  }}
`;

export const IconButton = ({
  icon,
  size = "small",
  dimmed,
  variant = "blended",
  tooltip,
  destructive,
  ...rest
}: IconButtonProps) => {
  const { foregroundColor } = useButtonForegroundColor(variant, !!destructive);

  const button = useMemo(() => {
    return (
      <StyledIconButton
        $dimmed={dimmed}
        $size={size}
        variant={variant}
        destructive={destructive}
        {...rest}
        $variant={variant}
      >
        {typeof icon === "string" ? <Icon name={icon as IconName} color={foregroundColor} /> : icon}
      </StyledIconButton>
    );
  }, [size, variant, icon, foregroundColor, destructive, rest]);

  return tooltip ? (
    <Tooltip enterDelayMs={50} tooltip={tooltip}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

type CloseIconButtonProps = Omit<IconButtonProps, "icon" | "tooltip"> & {};
export const CloseIconButton = ({
  size = "small",
  variant = "blended",
  destructive,
  ...rest
}: CloseIconButtonProps) => {
  const { foregroundColor } = useButtonForegroundColor(variant, !!destructive);

  return (
    <StyledIconButton
      $size={size}
      variant={variant}
      destructive={destructive}
      {...rest}
      $variant={variant}
    >
      <Icon
        name={size === "small" || size === "medium" ? "crossSmall" : "crossLarge"}
        color={foregroundColor}
      />
    </StyledIconButton>
  );
};
