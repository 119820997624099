import React from "react";
import { Time, TimeFormat, TimePicker, TimePickerProps, TimeRange, toMoment } from "./TimePicker";
import { Title } from "../../01-atoms";

import styles from "./TimePicker.module.scss";
import cx from "classnames";

export interface TimeRangePickerProps extends Omit<TimePickerProps, "onSelect" | "time"> {
  value: TimeRange;
  onSelect: (range: TimeRange) => unknown;
  separator?: string;
  maintainDuration?: boolean;
}

export const TimeRangePicker = ({
  value,
  onSelect,
  separator = "to",
  timeWindow = { start: "00:00", end: "23:59" },
  className,
  style,
  maintainDuration = true,
  ...rest
}: TimeRangePickerProps) => {
  const onSelectStart = (start: Time) => {
    if (!maintainDuration) return onSelect({ start, end: value.end });

    const durationMinutes = Math.abs(toMoment(value.start).diff(toMoment(value.end!), "minutes"));

    let end: Time | undefined = toMoment(start).add(durationMinutes, "minutes").format(TimeFormat);

    if (toMoment(end).isAfter(toMoment(timeWindow.end!))) {
      end = undefined;
    }

    return onSelect({ start, end });
  };

  return (
    <div className={cx(styles.TimeRangePicker, className)} style={style}>
      <TimePicker
        searchable
        time={value.start}
        onSelect={onSelectStart}
        timeWindow={timeWindow}
        {...rest}
      />
      <Title>{separator}</Title>
      <TimePicker
        searchable
        time={value.end}
        onSelect={end => onSelect({ ...value, end })}
        timeWindow={{ start: value.start, end: timeWindow.end }}
        {...rest}
      />
    </div>
  );
};
