import React from "react";
import { DrawerProps } from "../Drawer";
import { ModalV2 } from "../../../02-blocks";
import styled from "styled-components";

const StyledModal = styled(ModalV2)`
  height: 100%;
  box-sizing: border-box;
`;

export const MobileDrawer = ({ open = false, children, ...rest }: DrawerProps) => {
  return (
    <StyledModal open={open} maxHeight={100} fullWidth {...rest}>
      {children}
    </StyledModal>
  );
};
