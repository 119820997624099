import cx from "classnames";
import React, { ComponentProps } from "react";

import styles from "./Checkbox.module.scss";

export interface CheckboxProps
  extends CheckboxBaseProps,
    Omit<ComponentProps<"label">, "onChange"> {}

export interface CheckboxBaseProps {
  checked: boolean;
  onChange: (checked: boolean, e: React.MouseEvent<any>) => unknown;
  disabled?: boolean;
  readonly?: boolean;
}

export const Checkbox = ({
  checked,
  onChange,
  disabled,
  readonly,
  className,
  ...rest
}: CheckboxProps) => {
  const stateStyles = cx({
    [styles.Disabled]: disabled,
    [styles.Checked]: checked,
    [styles.Readonly]: readonly
  });
  const checkmarkClasses = cx(styles.Checkmark, stateStyles);
  const checkboxClasses = cx(styles.Checkbox, className, {
    [styles.Readonly]: readonly,
    [styles.Disabled]: disabled
  });

  return (
    <label className={checkboxClasses} {...rest}>
      <input type="checkbox" checked={checked} disabled readOnly={readonly} />
      <span
        className={checkmarkClasses}
        onClick={e => !disabled && !readonly && onChange(!checked, e)}
      ></span>
    </label>
  );
};
