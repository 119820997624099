import React, { CSSProperties, ReactNode } from "react";
import { useResponsive } from "../../03-hooks";
import { DesktopDrawer } from "./DesktopDrawer";
import { MobileDrawer } from "./MobileDrawer";

export type DrawerMode = "layout" | "overlay";

export interface DrawerProps {
  position?: "left" | "right";
  children?: ReactNode;
  open?: boolean;
  className?: string;
  style?: object;
  width?: string;
  onClose?: () => any;
  lockScroll?: boolean;
  backdropStyles?: CSSProperties;
}

export const Drawer = (props: DrawerProps) => {
  const { isMobileLayout } = useResponsive();
  if (isMobileLayout) return <MobileDrawer {...props} />;
  return <DesktopDrawer {...props} />;
};
