import React from "react";
import { CollapsibleNav } from "./CollapsibleNav";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";

export const CollapsibleNavExamples = () => {
  const items = [
    { section: "first", key: "first-1", href: "#first-1" },
    { section: "first", key: "first-2", href: "#first-2" },
    { section: "second", key: "second-1", href: "#second-1" },
    { section: "third", key: "third-1", href: "#third-1" },
    { section: "third", key: "third-2", href: "#third-2" },
    { section: "third", key: "third-3", href: "#third-3" },
    { section: "third", key: "third-4", href: "#third-4" }
  ];

  const selectedKey = window.location.hash.replace("#", "");

  return (
    <ComponentSection title="Basic">
      <div style={{ background: "#1B263A" }}>
        <CollapsibleNav items={items} selectedKey={selectedKey} style={{ margin: "1rem" }} />
      </div>
    </ComponentSection>
  );
};
