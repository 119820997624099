import React, { FC, ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { animated, config, useSpring } from "@react-spring/web";
import useMeasure from "react-use-measure";

import { Title } from "../../01-atoms/Typography";
import { Icon } from "../../00-assets/Icons";

import { IconButton } from "..";

export interface IAccordionProps {
  title: ReactNode;
  description?: string;
  error?: string;
  blended?: boolean;
  defaultOpen?: boolean;
  toggleOpenOnHeaderClick?: boolean;
  compact?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
}

const StyledAccordion = styled.section<{
  $isToggleOpenButtonHover: boolean;
  $hasError?: boolean;
  $clickable?: boolean;
  $blended?: boolean;
}>`
  border-radius: var(--rounding-medium);
  overflow: hidden;
  position: relative;
  background: var(--palette-background-default);
  color: var(--palette-foreground-default);
  border: 1px solid var(--palette-border-subdued);
  transition: border-color 160ms ease;
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};

  ${({ $clickable, $isToggleOpenButtonHover }) =>
    $clickable &&
    css`
      cursor: pointer;
      transition: background-color 160ms ease;

      :hover {
        background: ${$isToggleOpenButtonHover ? "initial" : "var(--palette-surface-dimmed)"};

        .bottom-gradient {
          opacity: 0;
        }
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: var(--palette-danger-default);
    `}

    ${({ $blended }) =>
    $blended &&
    css`
      border: none;
      padding: 0;

      > header,
      > div > div {
        padding: 0;
      }

      header {
        padding-bottom: 1.5rem;
      }

      > div > div {
        padding-bottom: 1.5rem;
      }
    `}
`;

const AccordionHeader = styled.header<{
  $compact?: boolean;
  $toggleOpenOnHeaderClick?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  ${({ $toggleOpenOnHeaderClick }) =>
    $toggleOpenOnHeaderClick &&
    css`
      cursor: pointer;
    `}

  ${({ $compact }) => {
    if ($compact) {
      return css`
        padding: var(--spacing-medium) var(--spacing-large);
      `;
    } else {
      return css`
        padding: var(--spacing-large) var(--spacing-xLarge);
      `;
    }
  }}

  ${({ $toggleOpenOnHeaderClick }) => {
    if ($toggleOpenOnHeaderClick) {
      return css`
        :hover {
          button {
            background: var(--palette-background-hovered);
          }
        }
      `;
    }
  }}
`;

const AccordionContent = styled.div<{ $compact?: boolean }>`
  ${({ $compact }) => {
    if ($compact) {
      return css`
        padding: 0 var(--spacing-large) var(--spacing-large) var(--spacing-large);
      `;
    } else {
      return css`
        padding: 0 var(--spacing-xLarge) var(--spacing-large) var(--spacing-xLarge);
      `;
    }
  }}
`;

const Gradient = styled.span`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1rem;
  background: linear-gradient(transparent, var(--palette-background-default));
  pointer-events: none;
`;

const StyledTitle = styled(Title)`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > span {
    flex: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0 !important;
  }
`;

const ErrorMessage = styled.span`
  color: var(--palette-danger-default);
`;

const AnimatedErrorMessage = animated(ErrorMessage);

const StyledDescription = styled(Title)`
  color: var(--palette-foreground-subdued);
  font-style: italic;
`;

const StylesTitleContainer = styled.span`
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  flex-direction: row;
`;

export const Accordion: FC<IAccordionProps> = ({
  title,
  description,
  children,
  error,
  blended,
  defaultOpen = true,
  toggleOpenOnHeaderClick = false,
  compact = false,
  onClick,
  onClose,
  onOpen,
  ...rest
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [isToggleOpenButtonHover, setIsToggleOpenButtonHover] = useState(false);

  const [contentRef, { height: contentHeight }] = useMeasure();

  const [isFirstRender, setIsFirstRender] = useState(true);

  // ensure that accordion is immediately open without any animation on the initial render when defaultOpen is set to true.
  const immediateAnimation = isFirstRender && defaultOpen;

  const spring = useSpring({
    height: open ? contentHeight : 0,
    immediate: immediateAnimation,
    config: {
      tension: 180
    },
    onRest: () => {
      if (isFirstRender) {
        setIsFirstRender(false);
      }
    }
  });

  const buttonSpring = useSpring({
    transform: open ? "rotateZ(180deg)" : "rotateZ(0deg)",
    display: "flex",
    config: config.stiff
  });

  const showError = !!error && !open;

  const errorSpring = useSpring({
    opacity: showError ? 1 : 0,
    config: config.stiff
  });

  const handleToggleOpen = () => {
    const isClosing = !!open;

    setOpen(!open);

    if (isClosing) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
    }
  };

  return (
    <StyledAccordion
      {...rest}
      $isToggleOpenButtonHover={isToggleOpenButtonHover}
      $clickable={!!onClick}
      $hasError={showError}
      $blended={blended}
      onClick={() => {
        open && onClick && onClick();
      }}
    >
      <AccordionHeader
        $toggleOpenOnHeaderClick={toggleOpenOnHeaderClick}
        $compact={compact}
        onClick={event => {
          if (onClick) {
            event.stopPropagation();
            onClick();
            return;
          }

          toggleOpenOnHeaderClick && handleToggleOpen();
        }}
      >
        <StyledTitle variant="bold">
          <StylesTitleContainer>
            <span>{title}</span>
            {description && <StyledDescription>{description}</StyledDescription>}
          </StylesTitleContainer>

          {showError && <AnimatedErrorMessage style={errorSpring}>{error}</AnimatedErrorMessage>}
        </StyledTitle>
        <StyledIconButton
          onClick={event => {
            event.stopPropagation();
            handleToggleOpen();
          }}
          onMouseEnter={() => setIsToggleOpenButtonHover(true)}
          onMouseLeave={() => setIsToggleOpenButtonHover(false)}
          icon={
            <animated.span style={buttonSpring}>
              <Icon name="chevronDown" />
            </animated.span>
          }
        />
      </AccordionHeader>
      <animated.div style={spring}>
        <AccordionContent ref={contentRef} $compact={compact}>
          {children}
        </AccordionContent>
      </animated.div>
      <Gradient className="bottom-gradient" />
    </StyledAccordion>
  );
};
