import React, { FC } from "react";
import styled from "styled-components";
import { SpringConfig, animated, useSpring } from "@react-spring/web";

import { IconButton } from "v2/02-blocks";
import { useResponsive } from "v2/03-hooks";

import { AppMenuItem } from "./components/AppMenuItem";
import { WorkspaceSelector } from "./components/WorkspaceSelector";
import { AppMenuProps } from "./index";
import { AppMenuInternalContext } from "./internalContext";
import { Icon } from "v2/00-assets";

const AnimatedAppMenu = styled(animated.div)`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--palette-surface-subdued);
  border-right: 1px solid var(--palette-border-subdued);
  z-index: 1;
  overflow: hidden;
`;

const Content = styled.div`
  padding-top: var(--spacing-large);
  box-sizing: border-box;

  overflow: hidden;
  :hover {
    overflow-y: auto;
  }
`;

const AnimatedGhostMenu = styled(animated.div)`
  height: 100vh;
  position: relative;
  flex-shrink: 0;
`;

const Wrapper = styled.div`
  padding: 0 1rem;
`;

const AnimatedCollapseButton = styled(animated(IconButton))`
  width: 2rem;
  height: 2rem;
  margin-top: var(--spacing-large);
  margin-left: auto;
  background-color: transparent;
  border-color: var(--palette-border-subdued);

  &:hover {
    && {
      background-color: transparent;
      border-color: var(--palette-primary-default);
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MainNavigation = styled.nav`
  margin-top: var(--spacing-xxLarge);
  display: grid;
  grid-row-gap: var(--spacing-xSmall);
  overflow: hidden;
`;

const BottomNavigation = styled(MainNavigation)`
  margin-top: auto;
  padding-bottom: var(--spacing-large);
`;

export const APP_MENU_WIDTH_PX = {
  collapsed: "76px",
  open: "216px"
};

export const APP_MENU_SPRING_CONFIG: SpringConfig = {
  tension: 240,
  clamp: true
};

export const AppMenu: FC<AppMenuProps> = ({
  organisation,
  collapsed = false,
  items,
  bottomItems,
  onToggleCollapse,
  user,
  workspaces,
  createWorkspace,
  onLogout,
  switchWorkspace,
  ...rest
}) => {
  const { isMobileLayout } = useResponsive();

  const spring = useSpring({
    width: collapsed ? APP_MENU_WIDTH_PX.collapsed : APP_MENU_WIDTH_PX.open,
    config: APP_MENU_SPRING_CONFIG
  });

  const collapseButtonSpring = useSpring({
    transform: collapsed ? "translateX(-6px)" : "translateX(0px)",
    immediate: true
  });

  const mobileSpring = useSpring({
    transform: collapsed ? "translateX(-100%)" : "translateX(0%)",
    width: "100vw"
  });

  return (
    <AppMenuInternalContext collapsed={collapsed}>
      {
        // pushes App content to right
        !isMobileLayout && <AnimatedGhostMenu style={spring} />
      }
      <AnimatedAppMenu style={isMobileLayout ? mobileSpring : spring} {...rest}>
        <Content>
          <Wrapper>
            <WorkspaceSelector
              user={user}
              organisation={organisation}
              workspaces={workspaces}
              onLogout={onLogout}
              createWorkspace={createWorkspace}
              switchWorkspace={switchWorkspace}
            />

            {!isMobileLayout && (
              <AnimatedCollapseButton
                style={collapseButtonSpring}
                icon={
                  <Icon
                    name="collapseDrawer"
                    style={{ transform: collapsed ? "rotateZ(180deg)" : undefined }}
                  />
                }
                variant="outlined"
                onClick={onToggleCollapse}
              />
            )}
          </Wrapper>
          <MainNavigation>
            {items.map((item, index) => (
              <AppMenuItem key={`app-menu-item-${index}`} {...item} />
            ))}
          </MainNavigation>

          {bottomItems && (
            <BottomNavigation>
              {bottomItems.map((bottomItem, index) => (
                <AppMenuItem key={`app-menu-bottom-item-${index}`} {...bottomItem} />
              ))}
            </BottomNavigation>
          )}
        </Content>
      </AnimatedAppMenu>
    </AppMenuInternalContext>
  );
};
