import React from "react";
import { IconTag } from "./IconTag";
import { useTheme } from "../../03-hooks";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";

export const IconTagExamples = () => {
  const { theme } = useTheme();

  return (
    <div>
      <ComponentSection title="Filled IconTag" columns={2} centerItems>
        <IconTag name="calendar" color={theme.palette.primary} />
        <IconTag name="person" color={theme.palette.danger} />
        <IconTag name="warning" color={theme.palette.highlight} />
      </ComponentSection>

      <ComponentSection
        title="Blended"
        description="IconTag can have blended variant to not show background"
        centerItems
      >
        <IconTag name="calendar" color={theme.palette.primary} variant="blended" />
        <IconTag name="warning" color={theme.palette.highlight} variant="blended" />
      </ComponentSection>

      <ComponentSection title="Filled" centerItems>
        <IconTag name="personCross" color={theme.palette.primary} variant="filled" />
        <IconTag name="personCross" color={theme.palette.danger} variant="filled" />
        <IconTag name="calendarCross" color={theme.palette.warning} variant="filled" />
        Inverted
        <IconTag name="calendarCross" inverted color={theme.palette.warning} variant="filled" />
      </ComponentSection>
      <ComponentSection title="Size" centerItems>
        <IconTag name="personCross" size="small" color={theme.palette.primary} variant="filled" />
        <IconTag name="personCross" size="medium" color={theme.palette.primary} variant="filled" />
      </ComponentSection>
    </div>
  );
};
