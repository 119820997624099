import React, { useState } from "react";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Layout } from "../../01-atoms/Layout";
import { Title } from "../../01-atoms/Typography";

import { Button } from ".";

const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export const ButtonExamples = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <button onClick={() => setLoading(!loading)}> loading</button>
      <ComponentSection title="Filled">
        <Layout.Group vertical gap="xLarge">
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Default</Title>
            <Layout.Group>
              <Button size="small">Label</Button>
              <Button size="small" startIcon="plusSmall">
                Label
              </Button>
              <Button size="medium">Label</Button>
              <Button disabled size="medium">
                Label
              </Button>
              <Button loading={loading} startIcon="person" size="medium">
                Label
              </Button>
              <Button endIcon="person" size="medium">
                Label
              </Button>
              <Button size="large" onClickWithLoading={() => wait(1000)}>
                Label
              </Button>
            </Layout.Group>
          </Layout.Group>
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Destructive</Title>
            <Layout.Group>
              <Button destructive size="small">
                Label
              </Button>
              <Button destructive startIcon="trash" size="small">
                Label
              </Button>
              <Button loading={loading} destructive size="medium">
                Label
              </Button>
              <Button destructive startIcon="trash" size="medium">
                Label
              </Button>
              <Button destructive endIcon="trash" size="medium">
                Label
              </Button>
              <Button destructive disabled size="medium">
                Label
              </Button>
              <Button destructive size="large">
                Label
              </Button>
            </Layout.Group>
          </Layout.Group>
        </Layout.Group>
      </ComponentSection>
      <ComponentSection title="Outlined">
        <Layout.Group vertical gap="xLarge">
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Default</Title>
            <Layout.Group>
              <Button variant="outlined" size="small">
                Label
              </Button>
              <Button startIcon="plusSmall" variant="outlined" size="small">
                Label
              </Button>
              <Button loading={loading} variant="outlined" size="medium">
                Label
              </Button>
              <Button startIcon="lock" variant="outlined" size="medium">
                Label
              </Button>
              <Button endIcon="lock" variant="outlined" size="medium">
                Label
              </Button>
              <Button disabled variant="outlined" size="medium">
                Label
              </Button>
              <Button variant="outlined" size="large">
                Label
              </Button>
            </Layout.Group>
          </Layout.Group>
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Destuctive</Title>
            <Layout.Group>
              <Button destructive variant="outlined" size="small">
                Label
              </Button>
              <Button destructive startIcon="trash" variant="outlined" size="small">
                Label
              </Button>
              <Button loading={loading} destructive variant="outlined" size="medium">
                Label
              </Button>
              <Button destructive startIcon="trash" variant="outlined" size="medium">
                Label
              </Button>
              <Button destructive endIcon="trash" variant="outlined" size="medium">
                Label
              </Button>
              <Button destructive disabled variant="outlined" size="medium">
                Label
              </Button>
              <Button destructive variant="outlined" size="large">
                Label
              </Button>
            </Layout.Group>
          </Layout.Group>
        </Layout.Group>
      </ComponentSection>
      <ComponentSection title="Blended">
        <Layout.Group vertical gap="xLarge">
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Default</Title>
            <Layout.Group>
              <Button variant="blended" size="small">
                Label
              </Button>
              <Button startIcon="arrowLeft" variant="blended" size="small">
                Label
              </Button>
              <Button loading={loading} variant="blended" size="medium">
                Label
              </Button>
              <Button startIcon="arrowLeft" variant="blended" size="medium">
                Label
              </Button>
              <Button endIcon="arrowRight" variant="blended" size="medium">
                Label
              </Button>
              <Button disabled variant="blended" size="medium">
                Label
              </Button>
              <Button variant="blended" size="large">
                Label
              </Button>
            </Layout.Group>
          </Layout.Group>
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Destructive</Title>
            <Layout.Group>
              <Button destructive variant="blended" size="small">
                Label
              </Button>
              <Button destructive startIcon="trash" variant="blended" size="small">
                Label
              </Button>
              <Button destructive variant="blended" size="medium">
                Label
              </Button>
              <Button destructive startIcon="trash" variant="blended" size="medium">
                Label
              </Button>
              <Button destructive endIcon="trash" variant="blended" size="medium">
                Label
              </Button>
              <Button destructive disabled variant="blended" size="medium">
                Label
              </Button>
              <Button destructive variant="blended" size="large">
                Label
              </Button>
            </Layout.Group>
          </Layout.Group>
        </Layout.Group>
      </ComponentSection>
    </div>
  );
};
