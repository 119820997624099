import { styled } from "../../styled";
import cx from "classnames";
import React, { ComponentProps } from "react";

import styles from "./Caps2.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface Caps2Props extends ComponentProps<"p"> {
  size?: "regular" | "semibold";
  inverted?: boolean;
}

export const Caps2 = ({ size = "regular", inverted = false, ...props }: Caps2Props) => {
  return styled(
    "p",
    props,
    styles.Caps2,
    styles[size],
    typographyStyles.Typography,
    cx({
      [typographyStyles.inverted]: inverted
    })
  );
};
