import { useEffect, useState } from "react";
import constate from "constate";

import { useResponsive } from "../../03-hooks";

type TAppMenuContextArgs = {
  localStorageKey?: string;
};

export const [AppMenuContextProvider, useAppMenuContext] = constate(
  ({ localStorageKey }: TAppMenuContextArgs) => {
    const { isMobileLayout } = useResponsive();

    const localStorageCollapsed = localStorageKey
      ? localStorage.getItem(localStorageKey) === "true"
      : undefined;

    const [collapsed, setCollapsed] = useState(isMobileLayout ? true : localStorageCollapsed);

    const toggleMenuCollapsed = () => {
      const nextCollapsed = !collapsed;
      setCollapsed(nextCollapsed);
      localStorageKey && localStorage.setItem(localStorageKey, nextCollapsed.toString());
    };

    useEffect(() => {
      if (!isMobileLayout) {
        setCollapsed(localStorageCollapsed);
      } else setCollapsed(true);
    }, [isMobileLayout]);

    return {
      collapsed,
      setCollapsed,
      toggleMenuCollapsed
    };
  }
);
