import React from "react";
import { ThemeProviderExamples } from "./04-providers/ThemeProvider/examples";
import { LoaderExamples } from "./01-atoms/Loader/examples";
import { IconsExample } from "./00-assets/Icons/example";
import { TypographyExample } from "./01-atoms/Typography/example";
import { CardExamples } from "./01-atoms/Card/examples";
import { InputsExample } from "./01-atoms/Input/examples";
import { InputV2Examples } from "./01-atoms/InputV2/examples";
import { ButtonExamples } from "./01-atoms/Button/examples";
import { ActionProvider, ThemeProvider } from "./04-providers";
import { OptionListItemExamples } from "./01-atoms/OptionListItem/examples";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router";
import { ComponentExamplesRoute, DirectoryComponent } from "./09-routes";
import { UseFormExamples } from "./03-hooks/useForm/examples";
import { UseResponsiveExamples } from "./03-hooks/useResponsive/examples";
import { ModalExamples } from "./02-blocks/Modal/examples";
import { ModalV2Example } from "./02-blocks/ModalV2/example";
import { DrawerExamples } from "./05-templates/Drawer/examples";
import { BackdropExamples } from "./00-assets/Backdrop/examples";
import { IndicatorExamples } from "./01-atoms/Indicator/examples";
import { OptionListExamples } from "./02-blocks/OptionList/examples";
import { UseOutsideClickExamples } from "./03-hooks/useOutsideClick/examples";
import { PopoverExamples } from "./01-atoms/Popover/examples";
import { TooltipExamples } from "./01-atoms/Tooltip/examples";
import { SelectExamples } from "./02-blocks/Select/examples";
import { AppMenuExamples } from "./06-views/AppMenu/examples";
import { IconTagExamples } from "./01-atoms/IconTag/examples";
import { TopBarExamples } from "./05-templates/TopBar/examples";
import { DatePickerExamples } from "./01-atoms/DatePicker/examples";
import { DateInputExamples } from "./02-blocks/DateInput/examples";
import { DateRangeSelectExamples } from "./02-blocks/DateRangeSelect/examples";
import { ConfirmationModalExamples } from "./05-templates/ConfirmationModal/examples";
import { ToastExamples } from "./02-blocks/Toast/examples";
import { IconButtonExamples } from "./02-blocks/IconButton/examples";
import { TimePickerExamples } from "./02-blocks/TimePicker/examples";
import { TagExamples } from "./01-atoms/Tag/examples";
import { ActionMenuExamples } from "./02-blocks/ActionMenu/examples";
import { ToastProvider } from "./04-providers";
import { UseToastExamples } from "./03-hooks/useToast/examples";
import { UseActionsExamples } from "./03-hooks/useActions/examples";
import { FieldExamples } from "./02-blocks/Field/examples";
import { CollapsibleNavExamples } from "./05-templates/CollapsibleNav/examples";
import { ComponentNav } from "./09-routes/ComponentDirectoryRoute/ComponentNav";
import orderBy from "lodash.orderby";
import { TextAreaExamples } from "./01-atoms/TextArea/examples";
import { ExamplesHome } from "./ExamplesHome";
import { CheckboxExamples } from "./01-atoms/Checkbox/examples";
import { InfoCardExample } from "./01-atoms/InfoCard/example";
import { ToggleExamples } from "./01-atoms/Toggle/examples";
import { StackExamples } from "./01-atoms/Stack/examples";
import { ColorsExamples } from "./00-assets/Colors/examples";
import { CollapsibleContentExamples } from "./01-atoms/CollapsibleContent/example";
import { TableExamples } from "./02-blocks/Table/examples";
import { UseSortableExamples } from "./03-hooks/useSortable/examples";
import { TitleExamples } from "./01-atoms/Typography/Title/examples";
import { ButtonGroupExamples } from "./05-templates/ButtonGroup/examples";
import { BreadcrumbExamples } from "./02-blocks/Breadcrumb/examples";
import { SplitButtonExamples } from "./02-blocks/SplitButton/examples";
import { AccordionExamples } from "./02-blocks/Accordion/examples";
import { SkeletonLoaderExamples } from "./01-atoms/SkeletonLoader/examples";

const examples: DirectoryComponent[] = [
  { name: "Icons", examples: IconsExample, category: "assets" },
  { name: "SkeletonLoader", examples: SkeletonLoaderExamples, category: "atoms" },
  { name: "Stack", examples: StackExamples, category: "atoms" },
  { name: "Backdrop", examples: BackdropExamples, category: "assets" },
  { name: "Colors", examples: ColorsExamples, category: "assets" },
  { name: "Loader", examples: LoaderExamples, category: "atoms" },
  { name: "Typography", examples: TypographyExample, category: "atoms" },
  { name: "InfoCard", examples: InfoCardExample, category: "atoms" },
  { name: "IconTag", examples: IconTagExamples, category: "atoms" },
  { name: "Card", examples: CardExamples, category: "atoms" },
  { name: "Input", examples: InputsExample, category: "atoms" },
  { name: "InputV2", examples: InputV2Examples, category: "atoms" },
  { name: "Button", examples: ButtonExamples, category: "atoms" },
  { name: "OptionListItem", examples: OptionListItemExamples, category: "atoms" },
  { name: "Indicator", examples: IndicatorExamples, category: "atoms" },
  { name: "Checkbox", examples: CheckboxExamples, category: "atoms" },
  { name: "Toggle", examples: ToggleExamples, category: "atoms" },
  { name: "Popover", examples: PopoverExamples, category: "atoms" },
  { name: "Tooltip", examples: TooltipExamples, category: "atoms" },
  { name: "DatePicker", examples: DatePickerExamples, category: "atoms" },
  { name: "Tag", examples: TagExamples, category: "atoms" },
  { name: "Title", examples: TitleExamples, category: "atoms" },
  { name: "ActionMenu", examples: ActionMenuExamples, category: "blocks" },
  { name: "DateInput", examples: DateInputExamples, category: "blocks" },
  { name: "DateRangeSelect", examples: DateRangeSelectExamples, category: "blocks" },
  { name: "IconButton", examples: IconButtonExamples, category: "blocks" },
  { name: "TimePicker", examples: TimePickerExamples, category: "blocks" },
  { name: "Table", examples: TableExamples, category: "blocks" },
  { name: "Field", examples: FieldExamples, category: "blocks" },
  { name: "Toast", examples: ToastExamples, category: "blocks" },
  { name: "Select", examples: SelectExamples, category: "blocks" },
  { name: "OptionList", examples: OptionListExamples, category: "blocks" },
  { name: "Modal", examples: ModalExamples, category: "blocks" },
  { name: "ModalV2", examples: ModalV2Example, category: "blocks" },
  { name: "useForm", examples: UseFormExamples, category: "hooks" },
  { name: "useResponsive", examples: UseResponsiveExamples, category: "hooks" },
  { name: "UseToast", examples: UseToastExamples, category: "hooks" },
  { name: "UseAction", examples: UseActionsExamples, category: "hooks" },
  { name: "useSortable", examples: UseSortableExamples, category: "hooks" },
  { name: "useOutsideClick", examples: UseOutsideClickExamples, category: "hooks" },
  { name: "ThemeProvider", examples: ThemeProviderExamples, category: "providers" },
  { name: "ButtonGroup", examples: ButtonGroupExamples, category: "templates" },
  { name: "CollapsibleNav", examples: CollapsibleNavExamples, category: "templates" },
  { name: "ConfirmationModal", examples: ConfirmationModalExamples, category: "templates" },
  { name: "TopBar", examples: TopBarExamples, category: "templates", noViewWrap: true },
  { name: "Drawer", examples: DrawerExamples, category: "views" },
  { name: "AppMenu", examples: AppMenuExamples, category: "views", noViewWrap: true },
  { name: "TextArea", examples: TextAreaExamples, category: "atoms" },
  { name: "CollapsibleContent", examples: CollapsibleContentExamples, category: "atoms" },
  { name: "Breadcrumb", examples: BreadcrumbExamples, category: "blocks" },
  { name: "SplitButton", examples: SplitButtonExamples, category: "blocks" },
  { name: "Accordion", examples: AccordionExamples, category: "blocks" }
];

export const ExamplesRouter = ({ base }: { base: string }) => {
  return (
    <ThemeProvider>
      <ToastProvider defaultPlacement="top-right">
        <ActionProvider defaultErrorMessage="Something went wrong">
          <Router basename={base}>
            <Route
              render={({ history }) => {
                return (
                  <ComponentNav
                    components={orderBy(examples, ["category", "name"])}
                    onLogoClick={() => (window.location.href = "/")}
                    selectedKey={history.location.pathname.substr(1)}
                  />
                );
              }}
            />
            <Route exact path="/" component={ExamplesHome} />
            {examples.map(c => (
              <Route
                key={c.name}
                exact={c.name === "AppMenu" ? false : true}
                path={`/${c.name}`}
                render={() => (
                  <ComponentExamplesRoute
                    disableContainerStyles={c.name === "AppMenu"}
                    component={c}
                    components={examples}
                  />
                )}
              />
            ))}
          </Router>
        </ActionProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};
