import React from "react";

import { DayPickerRangeController } from "react-dates";
import moment from "moment";
import cx from "classnames";
import styles from "./RangeCalendar.module.scss";

import { Icon } from "../../..";
import { Dates, Focus } from "..";
import { Title } from "../../../01-atoms";

export interface MomentDates {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

interface Props {
  focus: Focus;
  onFocusChange: (focus: Focus) => void;
  dates: MomentDates;
  onChangeDates: (dates: MomentDates) => void;
}

export const RangeCalendar: React.VFC<Props> = ({ focus, onFocusChange, onChangeDates, dates }) => {
  return (
    <DayPickerRangeController
      enableOutsideDays
      initialVisibleMonth={() => dates.startDate ?? moment()}
      numberOfMonths={1}
      renderWeekHeaderElement={day => <Title>{day}</Title>}
      hideKeyboardShortcutsPanel
      startDate={dates.startDate}
      endDate={dates.endDate}
      onDatesChange={onChangeDates}
      focusedInput={focus}
      onFocusChange={onFocusChange}
      renderNavNextButton={({ onClick }) => (
        <div onClick={onClick} className={cx(styles.Nav, styles.Next)}>
          <Icon name="chevronRight" />
        </div>
      )}
      renderNavPrevButton={({ onClick }) => (
        <div onClick={onClick} className={cx(styles.Nav, styles.Prev)}>
          <Icon name="chevronLeft" />
        </div>
      )}
      renderCalendarDay={({ day, modifiers, onDayClick, onDayMouseEnter, onDayMouseLeave }) => {
        return (
          onDayMouseEnter &&
          onDayMouseLeave &&
          onDayClick &&
          day && (
            <td
              className={styles.DayContainer}
              onMouseEnter={e => onDayMouseEnter(day, e)}
              onMouseLeave={e => onDayMouseLeave(day, e)}
              onClick={e => onDayClick(day, e)}
            >
              <div
                className={cx({
                  [styles.Day]: day,
                  [styles.HoveredSpan]: modifiers?.has("hovered-span"),
                  [styles.Hovered]: modifiers?.has("hovered"),
                  [styles.SelectedStart]: modifiers?.has("selected-start"),
                  [styles.SelectedEnd]: modifiers?.has("selected-end"),
                  [styles.SelectedSpan]: modifiers?.has("selected-span"),
                  [styles.Selected]: modifiers?.has("selected"),
                  [styles.SelectedStartInHoveredSpan]: modifiers?.has(
                    "selected-start-in-hovered-span"
                  )
                })}
              >
                <Title className={styles.DayTitle}>{day?.format("D")}</Title>
              </div>
            </td>
          )
        );
      }}
    />
  );
};
