import React, { FC } from "react";
import styled from "styled-components";
import { Icon, IconName } from "../../../00-assets";
import { BaseTag } from "../BaseTag";
import { useTheme } from "../../../03-hooks";

type TFilter = {
  filterName: string;
  filterIcon?: IconName;
  method: string;
  value?: string[];
};

interface ITagFilterProps {
  filter: TFilter;
  onRemove?: VoidFunction;
}

const StyledTagFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`;

const NameTag = styled(BaseTag)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const ValueTag = styled(BaseTag)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const MethodTag = styled(BaseTag)`
  border-radius: 0;
`;

export const TagFilter: FC<ITagFilterProps> = ({ filter, onRemove, ...rest }) => {
  const { theme } = useTheme();

  return (
    <StyledTagFilter {...rest}>
      <NameTag
        startAdornment={filter.filterIcon ? <Icon name={filter.filterIcon} /> : null}
        foregroundColor={theme.palette.foreground.default}
        backgroundColor={theme.palette.surface.default}
      >
        {filter.filterName}
      </NameTag>
      <MethodTag>{filter.method}</MethodTag>
      <ValueTag
        foregroundColor={theme.palette.foreground.default}
        backgroundColor={theme.palette.surface.default}
        onRemove={onRemove}
      >
        {filter.value}
      </ValueTag>
    </StyledTagFilter>
  );
};
