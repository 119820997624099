import React from "react";
import { ComponentVariant } from "../../01-atoms/";
import { H4, Title } from "../../01-atoms/Typography";
import { useTheme } from "../../03-hooks";
import { IconButton } from ".";

export const IconButtonExamples = () => {
  const { theme } = useTheme();
  return (
    <div>
      <div style={{ marginBottom: theme.spacing.xLarge }}>
        <Title>With default config</Title>
        <div
          style={{
            display: "flex",
            marginTop: theme.spacing.large,
            gap: theme.spacing.medium,
            alignItems: "center"
          }}
        >
          <IconButton icon="crossSmall" tooltip="Close" />
        </div>
      </div>

      <ButtonVariantGrid variant="filled" />
      <ButtonVariantGrid variant="outlined" />
      <ButtonVariantGrid variant="blended" />
    </div>
  );
};

const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

const ButtonVariantGrid = ({ variant }: { variant: ComponentVariant }) => {
  const { theme } = useTheme();

  return (
    <div style={{ marginBottom: theme.spacing.xLarge }}>
      <H4>{`Variant: ${variant}`}</H4>
      <div
        style={{
          display: "flex",
          marginTop: theme.spacing.large,
          gap: theme.spacing.medium,
          alignItems: "center"
        }}
      >
        <IconButton
          icon="more"
          variant={variant}
          size="small"
          dimmed
          onClickWithLoading={() => wait(1000)}
        />
        <IconButton
          icon="more"
          variant={variant}
          size="medium"
          onClickWithLoading={() => wait(1000)}
        />
        <IconButton
          icon="more"
          variant={variant}
          size="large"
          onClickWithLoading={() => wait(1000)}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: theme.spacing.large,
          gap: theme.spacing.medium,
          alignItems: "center"
        }}
      >
        <IconButton
          icon="door"
          variant={variant}
          size="small"
          onClickWithLoading={() => wait(1000)}
          loading
        />
        <IconButton
          icon="door"
          variant={variant}
          size="medium"
          onClickWithLoading={() => wait(1000)}
          loading
        />
        <IconButton
          icon="door"
          variant={variant}
          size="large"
          onClickWithLoading={() => wait(1000)}
          loading
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: theme.spacing.large,
          gap: theme.spacing.medium,
          alignItems: "center"
        }}
      >
        <IconButton icon="crossSmall" variant={variant} size="small" onClick={() => {}} />
        <IconButton icon="crossSmall" variant={variant} size="medium" onClick={() => {}} />
        <IconButton icon="crossSmall" variant={variant} size="large" onClick={() => {}} />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: theme.spacing.large,
          gap: theme.spacing.medium,
          alignItems: "center"
        }}
      >
        <IconButton
          icon="door"
          variant={variant}
          size="small"
          onClickWithLoading={() => wait(1000)}
          destructive
          loading
        />
        <IconButton
          icon="door"
          variant={variant}
          size="medium"
          onClickWithLoading={() => wait(1000)}
          destructive
          loading
        />
        <IconButton
          icon="door"
          variant={variant}
          size="large"
          onClickWithLoading={() => wait(1000)}
          destructive
          loading
        />
      </div>

      <Title style={{ margin: `${theme.spacing.large} 0` }}>Disabled</Title>
      <div
        style={{
          display: "flex",
          marginTop: theme.spacing.small,
          gap: theme.spacing.medium,
          alignItems: "center"
        }}
      >
        <IconButton
          icon="door"
          variant={variant}
          size="small"
          onClickWithLoading={() => wait(1000)}
          disabled
        />
        <IconButton
          icon="door"
          variant={variant}
          size="medium"
          onClickWithLoading={() => wait(1000)}
          disabled
        />
        <IconButton
          icon="door"
          variant={variant}
          size="large"
          onClickWithLoading={() => wait(1000)}
          disabled
        />
      </div>
    </div>
  );
};
