import React, { useState } from "react";
import { Button } from "../Button";
import { Popover, IPopoverProps } from "./Popover";
import { Title } from "../Typography";
import { Card } from "..";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Select, TimeRange } from "../../02-blocks";
import { useToast } from "../../03-hooks";

interface ExampleProps extends Omit<IPopoverProps, "referenceElement" | "open" | "onClose"> {
  buttonTitle?: string;
}

const PopoverExample = ({ buttonTitle, children, ...rest }: ExampleProps) => {
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const defaultContent = (
    <Card style={{ padding: "1rem" }}>
      <Title>This is some content </Title>
    </Card>
  );
  return (
    <div ref={setRefElement}>
      <Button onClick={() => setOpen(true)} style={{ width: "100%" }}>
        {buttonTitle || "Open popover"}
      </Button>
      <Popover referenceElement={refElement} open={open} onClose={() => setOpen(false)} {...rest}>
        {children || defaultContent}
      </Popover>
    </div>
  );
};

export const PopoverExamples = () => {
  const toast = useToast();

  return (
    <div style={{ display: "grid", gap: "1rem" }}>
      <ComponentSection
        title="Basic popover"
        description="Examples of basic usage of popover"
        columns={1}
      >
        <PopoverExample buttonTitle="No close on content click" closeOnContentClick={false} />
        <PopoverExample
          buttonTitle="Attached to ref popover"
          attachToRef
          closeOnContentClick={false}
        />
        <PopoverExample buttonTitle="Select in Popover" attachToRef closeOnContentClick={false}>
          <Card>
            <Select
              placeholder="In popover"
              onSelect={() => toast.info("Selected")}
              options={[{ key: "opt1" }, { key: "opt2" }]}
            />
          </Card>
        </PopoverExample>
        <PopoverExample buttonTitle="Parent width" sameWidthWithReference />
      </ComponentSection>
      <ComponentSection
        title="Animations"
        description="Here is examples of every placement with animations"
        columns={1}
      >
        <PopoverExample buttonTitle="Bottom start (default)" placement="bottom-start" />
        <PopoverExample buttonTitle="Left start" placement="left-start" />
        <PopoverExample buttonTitle="Left" placement="left" />
        <PopoverExample buttonTitle="Right" placement="right" />
        <PopoverExample buttonTitle="Top" placement="top" />
        <PopoverExample buttonTitle="Bottom" placement="bottom" />
      </ComponentSection>
    </div>
  );
};
