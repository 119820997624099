import { useEffect, useCallback } from "react";

export const useOutsideClick = (
  ignoredElems: Array<HTMLElement | null>,
  callback: () => unknown
) => {
  const handleClick = useCallback(
    e => {
      const isIgnoredElem = ignoredElems.map(elem => elem && elem.contains(e.target));
      if (!isIgnoredElem.includes(true)) {
        callback();
      }
    },
    [ignoredElems, callback]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
};
