import React from "react";

import { useResponsive } from "./useResponsive";
import { H3, Title } from "../../01-atoms";

export const UseResponsiveExamples = () => {
  const { isDesktopLayout, isMobileLayout } = useResponsive();

  return (
    <div>
      {isDesktopLayout && <H3>Desktop Layout!</H3>}
      {isMobileLayout && <H3>Mobile Layout!</H3>}
      <Title>Change browser window width to detect changes</Title>
    </div>
  );
};
