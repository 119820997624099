import React from "react";
import { Card } from "./Card";
import { useTheme } from "../../03-hooks";
import { Title } from "../Typography";

export const CardExamples = () => {
  const { theme } = useTheme();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: theme.spacing.small }}>
      <Card variant="default">
        <Title>Default card</Title>
      </Card>

      <Card variant="active">
        <Title>Active card</Title>
      </Card>
      <Card variant="blended">
        <Title>Blended card</Title>
      </Card>

      <Card variant="default" mode="dark">
        Dark card
      </Card>

      <Card
        variant="default"
        hoverable
        style={{ marginTop: theme.spacing.large }}
        onClick={() => {
          alert("Clicked");
        }}
      >
        <Title>Hoverable clickable</Title>
      </Card>
      <Card variant="active" hoverable>
        <Title>Hoverable active</Title>
      </Card>
      <Card variant="blended" hoverable>
        <Title>Hoverable blended</Title>
      </Card>

      <Card variant="default" elevated style={{ marginTop: theme.spacing.large }}>
        <Title>Elevated default</Title>
      </Card>
      <Card variant="active" elevated>
        <Title>Elevated active</Title>
      </Card>
      <Card variant="blended" elevated>
        <Title>Elevated blended</Title>
      </Card>

      <Title>Spacing</Title>
      <Card spacing="medium">
        <Title>Spacing medium (default)</Title>
      </Card>
      <Card spacing="large">
        <Title>Spacing large</Title>
      </Card>
      <Card spacing="xLarge">
        <Title>Spacing xLarge</Title>
      </Card>
    </div>
  );
};
