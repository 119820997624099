import cx from "classnames";
import React, {
  ChangeEvent,
  ComponentProps,
  CSSProperties,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { ComponentVariant } from "..";
import { useTheme } from "../../03-hooks";

import styles from "./Input.module.scss";

export interface InputProps extends Omit<ComponentProps<"input">, "onChange" | "ref" | "size"> {
  error?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  onChange?: (value: string, event: ChangeEvent) => void;
  inputStyles?: CSSProperties;
  blurOnEnter?: boolean;
  readonly?: boolean;
  size?: "small" | "medium" | "large";
  active?: boolean;
  variant?: ComponentVariant;
  scrollToInputOnAutoFocus?: boolean;
  disableHover?: boolean;
}

export type InputHandle = {
  insertTextToCursorPosition: (text: string) => void;
  hasFocus: () => boolean;
  input: HTMLInputElement | null;
  blur: () => void;
  focus: () => void;
};

const insertToPositionWithoutLosingFocus = (el: HTMLInputElement, text: string, start: number) => {
  el.setRangeText(text, start, start);
  el.focus();
  el.setSelectionRange(start + text.length, start + text.length);
  el.dispatchEvent(new Event("input", { bubbles: true }));
};

export const Input = React.forwardRef<InputHandle, InputProps>(
  (
    {
      error,
      onChange,
      className,
      style,
      startAdornment,
      endAdornment,
      disabled,
      inputStyles,
      blurOnEnter,
      type,
      readonly,
      size = "medium",
      active,
      variant = "outlined",
      autoFocus,
      scrollToInputOnAutoFocus = true,
      disableHover = false,
      ...restProps
    }: InputProps,
    ref
  ) => {
    const { theme } = useTheme();
    const [focus, setFocus] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        input: inputRef.current,
        hasFocus: () => document.getSelection()?.focusNode === wrapperRef.current,
        insertTextToCursorPosition: (text: string) => {
          const selectionStart = inputRef.current?.selectionStart;
          if (selectionStart === null || selectionStart === undefined) return;
          insertToPositionWithoutLosingFocus(inputRef.current!, text, selectionStart);
        },
        blur: () => inputRef.current?.blur(),
        focus: () => inputRef.current?.focus()
      }),
      [inputRef]
    );

    useEffect(() => {
      if (autoFocus) inputRef.current?.focus({ preventScroll: !scrollToInputOnAutoFocus });
    }, [autoFocus, scrollToInputOnAutoFocus]);

    const eventHandlers = {
      onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
        setFocus(true);
        restProps.onFocus && restProps.onFocus(e);
      },
      onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
        setFocus(false);
        restProps.onBlur && restProps.onBlur(e);
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e.target.value, e);
      }
    };

    const stateStyles = {
      [styles.Disabled]: disabled,
      [styles.Readonly]: readonly,
      [styles.Focus]: focus,
      [styles.Error]: error,
      [styles.Active]: active !== undefined ? active : focus // Allows overriding focus by giving active = false
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (blurOnEnter && e.key === "Enter") inputRef.current?.blur();
    };

    return (
      <div
        ref={wrapperRef}
        className={cx(styles.Wrapper, stateStyles, styles[variant], {
          [styles.DisableHover]: disableHover
        })}
        onClick={() => {
          if (!readonly) inputRef.current && inputRef.current.focus();
        }}
        style={{
          paddingLeft: theme.spacing.large,
          paddingRight: theme.spacing.large,
          ...style
        }}
      >
        {!readonly && startAdornment}
        <input
          ref={inputRef}
          onKeyDown={handleKeydown}
          disabled={disabled || readonly}
          className={cx(styles.Input, className, stateStyles, styles[size], styles[variant])}
          style={{
            paddingLeft: startAdornment && !readonly ? theme.spacing.small : 0,
            paddingRight: endAdornment && !readonly ? theme.spacing.small : 0,
            ...inputStyles
          }}
          {...restProps}
          {...eventHandlers}
          type={type}
        />
        {!readonly && endAdornment}
      </div>
    );
  }
);
