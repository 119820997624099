import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router";

import { Button, H1, Title } from "../../01-atoms";
import { useTheme } from "../../03-hooks";
import { SidebarAppLayout } from "../../05-templates";

import { AppMenu, AppMenuContextProvider, AppMenuItem } from ".";
import styled from "styled-components";
import { Icon } from "v2/00-assets";
import { ModalV2 } from "v2/02-blocks";

const StyledAppMenuExample = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 1rem;
`;

export const AppMenuExamples = () => {
  const history = useHistory();
  const { theme } = useTheme();

  const [collapsed, setCollapsed] = useState(false);

  const items: AppMenuItem[] = [
    {
      title: "Journeys",
      icon: <Icon name="map" />,
      path: "/AppMenu/journeys",
      onClick: (e) => {
        if (e.ctrlKey || e.metaKey) {
          window.open("/AppMenu/journeys")
        }
        else history.push("/AppMenu/journeys")
      },
      divider: true
    },
    {
      title: "Employees",
      icon: <Icon name="persons" />,
      path: "/AppMenu/employees",
      divider: true,
      onClick: () => history.push("/AppMenu/employees")
    },
    {
      title: "Moments",
      category: "Automations",
      icon: <Icon name="trigger" />,
      onClick: (e) => {
        if (e.ctrlKey || e.metaKey) {
          window.open("/AppMenu/moments")
        }
        history.push("/AppMenu/moments")
      },
      path: "/AppMenu/moments"
    },
    {
      title: "Routines",
      path: "/AppMenu/routines",
      divider: true,
      icon: <Icon name="featureRoutines" />,
      onClick: () => history.push("/AppMenu/routines")
    },
    {
      title: "Materials",
      icon: <Icon name="book" />,
      path: "/AppMenu/materials",
      onClick: () => history.push("/AppMenu/materials"),
      divider: true
    },
    {
      title: "Settings",
      icon: <Icon name="settings" />,
      closable: true,
      subItems: [
        {
          title: "Workspace settings",
          path: "/AppMenu/settings/workspace",
          onClick: () => history.push("/AppMenu/settings/workspace")
        },
        {
          title: "Linked accounts",
          path: "/AppMenu/settings/accounts/*",
          onClick: () => history.push("/AppMenu/settings/accounts/google")
        },
        {
          title: "Integrations",
          isAlpha: true,
          path: "/AppMenu/settings/integrations",
          onClick: () => history.push("/AppMenu/settings/integrations/hris")
        },
        {
          title: "Notifications",
          isNew: true,
          path: "/AppMenu/settings/notifications",
          onClick: () => history.push("/AppMenu/settings/notifications")
        }
      ]
    },
    {
      title: "Notifications",
      icon: <Icon name="notification" />,
      path: "/AppMenu#notifications",
      onClick: () => history.push("/AppMenu#notifications")
    }
  ];

  const bottomItems: AppMenuItem[] = [
    {
      title: "Help & Support",
      icon: <Icon name="bulb" />,
      onClick: () => alert("Get some help!")
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <StyledAppMenuExample>
        <AppMenuContextProvider localStorageKey="">
          <SidebarAppLayout
            appMenuCollapsed={collapsed}
            appMenu={
              <AppMenu
                user={{
                  email: "theophilina.hernandez@megacorporation.com"
                }}
                workspaces={[
                  { id: "anothermegacorp", name: "Another Mega Corporation" },
                  { id: "smallcorp", name: "SmallCorp" }
                ]}
                onLogout={() => alert("logout")}
                items={items}
                bottomItems={bottomItems}
                onToggleCollapse={() => setCollapsed(!collapsed)}
                collapsed={collapsed}
                organisation={{
                  logo: (
                    <Title
                      style={{
                        background: theme.palette.primary.default,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white"
                      }}
                      variant="bold"
                    >
                      A
                    </Title>
                  ),
                  name: "Acme & Co"
                }}
              />
            }
          >
            <div
              style={{
                padding: "3rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: theme.spacing.medium
              }}
            >
              <Switch>
                <Route path="/AppMenu/journeys" component={JourneysComponent} />
                <Route path="/AppMenu/moments" component={MomentsComponent} />
                <Route path="/AppMenu/routines" component={RoutinesComponent} />
                <Route path="/AppMenu/employees" component={EmployeesComponent} />
                <Route path="/AppMenu/materials" component={MaterialsComponent} />
                <Route path="/AppMenu/settings/workspace" component={SettingsWorkspace} />
                <Route path="/AppMenu/settings/accounts" component={SettingsAccounts} />
                <Route path="/AppMenu/settings/integrations" component={SettingsIntegrations} />
                <Route path="/AppMenu/settings/attributes" component={SettingsAttributes} />
              </Switch>

              <Button variant="outlined" onClick={() => history.push("/")}>
                Go Back
              </Button>
            </div>
          </SidebarAppLayout>
        </AppMenuContextProvider>
      </StyledAppMenuExample>
    </div>
  );
};

const JourneysComponent = () => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <H1>Journeys!</H1>
      <Button onClick={() => setOpen(true)}>open</Button>
      <ModalV2 open={open} onClose={() => setOpen(false)} />
    </>
  );
};
const EmployeesComponent = () => <H1>Employees!</H1>;
const MomentsComponent = () => <H1>Moments!</H1>;
const RoutinesComponent = () => <H1>Routines!</H1>;
const MaterialsComponent = () => <H1>Materials!</H1>;
const SettingsWorkspace = () => <H1>Settings/Workspace</H1>;
const SettingsAccounts = () => <H1>Settings/Linked accounts</H1>;
const SettingsIntegrations = () => <H1>Settings/Integrations</H1>;
const SettingsAttributes = () => <H1>Settings/Attributes</H1>;
