import React, { useEffect } from "react";
import styled from "styled-components";

import { InputV2 } from "../InputV2";
import { useDebouncedSearch } from "../../03-hooks/useDebouncedSearch";

type Props = {
  placeholder?: string;
  value?: string;
  onChange: (search?: string) => void;
  debounceMs?: number;
  maxWidth?: number;
};

const StyledSearchInput = styled(InputV2)<{ $maxWidth: number }>`
  height: 30px;
  max-width: ${p => p.$maxWidth}rem;
`;

export const ListSearchInput = ({
  value,
  placeholder = "Search",
  onChange,
  debounceMs,
  maxWidth = 14
}: Props) => {
  // Use the hook with initialSearch and a callback function
  const { query, inputValue, handleInputChange } = useDebouncedSearch({
    initialSearch: value || "",
    debounceMs
  });

  useEffect(() => {
    onChange(query);
  }, [query, onChange]);

  return (
    <StyledSearchInput
      endIcon="search"
      placeholder={placeholder}
      value={inputValue}
      onChange={e => handleInputChange(e.target.value)}
      $maxWidth={maxWidth}
    />
  );
};
