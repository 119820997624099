import React from "react";

import { SkeletonLoader, SkeletonLoaderProps } from ".";
import { Title } from "../Typography";
import { Stack } from "../Stack";
type Props = {};

const Example = ({
  title,
  ...props
}: SkeletonLoaderProps & {
  title: string;
}) => {
  return (
    <Stack vertical gap="xSmall">
      <Title>{title}</Title>
      <SkeletonLoader {...props} />
    </Stack>
  );
};

export const SkeletonLoaderExamples = ({ ...rest }: Props) => {
  return (
    <Stack vertical {...rest}>
      <Stack>
        <Example title="Button Small" element="buttonSmall" />
        <Example title="Button" element="button" />
        <Example title="Button Large" element="buttonLarge" />
      </Stack>
      <Example title="H3" element="h3" />
      <Example title="H4" element="h4" />
      <Example title="Title" element="title" />
      <Example title="Custom" height={100} width={100} rounding="full" />
    </Stack>
  );
};
