import React, { ComponentProps, FC, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { Placement } from "@popperjs/core";
import { usePopper } from "react-popper";

import { useOutsideClick } from "../../03-hooks";
import { TElevationName, DEFAULT_ELEVATION } from "v2/04-providers";
import { Backdrop } from "v2/00-assets";

export type TPopoverPlacement = Extract<
  Placement,
  "bottom-start" | "left-start" | "top" | "bottom" | "left" | "right" | "right-start" | "bottom-end"
>;

export interface IPopoverProps extends Omit<ComponentProps<"div">, "ref"> {
  sameWidthWithReference?: boolean;
  referenceElement: HTMLElement | null;
  placement?: TPopoverPlacement;
  onClose: () => any;
  closeOnContentClick?: boolean;
  closeOnEscPress?: boolean;
  onReady?: () => any;
  onEscPress?: () => void;
  attachToRef?: boolean;
  open?: boolean;
  onPlacementChange?: (placement: Placement) => void;
  elevation?: TElevationName;
  backdrop?: boolean;
}

export const Popover: FC<IPopoverProps> = ({
  referenceElement,
  attachToRef = false,
  sameWidthWithReference,
  children,
  placement = "bottom-start",
  closeOnContentClick = true,
  closeOnEscPress = true,
  open = false,
  style,
  elevation = "saturn",
  backdrop,
  onClose,
  onReady,
  onPlacementChange,
  ...rest
}) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    popperElement && onReady && onReady();
  }, [popperElement, onReady]);

  useOutsideClick([popperElement, referenceElement], () => onClose());

  const modifiers = useMemo(() => {
    const modifiers = [{ name: "offset", options: { offset: [0, 8] } }];
    if (sameWidthWithReference) {
      modifiers.push({
        name: "sameWidth",
        //@ts-ignore
        enabled: true,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        //@ts-ignore
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        }
      });
    }
    return modifiers;
  }, [sameWidthWithReference]);

  //@ts-ignore
  const popper = usePopper(referenceElement, popperElement, {
    placement,
    modifiers
  });

  useEffect(() => {
    if (!onPlacementChange) return;

    if (popper.state?.placement === placement) return;

    onPlacementChange && onPlacementChange(popper.state?.placement || placement);
  }, [popper.state?.placement, placement]);

  useEffect(() => {
    if (!closeOnEscPress || !open) return;

    if (open) {
      const handleEscKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape" || event.key === "Esc") {
          onClose();
        }
      };

      window.addEventListener("keydown", handleEscKeyPress);

      return () => {
        window.removeEventListener("keydown", handleEscKeyPress);
      };
    }
  }, [open, onClose]);

  const renderPopover = () => (
    <div
      ref={setPopperElement}
      style={{
        ...popper.styles.popper,
        ...style,
        zIndex: DEFAULT_ELEVATION[elevation]
      }}
      {...popper.attributes.popper}
      {...rest}
    >
      <div onClick={() => closeOnContentClick && onClose()}>{children}</div>
    </div>
  );

  return referenceElement && open
    ? createPortal(
        backdrop ? (
          <Backdrop open transparent disableAnimation>
            {renderPopover()}
          </Backdrop>
        ) : (
          renderPopover()
        ),

        attachToRef ? referenceElement! : document.getElementById("root")!
      )
    : null;
};
