import React, { CSSProperties, useCallback, useRef, useState } from "react";
import { Option, OptionListBase, OptionListProps } from "../";
import {
  Input,
  InputHandle,
  Icon,
  Popover,
  InputProps,
  Button,
  useTheme,
  ColorVariants,
  TPopoverPlacement
} from "../../";
import styles from "./Select.module.scss";
import cx from "classnames";

export interface SelectProps
  extends Pick<
      OptionListProps,
      | "options"
      | "markSelected"
      | "scrollToSelected"
      | "searchPlaceholder"
      | "onEnter"
      | "searchable"
      | "actions"
    >,
    Omit<InputProps, "value" | "onSelect" | "onBlur"> {
  element?: "input" | "button" | "tag";
  onSelect?: (selected: Option) => unknown;
  value?: string | string[];
  disabled?: boolean;
  showAdornment?: boolean;
  searchAutoFocus?: boolean;
  onClear?: () => unknown;
  buttonColor?: ColorVariants;
  sameWidthWithReference?: boolean;
  elementStyle?: CSSProperties;
  elementClassName?: string;
  optionListMaxWidth?: CSSProperties["width"];
  attachToRef?: boolean;
  optionListPlacement?: TPopoverPlacement;
  multiSelect?: boolean;
  multipleSelectedCopy?: string;
  popoverBackdrop?: boolean;
}

const getOptionValue = (option: Option) => option.title || option.key;
const asArray = (any: string | string[]) => (typeof any === "string" ? [any] : any);

export const Select = ({
  options,
  onSelect,
  onEnter,
  value,
  disabled,
  searchable,
  markSelected,
  style,
  showAdornment = true,
  startAdornment,
  scrollToSelected,
  searchPlaceholder,
  searchAutoFocus,
  popoverBackdrop,
  onClear,
  endAdornment,
  actions,
  readonly,
  variant = "outlined",
  element = "input",
  placeholder,
  error,
  scrollToInputOnAutoFocus,
  buttonColor,
  size,
  elementStyle,
  sameWidthWithReference = false,
  optionListMaxWidth = "500px",
  attachToRef = true,
  optionListPlacement = "bottom-start",
  multiSelect = false,
  className,
  elementClassName,
  multipleSelectedCopy,
  ...rest
}: SelectProps) => {
  const { theme } = useTheme();
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const inputRef = useRef<InputHandle>(null);

  const [open, setOpen] = useState(false);

  const onSelectOption = (option: Option) => {
    onSelect && onSelect(option);
    !multiSelect && setOpen(false);
  };

  const onClosePopup = useCallback(() => {
    setTimeout(() => setOpen(false));
  }, []);

  const getExactMatch = (searchValue: string) => {
    return options.find(
      option => getOptionValue(option).toLowerCase().trim() === searchValue.toLowerCase().trim()
    );
  };

  const handleOnEnter = (searchValue: string) => {
    const exactMatch = getExactMatch(searchValue);
    if (exactMatch) {
      onSelect && onSelect(exactMatch);
      setOpen(false);
    } else if (onEnter) {
      onEnter(searchValue);
      setOpen(false);
    }
  };

  const getValue = () => {
    if (!value) return "";

    const valueArray = asArray(value);

    if (multiSelect && valueArray.length > 1) {
      return `${value.length} ${multipleSelectedCopy || "selected"}`;
    }

    const optionValue = valueArray[0];

    const exactKeyMatch = options.find(_ => _.key === optionValue);
    return exactKeyMatch ? getOptionValue(exactKeyMatch) : optionValue || "";
  };

  const resolveAdornment = () => {
    if (endAdornment) return endAdornment;
    if (onClear && value && !disabled)
      return (
        <Icon
          style={{ cursor: "pointer" }}
          name="crossSmall"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            onClear();
          }}
        />
      );
    return (
      showAdornment &&
      element !== "button" && (
        <Icon name={open ? "chevronUp" : "chevronDown"} style={{ pointerEvents: "none" }} />
      )
    );
  };

  const onOpen = () => {
    if (!disabled && !readonly) {
      setOpen(!open);
      inputRef.current?.blur();
    }
  };

  const optionListValue = value ? asArray(value) : [];

  return (
    <div
      ref={setRefElement}
      className={cx(styles.Wrapper, className)}
      style={style}
      onClick={element === "input" ? onOpen : undefined}
    >
      {element === "button" && (
        <Button
          variant={variant}
          startAdornment={startAdornment}
          endAdornment={resolveAdornment()}
          destructive={!!error}
          disabled={disabled}
          active={open}
          onClick={onOpen}
          size={size}
          style={elementStyle}
        >
          {getValue() || placeholder}
        </Button>
      )}
      {element === "input" && (
        <Input
          ref={inputRef}
          variant={variant}
          className={cx(styles.Select, { [styles.Disabled]: disabled }, elementClassName)}
          value={getValue()}
          disabled={disabled}
          endAdornment={resolveAdornment()}
          active={open ? open : undefined}
          readonly={readonly}
          startAdornment={startAdornment}
          placeholder={placeholder}
          error={error}
          size={size}
          style={elementStyle}
          {...rest}
        />
      )}

      <Popover
        referenceElement={refElement}
        open={open}
        onClose={onClosePopup}
        attachToRef={attachToRef}
        sameWidthWithReference={sameWidthWithReference}
        style={{ maxWidth: optionListMaxWidth }}
        placement={optionListPlacement}
        backdrop={popoverBackdrop}
      >
        <OptionListBase
          selectable={multiSelect}
          scrollToSelected={scrollToSelected}
          options={options}
          onItemClick={onSelectOption}
          selected={optionListValue}
          markSelected={markSelected}
          searchable={searchable}
          autoFocus={searchAutoFocus !== undefined ? searchAutoFocus : searchable}
          onEnter={handleOnEnter}
          searchPlaceholder={searchPlaceholder}
          actions={actions}
        />
      </Popover>
    </div>
  );
};
