import React from "react";
import { useTheme, Title } from "v2";

export const ThemeProviderExamples = () => {
  const { theme } = useTheme();
  return (
    <Title
      style={{
        color: theme.palette.success.pressed,
        fontFamily: theme.typography.font.heading,
        fontSize: theme.typography.size.title
      }}
    >
      Title with new styles
    </Title>
  );
};
