import React, { ReactNode } from "react";
import { CategorisedOption, CategorySelect } from "../CategorySelect";
import { Option } from "../OptionList";
import { capitalize } from "lodash";
import { H4, ListSearchInput, Stack, Tag, Title } from "../..";

export type TableActionBarProps = {
  title?: string;
  description?: string;
  search?: {
    value?: string;
    onChange: (search?: string) => void;
    placeholder?: string;
  };
  filter?: {
    value: CategorisedOption[];
    categories: Option[];
    options: CategorisedOption[];
    onFilterChange: (filters: CategorisedOption[]) => void;
  };
  actions?: ReactNode;
  endActions?: ReactNode;
};

export const TableActionBar = ({
  title,
  description,
  filter,
  actions,
  endActions,
  search,
  ...rest
}: TableActionBarProps) => {
  const onRemoveFilter = (filterToRemove: CategorisedOption) => {
    if (!filter) return;

    filter.onFilterChange(
      filter.value.filter((f: CategorisedOption) => f.key !== filterToRemove.key)
    );
  };

  return (
    <Stack {...rest} vertical>
      <Stack justifyContent="space-between">
        <Stack>
          {(title || description) && (
            <Stack gap="small">
              {title && <H4>{title}</H4>}
              {description && <Title>{description}</Title>}
            </Stack>
          )}

          <Stack gap="small">
            {filter && (
              <CategorySelect
                popoverBackdrop
                size="small"
                variant="outlined"
                categories={filter.categories}
                options={filter.options}
                value={filter.value}
                onChange={filter.onFilterChange}
                startIcon="filter"
              >
                Filter
              </CategorySelect>
            )}
            {actions}
          </Stack>
        </Stack>

        <Stack gap="small">
          {endActions}
          {search && (
            <ListSearchInput
              placeholder={search.placeholder || "Search"}
              value={search?.value}
              onChange={search.onChange}
            />
          )}
        </Stack>
      </Stack>

      {filter && filter.value?.length > 0 && (
        <Stack wrap alignItems="center" style={{ width: "100%" }} gap="xSmall" className="filters">
          {filter.value.map(f => (
            <Tag.Filter
              key={`${f.title}-${f.category}-${f.key}`}
              onRemove={() => onRemoveFilter(f)}
              filter={{
                filterName: capitalize(f.category),
                method: "is",
                value: [f.title || f.key]
              }}
            />
          ))}
          <Tag colorVariant="outlined" onClick={() => filter.onFilterChange([])}>
            Clear filters
          </Tag>
        </Stack>
      )}
    </Stack>
  );
};
