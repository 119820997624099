import React, { useState } from "react";
import { Select } from ".";
import { useTheme, useToast } from "../../03-hooks";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Icon } from "../../00-assets";
import { Option } from "../OptionList";

const options = [
  { key: "Option 1" },
  { key: "Option 2" },
  { key: "Option 3" },
  {
    key: "Option 4",
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vestibulum volutpat ante vitae condimentum. Aliquam tristique a dui non malesuada."
  }
];

export const SelectExamples = () => {
  const toast = useToast();
  const { theme } = useTheme();

  const [selected, setSelected] = useState<string>();
  const [multiSelect, setMultiSelect] = useState<string[]>([]);

  const onMultiSelect = (option: Option) => {
    const { key } = option;
    const hasItem = multiSelect.includes(key);
    if (hasItem) setMultiSelect(multiSelect.filter(_ => _ !== key));
    else setMultiSelect([...multiSelect, key]);
  };
  return (
    <div>
      <ComponentSection
        title="Basic select"
        description="Plain and simple Select component as seen everywhere"
        columns={1}
      >
        <Select
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Plain Select"
        />
        <Select
          options={options}
          endAdornment={<Icon name="calendar" />}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Plain Searchable Select"
          searchable
        />
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Disabled Select"
        />
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Readonly Select"
        />
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          onClear={() => setSelected(undefined)}
          placeholder="Clearable Select"
        />
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Select with Error"
          error
        />
      </ComponentSection>
      <ComponentSection
        title="Basic multi select"
        description="Plain multi Select component as seen everywhere"
        columns={1}
      >
        <Select
          startAdornment={<Icon name="person" />}
          options={options}
          value={multiSelect}
          onSelect={onMultiSelect}
          placeholder="Plain Select"
          multiSelect
        />
        <Select
          options={options}
          endAdornment={<Icon name="calendar" />}
          value={multiSelect}
          onSelect={onMultiSelect}
          placeholder="Plain Searchable Select"
          searchable
          multiSelect
        />
        <Select
          options={options}
          value={multiSelect}
          onSelect={onMultiSelect}
          disabled
          placeholder="Disabled Select"
          multiSelect
        />
        <Select
          options={options}
          value={multiSelect}
          onSelect={onMultiSelect}
          disabled
          placeholder="Readonly Select"
          multiSelect
        />
        <Select
          options={options}
          value={multiSelect}
          onSelect={onMultiSelect}
          onClear={() => setSelected(undefined)}
          placeholder="Clearable Select"
          multiSelect
        />
        <Select
          options={options}
          value={multiSelect}
          onSelect={onMultiSelect}
          placeholder="Select with Error"
          error
          multiSelect
        />
      </ComponentSection>
      <ComponentSection
        title="Blended variant"
        description="Select can have variant blended to become more, well, blended"
        columns={1}
      >
        <Select
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Blended Select"
        />
        <Select
          variant="blended"
          endAdornment={<Icon name="calendar" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Disabled Blended Select"
        />
        <Select
          variant="blended"
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Readonly Blended Select"
        />
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Blended with Error"
          error
        />
      </ComponentSection>
      <ComponentSection
        title="Basic button variant"
        description="Select can look like a button (be a button) when closed"
        columns={1}
      >
        <Select
          element="button"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Button Select"
        />
        <Select
          element="button"
          endAdornment={<Icon name="calendar" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Disabled Button Select"
        />
        <Select
          element="button"
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Readonly Button Select"
        />
        <Select
          element="button"
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Button with Error"
          error
        />
      </ComponentSection>
      <ComponentSection
        title="Blended button variant"
        description="Select can look like a button (be a button) when closed"
        columns={1}
      >
        <Select
          element="button"
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Button Select"
        />
        <Select
          element="button"
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Custom Color Button Select"
          buttonColor={theme.palette.border}
        />
        <Select
          element="button"
          variant="blended"
          endAdornment={<Icon name="calendar" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Disabled Button Select"
        />
        <Select
          element="button"
          variant="blended"
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          disabled
          placeholder="Readonly Button Select"
        />
        <Select
          element="button"
          variant="blended"
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Button with Error"
          error
        />
      </ComponentSection>

      <ComponentSection
        title="Select actions"
        description="There are quite some actions Select can have through OptionList"
        columns={1}
      >
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="This select has an action"
          actions={[
            {
              key: "add",
              title: "Add new item",
              startAdornmentIcon: "plus",
              onClick: () => toast.info("Action clicked")
            }
          ]}
        />
        <Select
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Add with enter"
          searchable
          onEnter={search => setSelected(search)}
        />
      </ComponentSection>
      <ComponentSection
        title="No wrap"
        description="Button Select can be set to not grow even if the content is long"
        columns={2}
      >
        <Select
          element="button"
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Custom Color Button Select with very, very, very long text."
          buttonColor={theme.palette.border}
          style={{ width: "200px" }}
        />
        <Select
          element="button"
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="Custom Color Button Select with some very, very, very, very, very long lorem ipsum."
          buttonColor={theme.palette.border}
        />
      </ComponentSection>
      <ComponentSection
        title="Option list placement"
        description="Change the default placement for option list"
        columns={2}
        style={{ marginBottom: "4rem" }}
      >
        <Select
          element="button"
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="bottom-start"
          optionListPlacement="bottom-start"
          buttonColor={theme.palette.border}
          style={{ width: "200px" }}
        />{" "}
        <Select
          element="button"
          variant="blended"
          startAdornment={<Icon name="person" />}
          options={options}
          value={selected}
          onSelect={option => setSelected(option.key)}
          placeholder="left-start"
          optionListPlacement="left-start"
          buttonColor={theme.palette.border}
          style={{ width: "200px" }}
        />
      </ComponentSection>
    </div>
  );
};
