import React, { MouseEventHandler, useMemo } from "react";
import cx from "classnames";
import styles from "./Icon.module.scss";

import { IconName, icons } from "./icons";
import { useTheme } from "v2/03-hooks";

export interface IconProps {
  name: IconName;
  color?: string;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLOrSVGElement>;
  className?: string;
  shortcut?: boolean;
  danger?: boolean;
  dimmed?: boolean;
  subdued?: boolean;
}

interface StyledIconProps {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const StyledIcon: React.FunctionComponent<
  StyledIconProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ icon: Icon, ...rest }) => {
  return Icon ? <Icon {...rest} /> : <></>;
};

export const Icon = ({
  color,
  name,
  danger,
  dimmed,
  subdued,
  style,
  onClick,
  className,
  shortcut
}: IconProps) => {
  const { theme } = useTheme();

  const subduedColor = theme.palette.foreground.subdued;
  const dimmedColor = theme.palette.foreground.dimmed;
  const dangerColor = theme.palette.danger.default;

  const getColor = () => {
    if (color) {
      return color;
    }

    if (subdued) {
      return subduedColor;
    }

    if (dimmed) {
      return dimmedColor;
    }

    if (danger) {
      return dangerColor;
    }

    return theme.palette.foreground.default;
  };

  const finalColor = getColor();

  return (
    <StyledIcon
      onClick={onClick}
      icon={icons[name]}
      className={cx(className, { [styles.Shortcut]: shortcut })}
      style={{
        ...style,
        fill: finalColor,
        stroke: finalColor
      }}
    />
  );
};
