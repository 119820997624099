import React, { useState } from "react";
import styled from "styled-components";
import { H4, P, Title } from "../Typography";

import { ALIGNMENT, Alignment, STACK_SPACING, StackSpacing, Stack, StackProps } from "./Stack";
import { Toggle } from "../Toggle";
import { Select } from "v2/02-blocks";

const StyledStackExamples = styled.div``;

const StyledExampleStack = styled(Stack)`
  border: 1px solid violet;
`;

export const StackExamples = () => {
  const [gap, setGap] = useState<StackSpacing>();
  const [vertical, setVertical] = useState<boolean>();
  const [fitContent, setFitContent] = useState<boolean>();
  const [alignItems, setAlignItems] = useState<Alignment>();
  const [justifyContent, setJustifyContent] = useState<Alignment>();

  return (
    <StyledStackExamples>
      <Stack vertical gap="xxLarge">
        <P>
          Stack is the workhorse of layouting. It was born out of pain of constantly having to
          create basic flexbox components.
        </P>

        <Stack vertical>
          <H4>Props</H4>

          <Stack vertical>
            <code>
              <b>vertical</b>?: boolean;
            </code>
            <code>
              <b>justifyContent</b>?: Alignment;
            </code>
            <code>
              <b>alignSelf</b>?: Alignment;
            </code>
            <code>
              <b>alignItems</b>?: Alignment;
            </code>
            <code>
              <b>gap</b>?: Spacing;
            </code>
            <code>
              <b>lastChildToEnd</b>?: boolean;
            </code>
            <code>
              <b>fitContent</b>?: boolean;
            </code>
          </Stack>
          <br />
          <code>
            <Stack vertical>
              <span>
                <b>type Alignment</b> = "flex-start" | "flex-end" | "space-between" | "center" |
                "stretch" | "baseline";
              </span>
              <span>
                <b>type Spacing</b> = "xSmall" | "small" | "medium" | "large" | "xLarge" | "xxLarge"
                | "none";
              </span>
            </Stack>
          </code>
        </Stack>

        <Stack vertical>
          <Stack vertical gap="small">
            <H4>Example</H4>
            <Stack>
              <Stack gap="small" style={{ flexShrink: 0 }}>
                <Toggle checked={!!vertical} onChange={setVertical} />
                <Title variant="bold">Vertical</Title>
              </Stack>
              <Stack gap="small" style={{ flexShrink: 0 }}>
                <Toggle checked={!!fitContent} onChange={setFitContent} />
                <Title variant="bold">Fit content</Title>
              </Stack>
              <Select
                placeholder="Gap"
                size="small"
                value={gap}
                options={STACK_SPACING.map(s => ({ title: s, key: s }))}
                onSelect={({ key }) => setGap(key as StackSpacing)}
              />
              <Select
                placeholder="Align items"
                size="small"
                value={alignItems}
                options={ALIGNMENT.map(s => ({ title: s, key: s }))}
                onSelect={({ key }) => setAlignItems(key as Alignment)}
              />
              <Select
                placeholder="Justify content"
                size="small"
                value={justifyContent}
                options={ALIGNMENT.map(s => ({ title: s, key: s }))}
                onSelect={({ key }) => setJustifyContent(key as Alignment)}
              />
            </Stack>
          </Stack>
          <StyledExampleStack
            vertical={vertical}
            fitContent={fitContent}
            justifyContent={justifyContent}
            gap={gap}
            alignItems={alignItems}
          >
            <Title>Item 1</Title>
            <Title>Item 2</Title>
            <Title>Item 3</Title>
          </StyledExampleStack>
        </Stack>
      </Stack>
    </StyledStackExamples>
  );
};
