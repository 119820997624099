import React, { FC, ReactNode, useMemo } from "react";
import styled, { css } from "styled-components";

import { Icon, IconName } from "../../00-assets";
import { Title } from "../../01-atoms";
import { useTheme } from "v2/03-hooks";

export type TextButtonProps = {
  startIcon?: IconName;
  onClick?: () => void;
  colorVariant?: "primary" | "subdued";
  children?: ReactNode;
};

const StyledTitle = styled(Title)<{ $color?: string }>`
  color: ${({ $color }) => $color};
`;

const StyledTextButton = styled.button<{ $colorVariant: "primary" | "subdued" }>`
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  .icon {
    fill: var(--palette-foreground-subdued) !important;
    stroke: var(--palette-foreground-subdued) !important;
  }

  .text {
    color: var(--palette-foreground-subdued);
  }

  :hover {
    .text {
      color: var(--palette-foreground-default);
    }
    .icon {
      fill: var(--palette-foreground-default) !important;
      stroke: var(--palette-foreground-default) !important;
    }
  }

  ${({ $colorVariant }) => {
    if ($colorVariant === "primary") {
      return css`
        .icon {
          fill: var(--palette-primary-default) !important;
          stroke: var(--palette-primary-default) !important;
        }

        .text {
          color: var(--palette-primary-default);
        }

        :hover {
          .text {
            color: var(--palette-primary-subdued);
          }
          .icon {
            fill: var(--palette-primary-subdued) !important;
            stroke: var(--palette-primary-subdued) !important;
          }
        }
      `;
    }
  }}
`;

export const TextButton = ({
  onClick,
  startIcon,
  colorVariant = "subdued",
  children,
  ...rest
}: TextButtonProps) => {
  const { theme } = useTheme();

  const color = useMemo(() => {
    if (colorVariant === "primary") {
      return {
        foreground: theme.palette.primary.default,
        hover: theme.palette.primary.dimmed
      };
    }

    return {
      foreground: theme.palette.foreground.subdued,
      hover: theme.palette.foreground.default
    };
  }, [colorVariant]);

  return (
    <StyledTextButton {...rest} onClick={onClick} $colorVariant={colorVariant}>
      {startIcon && <Icon className="icon" name={startIcon} />}
      <StyledTitle variant="bold" className="text">
        {children}
      </StyledTitle>
    </StyledTextButton>
  );
};
