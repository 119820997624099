import React, { ComponentProps, ReactNode } from "react";
import styled, { css } from "styled-components";

import { IconName, Icon } from "../../00-assets/Icons";
import { useColorVariant } from "../../03-hooks";
import { Title } from "../Typography";
import { Card } from "../Card";
import { Layout } from "../Layout";

export type TInfoCardColorVariant = "primary" | "success" | "danger" | "warning" | "surface";

type InfoCardProps = Pick<ComponentProps<"div">, "style" | "onClick"> & {
  title: ReactNode;
  children?: ReactNode;
  icon?: IconName;
  hideIcon?: boolean;
  colorVariant?: TInfoCardColorVariant;
  onClose?: () => void;
};

type TInfoCardColor = {
  foreground: string;
  border: string;
  background: string;
  tagBackground?: string;
};

const StyledInfoCard = styled(Card)<{ $color: TInfoCardColor }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  user-select: none;

  ${({ $color }) => css`
    && {
      border-color: ${$color.border};
      background-color: ${$color.background};

      * {
        color: ${$color.foreground};
      }

      .icon {
        stroke: ${$color.foreground};
        fill: ${$color.foreground};
      }
    }
  `};
`;

const StyledTitle = styled(Title)`
  flex: 1;
`;

export const InfoCard = ({
  title,
  children,
  icon = "infoFilled",
  hideIcon,
  colorVariant = "surface",
  onClose,
  ...props
}: InfoCardProps) => {
  const color = useColorVariant(colorVariant);

  return (
    <StyledInfoCard {...props} $color={color}>
      <Layout.Group gap="small" lastChildToEnd>
        {!hideIcon && <Icon className="icon" name={icon} color={color.foreground} />}
        <StyledTitle variant={children ? "bold" : "regular"}>{title}</StyledTitle>
        {onClose && (
          <Icon
            name="crossSmall"
            onClick={onClose}
            style={{ cursor: "pointer" }}
            color={color.foreground}
          />
        )}
      </Layout.Group>
      {typeof children === "string" ? <Title>{children}</Title> : children}
    </StyledInfoCard>
  );
};
