import React, { useState } from "react";
import chroma from "chroma-js";

import { useOutsideClick, useTheme } from "../../03-hooks";
import { Layout, Title } from "../../01-atoms";
import { IconButton } from "../IconButton";
import styled from "styled-components";
import { IconName, Icon } from "v2/00-assets";

export type ToastType = "success" | "info" | "warning" | "error";

export interface ToastProps {
  title: string;
  message?: string;
  onClose?: () => any;
  type?: ToastType;
  onOutsideClick?: () => any;
}

const StyledToast = styled.div`
  padding: var(--spacing-large);
  background-color: var(--palette-foreground-default);
  border-radius: var(--rounding-medium);
  user-select: none;
`;

const IconBackground = styled.div<{ $backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const StyledIconButton = styled(IconButton)`
  background-color: transparent;
  transition: none;
  border: none !important;

  :hover {
    background-color: transparent !important;

    svg {
      fill: var(--palette-foreground-ghosted) !important;
    }
  }

  svg {
    fill: var(--palette-foreground-dimmed);
  }
`;

export const Toast = ({ onClose, onOutsideClick, title, type = "info", message }: ToastProps) => {
  const { theme } = useTheme();
  const [toastElement, setElement] = useState<HTMLElement | null>(null);

  const getToastIconProps = (
    type: ToastType
  ): { name: IconName; color: string; backgroundColor: string } => {
    switch (type) {
      case "error":
        return {
          name: "warning",
          color: theme.palette.danger.default,
          backgroundColor: chroma(theme.palette.danger.default).alpha(0.3).hex()
        };

      case "success":
        return {
          name: "check",
          color: theme.palette.success.default,
          backgroundColor: chroma(theme.palette.success.default).alpha(0.3).hex()
        };

      case "warning":
        return {
          name: "warning",
          color: theme.palette.warning.default,
          backgroundColor: chroma(theme.palette.warning.default).alpha(0.3).hex()
        };

      default:
      case "info":
        return {
          name: "info",
          color: theme.palette.primary.default,
          backgroundColor: chroma(theme.palette.primary.default).alpha(0.3).hex()
        };
    }
  };

  useOutsideClick([toastElement], () => {
    onOutsideClick && onOutsideClick();
  });

  const iconStuff = getToastIconProps(type);

  return (
    <StyledToast role="alert" ref={setElement}>
      <Layout.Group justifyContent="space-between">
        <Layout.Group gap="medium">
          <IconBackground $backgroundColor={iconStuff.backgroundColor}>
            <Icon name={iconStuff.name} color={iconStuff.color} />
          </IconBackground>
          <Title variant="bold" color={theme.palette.background.default}>
            {title}
          </Title>
        </Layout.Group>
        {onClose && <StyledIconButton icon="crossSmall" onClick={onClose} variant="blended" />}
      </Layout.Group>

      {message && <Title color={theme.palette.foreground.dimmed}>{message}</Title>}
    </StyledToast>
  );
};
