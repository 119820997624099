import React from "react";
import { useTheme } from "v2";
import { Indicator } from ".";
import { Button } from "..";

export const IndicatorExamples = () => {
  const { theme } = useTheme();
  return (
    <div style={{ display: "grid", gap: "5rem", gridAutoFlow: "column", width: "fit-content" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          width: "fit-content",
          gap: "1rem"
        }}
      >
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.primary} />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          width: "fit-content",
          gap: "1rem",
          height: "fit-content"
        }}
      >
        <Indicator color={theme.palette.border} />
        <Indicator color={theme.palette.danger} />
        <Indicator color={theme.palette.foreground} />
        <Indicator color={theme.palette.highlight} />
        <Indicator color={theme.palette.primary} />
        <Indicator color={theme.palette.success} />
        <Indicator color={theme.palette.surface} />
        <Indicator color={theme.palette.warning} />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          width: "fit-content",
          gap: "1rem",
          height: "fit-content"
        }}
      >
        <div style={{ display: "flex", position: "relative" }}>
          <Indicator
            color={theme.palette.primary}
            vertical
            style={{ position: "absolute", top: 0, left: 0 }}
          />
          <Button style={{ marginLeft: "1rem" }} variant="blended" destructive>
            Example
          </Button>
        </div>
      </div>
    </div>
  );
};
