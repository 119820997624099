import { TInfoCardColorVariant, TTagColorVariant } from "v2/01-atoms";
import { useTheme } from "../useTheme";

type TColorVariant = TInfoCardColorVariant | TTagColorVariant;

type ColorGroup = {
  foreground: string;
  border: string;
  background: string;
};

export const useColorVariant = (
  colorVariant: TColorVariant,
  options?: {
    highContrast?: boolean;
  }
): ColorGroup => {
  const { theme } = useTheme();
  if (options?.highContrast) {
    switch (colorVariant) {
      case "danger":
        return {
          foreground: "#DC2626",
          border: "#DC2626",
          background: "#FEE2E2"
        };
      case "outlined":
        return {
          foreground: theme.palette.foreground.subdued,
          border: theme.palette.foreground.dimmed,
          background: "transparent"
        };
      case "primary":
        return {
          foreground: "#2563EB",
          border: "#2563EB",
          background: "#DBEAFE"
        };
      case "success":
        return {
          border: "#059669",
          foreground: "#059669",
          background: "#D1FAE5"
        };
      case "warning":
        return {
          border: "#CA8A04",
          foreground: "#CA8A04",
          background: "#FEF9C3"
        };
      case "info": {
        return {
          border: "#0284C7",
          foreground: "#0284C7",
          background: "#E0F2FE"
        };
      }
      case "purple":
        return {
          border: "#9333EA",
          foreground: "#9333EA",
          background: "#F3E8FF"
        };
      case "surface":
      default:
        return {
          border: "#4B5563",
          foreground: "#4B5563",
          background: theme.palette.surface.default
        };
    }
  }

  switch (colorVariant) {
    case "danger":
      return {
        foreground: theme.palette.danger.default,
        border: theme.palette.danger.dimmed,
        background: theme.palette.danger.ghosted
      };
    case "primary":
      return {
        foreground: theme.palette.primary.default,
        border: theme.palette.primary.dimmed,
        background: theme.palette.primary.ghosted
      };

    case "success": {
      return {
        foreground: theme.palette.success.default,
        border: theme.palette.success.dimmed,
        background: theme.palette.success.ghosted
      };
    }
    case "warning": {
      return {
        foreground: theme.palette.warning.pressed,
        border: theme.palette.warning.subdued,
        background: theme.palette.warning.ghosted
      };
    }
    case "outlined": {
      return {
        foreground: theme.palette.foreground.subdued,
        border: theme.palette.foreground.subdued,
        background: "transparent"
      };
    }
    case "info": {
      return {
        foreground: theme.palette.info.default,
        background: theme.palette.info.ghosted,
        border: theme.palette.info.default
      };
    }
    case "purple": {
      return {
        foreground: theme.palette.purple.default,
        background: theme.palette.purple.ghosted,
        border: theme.palette.purple.default
      };
    }
    default:
    case "surface":
      return {
        foreground: theme.palette.foreground.subdued,
        background: theme.palette.surface.default,
        border: theme.palette.border.default
      };
  }
};
