import React, { useState } from "react";
import { CircularLoader } from "./CircularLoader";
import { useTheme } from "../../03-hooks";
import { IntroistLoader } from "./IntroistLoader";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Button } from "../Button";

export const LoaderExamples = () => {
  const { theme } = useTheme();

  const [showGlobalLoader, setShowGlobalLoader] = useState(false);

  return (
    <div>
      <div>CircularLoader</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          alignItems: "center",
          marginTop: theme.spacing.large
        }}
      >
        <CircularLoader size="tiny" />
        <CircularLoader size="small" />
        <CircularLoader size="medium" />
        <CircularLoader size="large" />
      </div>

      <div>Fill parent container</div>
      <div
        style={{
          width: "100%",
          height: "300px",
          border: `1px solid ${theme.palette.border.default}`
        }}
      >
        <CircularLoader fillParent />
      </div>

      <ComponentSection
        title="Introist Loader"
        description="Introist themed loaded to use in general loadings (e.g. views)"
      >
        <div
          style={{
            width: "100%",
            height: "300px",
            border: `1px solid ${theme.palette.border.default}`
          }}
        >
          <IntroistLoader fillParent />
        </div>
      </ComponentSection>

      <ComponentSection
        title="Globally center Introist Loader"
        description="Introist Loader can be set to be in the middle of the browser window"
      >
        {showGlobalLoader && <IntroistLoader globalCenter />}
        <Button
          onClick={() => {
            setShowGlobalLoader(!showGlobalLoader);
          }}
        >
          {showGlobalLoader ? "Hide global loader" : "Show global loader"}
        </Button>
      </ComponentSection>
    </div>
  );
};
