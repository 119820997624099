import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Icon } from "../../00-assets";
import { useColorVariant } from "../../03-hooks";
import { Title } from "../Typography";
import { ITagProps } from ".";

type TStyledTagProps = {
  $backgroundColor: string;
  $foregroundColor: string;
  $borderColor?: string;
  $clickable: boolean;
};

const StyledBaseTag = styled.span<TStyledTagProps>`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  width: fit-content;
  padding: 0 0.375rem;
  border-radius: var(--rounding-medium);
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: 1px solid ${({ $borderColor }) => $borderColor || "transparent"};
  cursor: default;
  min-height: 1.25rem;
  user-select: none;

  > h5 {
    font-size: 12px;
    color: ${({ $foregroundColor }) => $foregroundColor};
  }

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`;

export const BaseTag: FC<ITagProps> = ({
  children,
  onRemove,
  onClick,
  startIcon,
  endIcon,
  colorVariant = "surface",
  foregroundColor,
  backgroundColor,
  startAdornment,
  ...rest
}) => {
  const { foreground, background, border } = useColorVariant(colorVariant, { highContrast: true });

  return (
    <StyledBaseTag
      {...rest}
      onClick={onClick}
      $backgroundColor={backgroundColor || background}
      $foregroundColor={foregroundColor || foreground}
      $borderColor={colorVariant === "outlined" ? border : undefined}
      $clickable={!!onClick}
    >
      {startIcon && <Icon name={startIcon} color={foreground || foregroundColor} />}
      {startAdornment}
      {children && <Title variant="bold">{children}</Title>}
      {endIcon && <Icon name={endIcon} color={foreground || foregroundColor} />}
      {onRemove && (
        <Icon
          name="crossSmall"
          style={{ cursor: "pointer" }}
          onClick={e => {
            onRemove();
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
    </StyledBaseTag>
  );
};
