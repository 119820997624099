import constate from "constate";

type TAppMenuInternalContext = {
  collapsed: boolean;
};

// For internal use only
export const [AppMenuInternalContext, useAppMenuInternalContext] = constate(
  ({ collapsed }: TAppMenuInternalContext) => {
    return { collapsed };
  }
);
