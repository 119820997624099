import React from "react";
import { ActionMenu, Icon } from "../../";

export const ActionMenuExamples = () => {
  return (
    <div>
      <ActionMenu
        options={[
          {
            key: "Send me a test",
            startAdornment: <Icon name="arrowRight" />,
            onClick: () => alert("Sending a test")
          },
          {
            key: "Duplicate step",
            startAdornment: <Icon name="plus" />,
            onClick: () => console.log("Duplicating")
          },
          {
            key: "Remove step",
            startAdornment: <Icon name="trash" />,
            onClick: () => new Promise(res => setTimeout(res, 5000))
          }
        ]}
      />
    </div>
  );
};
