import getProperty from "lodash.get";
import setProperty from "lodash.set";
import React, { useCallback, useState } from "react";
import Schema, { SchemaDefinition, ValidationError, ValidationOptions } from "validate";
import { z } from "zod";

interface KeyValueMap {
  [index: string]: any;
}

// ValidationError is not actually exported as a class from validate
// therefore, creating class implementation
class ValError implements ValidationError {
  constructor(public message: string, public path: string) {}

  public status = 400;
  public expose = false;
}

export interface DataForm<DataType> {
  data: DataType;
  set: (key: string, silent?: boolean) => (value: any) => unknown;
  setMany: (keyValueMap: KeyValueMap, silent?: boolean) => unknown;
  get: (key: string) => string | any;
  validate: (
    schema?: SchemaDefinition,
    zodSchema?: z.ZodObject<any>,
    validationOptions?: ValidationOptions
  ) => boolean;
  submit: (schema?: SchemaDefinition) => DataType;
  setValue: (data: DataType, editing?: boolean) => unknown;
  errors: ValidationError[];
  error: (key: string) => string | undefined;
  setErrors: (errors: ValidationError[]) => void;
  isError: (key: string) => boolean | undefined;
  edited: boolean;
}

export const useForm = <DataType extends object = any>(
  initialSchema?: SchemaDefinition,
  defaultValue?: DataType,
  debug?: boolean
): DataForm<DataType> => {
  const [data, setData] = useState<DataType>(defaultValue || ({} as DataType));
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [edited, setEdited] = useState(false);

  const setMany = useCallback(
    (keyValueMap: KeyValueMap, silent?: boolean) => {
      const newData = { ...data };
      const keys = Object.keys(keyValueMap);
      keys.map(key => setProperty(newData, key, keyValueMap[key]));
      setData(newData);
      setErrors(errors.filter(e => !keys.includes(e.path)));
      !silent && setEdited(true);
      if (debug) {
        console.log(`Update to data:`);
        keys.map(key =>
          console.log(`Value ${keyValueMap[key].toString()} to key ${key} with silent as ${silent}`)
        );
      }
    },
    [data, errors]
  );

  const set = useCallback(
    (key: string, silent = false) => (value: any) => {
      setMany({ [key]: value }, silent);
    },
    [setMany]
  );

  const get = (key: string) => {
    const value = getProperty(data, key);
    if (value !== null && value !== undefined) return value;
    return "";
  };

  const error = useCallback(
    (key: string): string | undefined => {
      const error = errors.find(e => e.path === key.toString());
      //@ts-ignore
      return error ? error.message : undefined;
    },
    [errors]
  );

  const isError = useCallback((key: string): boolean | undefined => error(key) !== undefined, [
    error
  ]);

  const setValue = useCallback((data?: DataType, editing?: boolean) => {
    data && setData(data);
    editing !== undefined && setEdited(editing);
    if (debug) console.log(`Set ${data} to data with editing: ${editing}`);
  }, []);

  const validateSchema = useCallback(
    (schema: SchemaDefinition, validationOptions: ValidationOptions) => {
      const schemaToUse = schema || initialSchema;
      if (schemaToUse) {
        const validator = new Schema(schemaToUse);
        validator.typecaster({
          object: val => val
        });
        const errors = validator.validate(data, validationOptions);
        setErrors(errors);
        return errors.length === 0;
      }
      throw new Error("No schema for validation");
    },
    [initialSchema, data]
  );

  const validateZodSchema = useCallback(
    (zodSchema: z.ZodObject<any>, validationOptions: ValidationOptions) => {
      let validator;

      if (validationOptions.strip) {
        validator = zodSchema;
      } else {
        validator = zodSchema.passthrough();
      }

      const result = validator.safeParse(data);

      if (!result.success) {
        const errors = result.error.issues.map(i => new ValError(i.message, i.path.toString()));

        setErrors(errors);

        return errors.length === 0;
      } else {
        return true;
      }
    },
    [data]
  );

  const validate = useCallback(
    (
      schema?: SchemaDefinition,
      zodSchema?: z.ZodObject<any>,
      validationOptions: ValidationOptions = { typecast: true, strip: false }
    ) => {
      if (schema) {
        return validateSchema(schema, validationOptions);
      } else if (zodSchema) {
        return validateZodSchema(zodSchema, validationOptions);
      } else {
        throw new Error("No schema for validation");
      }
    },
    [data, initialSchema, validateSchema]
  );

  const submit = useCallback(
    (schema?): DataType => {
      if (validate(schema)) {
        setEdited(false);
        return data;
      } else throw new Error("Validation failed");
    },
    [data]
  );

  return {
    data,
    setMany,
    set,
    get,
    validate,
    submit,
    setValue,
    errors,
    error,
    setErrors,
    isError,
    edited
  };
};
