import styled, { css } from "styled-components";

export const ALIGNMENT = [
  "flex-start",
  "flex-end",
  "space-between",
  "center",
  "stretch",
  "baseline"
] as const;
export type Alignment = typeof ALIGNMENT[number];

export const STACK_SPACING = [
  "xSmall",
  "small",
  "medium",
  "large",
  "xLarge",
  "xxLarge",
  "none"
] as const;
export type StackSpacing = typeof STACK_SPACING[number];

export type StackProps = {
  vertical?: boolean;
  justifyContent?: Alignment;
  alignSelf?: Alignment;
  alignItems?: Alignment;
  gap?: StackSpacing;
  lastChildToEnd?: boolean;
  wrap?: boolean;
  fitContent?: boolean;
};

const getGap = (spacing?: StackSpacing) => {
  switch (spacing) {
    case "medium":
      return css`
        gap: var(--spacing-medium);
      `;
    case "small":
      return css`
        gap: var(--spacing-small);
      `;
    case "xLarge":
      return css`
        gap: var(--spacing-xLarge);
      `;
    case "xSmall":
      return css`
        gap: var(--spacing-xSmall);
      `;
    case "xxLarge":
      return css`
        gap: var(--spacing-xxLarge);
      `;
    case "large":
    default:
      return css`
        gap: var(--spacing-large);
      `;
  }
};

export const Stack = styled.div<StackProps>`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  ${({ gap }) => gap !== "none" && getGap(gap)};

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}

  ${({ vertical, alignItems }) =>
    vertical &&
    css`
      align-items: ${alignItems ? alignItems : "stretch"};
      flex-direction: column;
    `}

    svg {
    flex-shrink: 0;
  }

  ${({ lastChildToEnd, vertical }) => {
    if (lastChildToEnd && vertical) {
      return css`
        > :last-child:not(:first-child) {
          align-self: flex-end;
        }
      `;
    }
    if (lastChildToEnd && !vertical) {
      return css`
        > :last-child:not(:first-child) {
          margin-left: auto;
        }
      `;
    }
  }}

  ${({ wrap }) =>
    wrap &&
    css`
      flex-wrap: wrap;
    `}

  ${({ fitContent }) =>
    fitContent &&
    css`
      width: fit-content;
    `}
`;
