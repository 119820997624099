import React, { ComponentProps } from "react";
import { Button, ButtonProps } from "../../01-atoms";

import styles from "./ButtonGroup.module.scss";
import cx from "classnames";

export type ButtonGroupProps = Omit<ComponentProps<"div">, "onClick"> & {
  buttons: Omit<ButtonProps, "size" | "variant">[];
  size?: "small" | "medium" | "large";
  active?: string[];
  onClick?: (buttonKey: string) => void;
};

export const ButtonGroup: React.VFC<ButtonGroupProps> = ({
  buttons,
  active,
  size,
  onClick,
  className,
  ...rest
}) => {
  return (
    <div className={cx(styles.Root, className)} {...rest}>
      {buttons.map(btn => {
        const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (btn.onClick) btn.onClick(e);
          if (btn.key && onClick) onClick(btn.key as string);
        };

        const isActiveButton = !!btn.key && active?.includes(btn.key as string);

        return (
          <Button
            key={btn.key}
            {...btn}
            className={cx(btn.className, { [styles.InactiveButton]: !isActiveButton })}
            onClick={onButtonClick}
            size={size}
            variant={isActiveButton ? "outlined" : "blended"}
          />
        );
      })}
    </div>
  );
};
