import React from "react";
import { Icon } from "../../00-assets";
import { Input } from ".";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";

export const InputsExample = () => {
  return (
    <>
      <ComponentSection
        title="Number input"
        description="Simple and plain number input to show margin next to spin buttons"
      >
        <Input
          type="number"
          size="small"
          inputStyles={{ textAlign: "right" }}
          style={{ width: "50px" }}
        />
      </ComponentSection>{" "}
      <div style={{ display: "grid", gap: "7.5rem", gridTemplateColumns: "1fr 1fr 1fr" }}>
        <div style={{ display: "grid", gap: "1rem" }}>
          <Input endAdornment={<Icon name="calendar" />} />
          <Input endAdornment={<Icon name="calendar" />} value="Value" />
          <Input endAdornment={<Icon name="calendar" />} placeholder="Placeholder" />
          <Input endAdornment={<Icon name="calendar" />} value="Active" active />
          <Input endAdornment={<Icon name="calendar" />} value="Disabled" disabled />
          <Input endAdornment={<Icon name="calendar" />} error value="Error" />
          <Input endAdornment={<Icon name="calendar" />} readonly value="Readonly" />
        </div>
        <div style={{ display: "grid", gap: "1rem" }}>
          <Input />
          <Input value="Value" />
          <Input placeholder="Placeholder" />
          <Input value="Active" active />
          <Input value="Disabled" disabled />
          <Input error value="Error" />
          <Input readonly value="Readonly" />
        </div>
        <div style={{ display: "grid", gap: "1rem" }}>
          <Input variant="blended" />
          <Input value="Value" variant="blended" />
          <Input
            placeholder="Placeholder"
            variant="blended"
            endAdornment={<Icon name="calendar" />}
          />
          <Input value="Active" active variant="blended" />
          <Input value="Disabled" disabled variant="blended" />
          <Input error value="Error" variant="blended" endAdornment={<Icon name="calendar" />} />
          <Input readonly value="Readonly" variant="blended" />
        </div>
        <div style={{ gridColumn: "1/-1" }}>
          <ComponentSection title="Size" description="Input can have different size" columns={3}>
            <Input size="small" placeholder="small" />
            <Input size="medium" placeholder="medium" />
            <Input size="large" placeholder="large" />
            <Input size="small" placeholder="small" variant="blended" />
            <Input size="medium" placeholder="medium" variant="blended" />
            <Input size="large" placeholder="large" variant="blended" />
          </ComponentSection>
        </div>
        <div style={{ display: "grid", gap: "1rem" }}>
          <Input startAdornment={<Icon name="time" />} />
          <Input startAdornment={<Icon name="time" />} value="Value" />
          <Input startAdornment={<Icon name="time" />} placeholder="Placeholder" />
          <Input startAdornment={<Icon name="time" />} value="Active" active />
          <Input startAdornment={<Icon name="time" />} value="Disabled" disabled />
          <Input startAdornment={<Icon name="time" />} error value="Error" />
          <Input startAdornment={<Icon name="time" />} readonly value="Readonly" />
        </div>
        <div style={{ display: "grid", gap: "1rem" }}>
          <Input variant="filled" />
          <Input variant="filled" value="Value" />
          <Input variant="filled" placeholder="Placeholder" />
          <Input variant="filled" value="Active" active />
          <Input variant="filled" value="Disabled" disabled />
          <Input variant="filled" error value="Error" />
          <Input variant="filled" readonly value="Readonly" />
        </div>
        <div>
          <Input style={{ width: "100px" }} />
        </div>
      </div>
    </>
  );
};
