import React from "react";
import { InfoCard } from "./InfoCard";
import { Layout } from "../Layout";

export const InfoCardExample = () => {
  return (
    <Layout.Group vertical style={{ width: 320 }}>
      <InfoCard title="Info">This is some serious info</InfoCard>
      <InfoCard title="Info" colorVariant="primary">
        This is some serious info
      </InfoCard>
      <InfoCard title="Info" colorVariant="success" onClose={() => alert("closed")}>
        This is some serious info
      </InfoCard>
      <InfoCard title="Info" colorVariant="danger">
        This is some serious info
      </InfoCard>
      <InfoCard title="Info" colorVariant="warning">
        This is some serious info
      </InfoCard>
    </Layout.Group>
  );
};
