import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Input, Icon, Focus } from "../../..";
import { MomentDates } from "./RangeCalendar";

export const DateInputs: React.VFC<{
  dates: MomentDates;
  onChangeDates: (dates: MomentDates) => void;
  setFocus: (focus: Focus) => void;
}> = ({ dates, onChangeDates, setFocus }) => {
  const startDate = useMemo(() => sanitizeDate(dates.startDate), [dates.startDate]);
  const endDate = useMemo(() => sanitizeDate(dates.endDate), [dates.endDate]);

  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(endDate);

  useEffect(() => {
    setStartValue(startDate);
    setEndValue(endDate);
  }, [startDate, endDate]);

  const onChangeValues = useCallback(
    (start: string | undefined = startValue, end: string | undefined = endValue) => {
      if (isValid(start) && isValid(end)) {
        onChangeDates({
          startDate: start === "" ? null : moment(start, "DD.MM.YYYY"),
          endDate: end === "" ? null : moment(end, "DD.MM.YYYY")
        });
      }

      setStartValue(start);
      setEndValue(end);
    },
    [setStartValue, setEndValue, startValue, endValue, onChangeDates]
  );

  const isEndBeforeStart = useMemo(() => {
    return (dates.endDate && dates.endDate.isBefore(dates.startDate)) ?? false;
  }, [dates]);

  return (
    <>
      <DateInput
        error={!isValid(startValue)}
        value={startValue}
        onChange={val => onChangeValues(sanitizeValue(val))}
        onClick={() => setFocus("startDate")}
      />
      -
      <DateInput
        error={!isValid(endValue) || isEndBeforeStart}
        value={endValue}
        onChange={val => onChangeValues(undefined, sanitizeValue(val))}
        onClick={() => setFocus("endDate")}
      />
    </>
  );
};

export const DateInput: React.VFC<React.ComponentProps<typeof Input>> = props => (
  <Input placeholder="DD.MM.YYYY" size="small" endAdornment={<Icon name="calendar" />} {...props} />
);

const CHARS_EXCEPT_DOT = /[^\.0-9]/g;

const sanitizeValue = (value: string) => {
  return value.replace(CHARS_EXCEPT_DOT, "");
};

const sanitizeDate = (date: moment.Moment | null) => {
  return date ? date.format("DD.MM.YYYY") : "";
};

const isValid = (value: string) => {
  const date = moment(value, "DD.MM.YYYY", true);

  return date.isValid() || value === "";
};
