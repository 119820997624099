import React, { FC, useState } from "react";
import { Accordion } from "./Accordion";
import styled from "styled-components";
import { H4, Title } from "v2/01-atoms";

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;

  > div {
    min-width: 32rem;

    > h4:first-of-type {
      margin-bottom: 0.5rem;
    }
  }
`;

export const AccordionExamples: FC = () => {
  return (
    <Wrapper>
      <div>
        <H4>Default</H4>
        <Accordion title="Title">
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>
      <div>
        <H4>Blended</H4>
        <Accordion blended toggleOpenOnHeaderClick title="Title">
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>

      <div>
        <H4>Compact</H4>
        <Accordion title="Title" compact>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>

      <div>
        <H4>Toggle open on header click</H4>
        <Accordion title="Title" toggleOpenOnHeaderClick>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>

      <div>
        <H4>On click</H4>
        <Accordion onClick={() => alert("Clicked!")} title="Title">
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>

      <div>
        <H4>On close</H4>
        <Accordion onClose={() => alert("closed!")} title="Title">
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>

      <div>
        <H4>Error state</H4>
        <Accordion title="Title" error="Error error everything is terrible!">
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>
      <div>
        <H4>Description</H4>
        <Accordion title="Title" description="Description">
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>
      <div>
        <H4>Description & Error</H4>
        <Accordion
          title="Title"
          description="description"
          error="Error error everything is terrible!"
        >
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
          <div>This is some mean lean content</div>
        </Accordion>
      </div>
    </Wrapper>
  );
};
