import React from "react";

import { SortDirection, useSortable } from "./useSortable";
import { Title } from "../../01-atoms";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { useTheme } from "../useTheme";
import { Select } from "../../02-blocks";

interface SortExampleData {
  name: string;
  description: string;
}

export const UseSortableExamples = () => {
  const {theme} = useTheme();


  const initialData: SortExampleData[] = [
    {name: "Test 1", description: "Some description"},
    {name: "Test 2", description: "More description"},
    {name: "Test 3", description: "Yet another desc"}
  ]

  const {data, sortedBy, setSortedBy, sortDir, setSortDir} = useSortable({initialData, sortedBy: "name"});

  return (
    <div>
      <ComponentSection title="Sorted data" description="Get your data sorted">
        <Select value={sortedBy} options={[{key: "name"}, {key: "description"}]} onSelect={(opt) => setSortedBy(opt.key)} />
        <Select value={sortDir} options={[{key: "asc"}, {key: "desc"}]} onSelect={(opt) => setSortDir(opt.key as SortDirection)} />

        {data.map(example => <Title key={example.name} style={{margin: theme.spacing.xSmall}}>{example.name} - {example.description}</Title>)}
      </ComponentSection>
    </div>
  );
};
