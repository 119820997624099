import React, { useState } from "react";
import { Checkbox, LabelledCheckbox } from ".";

export const CheckboxExamples = () => {
  const [checked, setChecked] = useState(false);
  return (
    <div style={{ display: "grid", gap: "2rem", gridAutoFlow: "column" }}>
      <div style={{ display: "grid", gap: "1rem" }}>
        <Checkbox checked={checked} onChange={setChecked} />
        <Checkbox checked={checked} onChange={setChecked} disabled />
        <Checkbox checked={!checked} onChange={() => setChecked(!checked)} />
        <Checkbox checked={checked} onChange={setChecked} readonly />
      </div>
      <div style={{ display: "grid", gap: "1rem" }}>
        <LabelledCheckbox label="Label" checked={checked} onChange={setChecked} />
        <LabelledCheckbox label="Label" checked={checked} onChange={setChecked} disabled />
        <LabelledCheckbox label="Label" checked={!checked} onChange={() => setChecked(!checked)} />
        <LabelledCheckbox label="Label" checked={checked} onChange={setChecked} readonly />
      </div>
    </div>
  );
};
