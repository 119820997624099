import { styled } from "../../styled";
import cx from "classnames";
import React, { ComponentProps } from "react";

import styles from "./P4.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface P4Props extends ComponentProps<"p"> {
  inverted?: boolean;
}

export const P4 = ({ inverted = false, ...props }: P4Props) => {
  return styled(
    "p",
    props,
    styles.P4,
    typographyStyles.Typography,
    cx({
      [typographyStyles.inverted]: inverted
    })
  );
};
