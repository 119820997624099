import React, { createRef, useCallback, useEffect, useState } from "react";
import { ComponentProps } from "react";
import styles from "./CollapsibleContent.module.scss";
import cx from "classnames";
interface Props extends ComponentProps<"div"> {
  open?: boolean;
}
export const CollapsibleContent = ({ open, ...rest }: Props) => {
  const contentRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState<string>("auto");
  const [contentHeight, setContentHeight] = useState<string>("0px");
  useEffect(() => {
    const contentHeight = `${contentRef.current!.offsetHeight}px`;
    setContentHeight(contentHeight);
  }, [contentRef]);
  const closeContent = useCallback(() => {
    setHeight(contentHeight);
    setTimeout(() => setHeight("0px"), 0);
  }, [contentHeight]);
  const openContent = useCallback(() => {
    setHeight(contentHeight);
    setTimeout(() => setHeight("auto"), 250);
  }, [contentHeight]);
  useEffect(() => {
    if (!open && height === "auto") return closeContent();
    if (open && height === "0px") return openContent();
  }, [open, height, closeContent, openContent]);

  return (
    <div className={cx(styles.CollapsibleContent, { [styles.Closed]: !open })} style={{ height }}>
      <div style={{ display: "inline-block", width: "100%" }} ref={contentRef} {...rest} />
    </div>
  );
};
