import React, { useState } from "react";
import { ButtonGroup } from "./ButtonGroup";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { useToast } from "../../03-hooks";

export const ButtonGroupExamples = () => {
  const toast = useToast();

  const [active, setActive] = useState(["all"]);
  const [active2, setActive2] = useState(["all"]);

  const toggleClicked = (key: string) => {
    if (active2.includes(key)) {
      setActive2(active2.filter(k => k !== key));
    } else {
      setActive2([...active2, key]);
    }
  };

  return (
    <div>
      <ComponentSection
        title="Basic button group"
        description="Buttons can be grouped when related"
        centerItems
      >
        <ButtonGroup
          active={active}
          onClick={key => toast.info(`Clicked ${key}`)}
          buttons={[
            {
              key: "all",
              children: "All",
              onClick: () => setActive(["all"])
            },
            {
              key: "starred",
              children: "Starred",
              onClick: () => setActive(["starred"])
            }
          ]}
        />
      </ComponentSection>
      <ComponentSection title="More buttons" description="Group can have any number of buttons">
        <ButtonGroup
          active={active2}
          size="small"
          onClick={key => toast.info(`Clicked ${key}`)}
          buttons={[
            {
              key: "all",
              children: "All",
              onClick: () => toggleClicked("all")
            },
            {
              key: "starred",
              children: "Starred",
              onClick: () => toggleClicked("starred")
            },
            {
              key: "more",
              children: "More",
              onClick: () => toggleClicked("more")
            },
            {
              key: "evenmore",
              children: "Even more",
              onClick: () => toggleClicked("evenmore")
            }
          ]}
        />
      </ComponentSection>
    </div>
  );
};
