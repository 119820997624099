import React from "react";
import { H1, P, Title } from "./01-atoms";

import styles from "./ExamplesHome.module.scss";
import { useTheme } from "./03-hooks";

export const ExamplesHome = () => {
  const { theme } = useTheme();

  return (
    <div className={styles.Root}>
      <div className={styles.Content}>
        <H1>Introist Foundation</H1>
        <Title style={{ color: theme.palette.foreground.subdued, marginTop: theme.spacing.large }}>
          Our collection of components to build web apps that are responsive and enjoyable to use.
        </Title>

        <div className={styles.Header}>
          <P>
            All our components are divided into groups by ideology loosely following{" "}
            <a href="https://bradfrost.com/blog/post/atomic-web-design/" target="_blank">
              Atomic Design
            </a>
            . Here's a brief overview of how the different categories are formed.
          </P>
        </div>

        <div className={styles.Section}>
          <Title variant="bold">Assets</Title>
          <P>
            Assets form the lowest level of components being basically visual assets to use. Most
            obvious example of such are Icons for which we have our own handcrafted set.
          </P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Atoms</Title>
          <P>
            Atoms are components that generally do not build on top of other components but utilise
            HTML tags directly. This category includes all those basic building blocks such as
            Button, Input and Typography.
          </P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Blocks</Title>
          <P>
            Blocks use Atoms to build higher order building blocks. Good example of a block is
            Select which uses Input as its normal state and combines it with OptionList to provide
            the "select" functionality.
          </P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Hooks</Title>
          <P>Hooks are our custom React hooks to make certain things more convenient to use.</P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Providers</Title>
          <P>
            Providers are our custom React Providers to wrap some actions such as Toast to be
            conveniently usable anywhere.
          </P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Templates</Title>
          <P>
            Where Atoms and Blocks are rather generic components, Templates are allowed to be more
            opinionated. Template usually provides one way to solve a certain need while definitely
            not being the only one.
          </P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Views</Title>
          <P>
            Views build even higher stack of lower level components. These inevitably are rather
            opinionated so it should be quite rare for a View to mature up to being included in the
            Foundation.
          </P>
        </div>
        <div className={styles.Section}>
          <Title variant="bold">Routes</Title>
          <P>
            As the cherry on the top, there are few Routes that are "plug-and-play" routes, ready to
            be attached to the application router. Pass the right data and the Route does it all.
          </P>
        </div>
      </div>
    </div>
  );
};
