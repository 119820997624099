import { styled } from "../../styled";
import cx from "classnames";
import React, { ComponentProps } from "react";

import styles from "./P.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface ParagraphProps extends ComponentProps<"p"> {
  size?: "regular" | "medium";
  inverted?: boolean;
}

export const P = ({ size = "regular", inverted = false, ...props }: ParagraphProps) => {
  return styled(
    "p",
    props,
    styles.P,
    styles[size],
    typographyStyles.Typography,
    cx({
      [typographyStyles.inverted]: inverted
    })
  );
};
