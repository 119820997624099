import React from "react";

import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Breadcrumb } from "./Breadcrumb";
import { useToast } from "../../03-hooks";
import { Icon } from "v2/00-assets";

export const BreadcrumbExamples = () => {
  const toast = useToast();

  return (
    <div>
      <ComponentSection
        title="Breadcrumb with clickable paths"
        description="Basic breadcrumb with paths being clickable"
        columns={1}
      >
        <Breadcrumb
          paths={[
            {
              key: "employees",
              title: "Employees",
              onClick: () => toast.info("Employees clicked")
            },
            { key: "employee", title: "John Doe", onClick: () => toast.info("John Doe clicked") },
            {
              key: "journey",
              title: "Onboarding",
              onClick: () => toast.info("Onboarding clicked"),
              startAdornment: <Icon name="folder" />
            }
          ]}
        />
      </ComponentSection>
    </div>
  );
};
