import "./index.css";

import React from "react";
import ReactDOM from "react-dom";

import { Route, Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { ExamplesRouter } from "./v2/examples";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/" render={route => <ExamplesRouter base={route.match.path} />} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
