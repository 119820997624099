import React from "react";
import styled, { keyframes } from "styled-components";

type SkeletonLoaderPreset = "h3" | "h4" | "title" | "button" | "buttonSmall" | "buttonLarge";
export type SkeletonLoaderProps = {
  height?: number;
  width?: number;
  rounding?: "none" | "small" | "medium" | "large" | "full";
  fadeOut?: boolean;
  element?: SkeletonLoaderPreset;
};

const skeletonAnimation = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
`;

const getPresetHeight = (preset: SkeletonLoaderPreset) => {
  switch (preset) {
    case "h3":
      return 36;
    case "h4":
      return 24;
    case "title":
      return 20;
    case "button":
      return 36;
    case "buttonSmall":
      return 30;
    case "buttonLarge":
      return 44;
    default:
      return undefined;
  }
};

const getPresetWidth = (preset: SkeletonLoaderPreset) => {
  switch (preset) {
    case "button":
      return 120;
    case "buttonSmall":
      return 100;
    case "buttonLarge":
      return 140;
    case "title":
      return 96;
    case "h4":
      return 192;
    case "h3":
      return 256;
    default:
      return undefined;
  }
};

const StyledSkeletonLoader = styled.div<{
  $fadeOut?: boolean;
  $rounding?: "none" | "small" | "medium" | "large" | "full";
}>`
  height: 1rem;
  background: ${p =>
    p.$fadeOut
      ? "linear-gradient(var(--palette-surface-default), transparent)"
      : "var(--palette-surface-default)"};
  animation: ${skeletonAnimation} 0.8s linear infinite alternate;

  ${({ $rounding }) => {
    switch ($rounding) {
      case "none":
        return `
          border-radius: 0;
        `;
      case "small":
        return `
          border-radius: var(--rounding-small);
        `;
      case "medium":
        return `
          border-radius: var(--rounding-medium);
        `;
      case "large":
        return `
          border-radius: var(--rounding-large);
        `;
      case "full":
        return `
          border-radius: 100%;
        `;
    }
  }}
`;

export const SkeletonLoader = ({
  height: heightProp,
  width: widthProp,
  element,
  rounding = "medium",
  fadeOut = false,
  ...rest
}: SkeletonLoaderProps) => {
  const height = heightProp || (element ? getPresetHeight(element) : undefined);
  const width = widthProp || (element ? getPresetWidth(element) : undefined);

  return (
    <StyledSkeletonLoader
      {...rest}
      $rounding={rounding}
      $fadeOut={fadeOut}
      style={{
        height,
        width
      }}
    />
  );
};
