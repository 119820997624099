import React, {
  ComponentProps,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Icon, IconName } from "../../00-assets/Icons";
import { Layout } from "../../01-atoms/Layout";
import { Title } from "../../01-atoms/Typography";
import { useTheme } from "../../03-hooks";
import { CircularLoader } from "../Loader";

type ButtonVariant = "filled" | "outlined" | "blended";
export type ButtonSize = "small" | "medium" | "large";

export type ButtonProps = Pick<
  ComponentProps<"button">,
  | "onClick"
  | "onMouseEnter"
  | "onMouseLeave"
  | "onMouseDown"
  | "disabled"
  | "style"
  | "key"
  | "className"
  | "id"
> & {
  children?: ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  active?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
  loading?: boolean;
  destructive?: boolean;
  to?: string;
  onClickWithLoading?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>;
};

type StyledButtonV2Props = {
  $variant: ButtonVariant;
  $active?: boolean;
  $size: ButtonSize;
  $destructive?: boolean;
  $disabled?: boolean;
  $hasStartAdornment?: boolean;
  $hasEndAdornment?: boolean;
};

const getSmallButtonStyle = (hasStartAdornment?: boolean, hasEndAdornment?: boolean) => {
  if (hasStartAdornment && hasEndAdornment) {
    return css`
      min-height: 30px;
      padding: 0.313rem 0.625rem;
    `;
  }

  if (hasStartAdornment) {
    return css`
      min-height: 30px;
      padding: 0.313rem 0.75rem;
      padding-left: 0.625rem;
    `;
  }

  if (hasEndAdornment) {
    return css`
      min-height: 30px;
      padding: 0.313rem 0.75rem;
      padding-right: 0.625rem;
    `;
  }

  return css`
    min-height: 30px;
    padding: 0.313rem 0.75rem;
  `;
};

const getMediumButtonStyle = (hasStartAdornment?: boolean, hasEndAdornment?: boolean) => {
  if (hasStartAdornment && hasEndAdornment) {
    return css`
      min-height: 36px;
      padding: 0.5rem 0.75rem;
    `;
  }

  if (hasStartAdornment) {
    return css`
      min-height: 36px;
      padding: 0.5rem 1rem;
      padding-left: 0.875rem;
    `;
  }

  if (hasEndAdornment) {
    return css`
      min-height: 36px;
      padding: 0.5rem 1rem;
      padding-right: 0.875rem;
    `;
  }

  return css`
    min-height: 36px;
    padding: 0.5rem 1rem;
  `;
};

const getLargeButtonStyle = (hasStartAdornment?: boolean, hasEndAdornment?: boolean) => {
  if (hasStartAdornment && hasEndAdornment) {
    return css`
      min-height: 44px;
      padding: 0.75rem 1.375rem;
    `;
  }

  if (hasStartAdornment) {
    return css`
      min-height: 44px;
      padding: 0.75rem 1.5rem;
      padding-left: 1.375rem;
    `;
  }

  if (hasEndAdornment) {
    return css`
      min-height: 44px;
      padding: 0.75rem 1.5rem;
      padding-right: 1.375rem;
    `;
  }

  return css`
    min-height: 44px;
    padding: 0.75rem 1.5rem;
  `;
};

const getVariantStyle = (variant: ButtonVariant, destructive?: boolean, active?: boolean) => {
  if (destructive && variant === "filled") {
    return css`
      background-color: var(--palette-danger-default);
      border-color: var(--palette-danger-default);

      :hover {
        background-color: var(--palette-danger-hovered);
      }

      :active {
        background-color: var(--palette-danger-pressed);
        border-color: var(--palette-danger-pressed);
      }
    `;
  }

  if (destructive && variant === "outlined") {
    return css`
      background-color: white;
      border-color: var(--palette-danger-default);

      :active {
        border-color: var(--palette-danger-pressed);
        background-color: var(--palette-surface-subdued);
      }
    `;
  }

  if (variant === "outlined") {
    return css`
      background-color: white;
      border-color: ${active
        ? "var(--palette-border-hovered) !important"
        : "var(--palette-border-subdued)"};

      :hover {
        border-color: var(--palette-border-pressed);
      }

      :active {
        background-color: var(--palette-surface-subdued);
        border-color: var(--palette-border-pressed);
      }
    `;
  }

  if (variant === "blended" && destructive) {
    return css`
      background-color: ${active ? "var(--palette-danger-ghosted) !important" : "transparent"};
      border-color: transparent;
      box-shadow: none;

      :hover {
        border-color: var(--palette-danger-ghosted);
        background-color: var(--palette-danger-ghosted);
      }

      :active {
        border-color: var(--palette-danger-dimmed);
        background-color: var(--palette-danger-dimmed);
      }
    `;
  }

  if (variant === "blended") {
    return css`
      background-color: ${active ? "var(--palette-foreground-ghosted) !important" : "transparent"};
      border-color: transparent;
      box-shadow: none;

      :hover {
        border-color: var(--palette-foreground-ghosted);
        background-color: var(--palette-foreground-ghosted);
      }

      :active {
        border-color: var(--palette-foreground-dimmed);
        background-color: var(--palette-foreground-dimmed);
      }
    `;
  }

  return css`
    background-color: var(--palette-primary-default);
    border-color: var(--palette-primary-default);

    :hover {
      background-color: var(--palette-primary-hovered);
    }

    :active {
      background-color: var(--palette-primary-pressed);
      border-color: var(--palette-primary-pressed);
    }
  `;
};

const StyledButtonV2 = styled.button<StyledButtonV2Props>`
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: var(--rounding-medium);
  box-shadow: 0px 1px 0px 0px var(--palette-border-dimmed);
  color: var(--palette-background-default);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
  }

  :active {
    transform: translateY(1px);
    box-shadow: none;
  }

  ${({ $size, $hasStartAdornment, $hasEndAdornment }) => {
    switch ($size) {
      case "small":
        return getSmallButtonStyle($hasStartAdornment, $hasEndAdornment);
      case "large":
        return getLargeButtonStyle($hasStartAdornment, $hasEndAdornment);
      default:
      case "medium":
        return getMediumButtonStyle($hasStartAdornment, $hasEndAdornment);
    }
  }}

  ${({ $variant, $destructive, $active }) => getVariantStyle($variant, $destructive, $active)};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    `}
`;

const StyledTitle = styled(Title)`
  line-height: 18px;
  display: flex;
`;

export const useButtonForegroundColor = (variant: ButtonVariant, destructive: boolean) => {
  const { theme } = useTheme();

  const foregroundColor = useMemo(() => {
    if (variant === "filled") {
      return theme.palette.background.default;
    }

    if (destructive && variant === "outlined") {
      return theme.palette.danger.default;
    }

    if (variant === "outlined") {
      return theme.palette.foreground.default;
    }

    if (variant === "blended") {
      if (destructive) {
        return theme.palette.danger.default;
      }
      return theme.palette.foreground.default;
    }
  }, [variant, destructive]);

  const loaderColor = useMemo(() => {
    if (variant === "outlined" || variant === "blended") {
      return theme.palette.foreground.dimmed;
    }

    return "white";
  }, [variant]);

  return { foregroundColor, loaderColor };
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "filled",
      size = "medium",
      destructive,
      disabled,
      children,
      startIcon,
      loading: loadingProp,
      to,
      active,
      startAdornment,
      endIcon,
      endAdornment,
      style,
      onClickWithLoading,
      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = useState(loadingProp);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (onClickWithLoading && !loading) {
        setLoading(true);
        onClickWithLoading(e).finally(() => setLoading(false));
      } else if (onClick && !loading) {
        onClick(e);
      }
    };

    const buttonPointerEvents = { onClick: handleClick, onMouseDown, onMouseEnter, onMouseLeave };

    const { foregroundColor, loaderColor } = useButtonForegroundColor(variant, !!destructive);

    const contentWidth = useRef<number | null>(null);

    useEffect(() => {
      setLoading(loadingProp);
    }, [loadingProp, setLoading]);

    const button = useMemo(() => {
      return (
        <StyledButtonV2
          {...(!to ? rest : {})}
          {...buttonPointerEvents}
          style={style}
          ref={ref}
          $size={size}
          $variant={variant}
          $destructive={destructive}
          $disabled={disabled}
          $active={active}
          $hasStartAdornment={!!startAdornment || !!startIcon}
          $hasEndAdornment={!!endAdornment || !!endIcon}
        >
          {loading ? (
            <Layout.Group
              justifyContent="center"
              style={{ width: contentWidth.current || undefined }}
            >
              <CircularLoader size="tiny" color={loaderColor} />
            </Layout.Group>
          ) : (
            <div
              ref={ref => (ref ? (contentWidth.current = ref.getBoundingClientRect().width) : null)}
            >
              {startIcon && <Icon name={startIcon} color={foregroundColor} />}
              {startAdornment && startAdornment}
              {children && (
                <StyledTitle variant="bold" color={foregroundColor}>
                  {children}
                </StyledTitle>
              )}
              {endIcon && <Icon name={endIcon} color={foregroundColor} />}
              {endAdornment && endAdornment}
            </div>
          )}
        </StyledButtonV2>
      );
    }, [
      to,
      loading,
      buttonPointerEvents,
      rest,
      ref,
      size,
      variant,
      destructive,
      disabled,
      children,
      startAdornment,
      endAdornment,
      style,
      endIcon,
      startIcon,
      foregroundColor,
      contentWidth
    ]);

    if (to) {
      return (
        <Link to={to} {...rest}>
          {button}
        </Link>
      );
    }

    return button;
  }
);
