import React from "react";
import cx from "classnames";

import styles from "./CircularLoader.module.scss";
import { Loader, LoaderProps, LoaderSize } from "../Loader";
import { useColorVariant } from "../../../03-hooks";

export interface CircularLoaderProps extends Omit<LoaderProps, "icon" | "color"> {
  colorVariant?: "primary" | "surface";
  color?: string;
}

export const CircularLoader = ({
  size,
  color: colorProp,
  colorVariant = "surface",
  ...rest
}: CircularLoaderProps) => {
  const color = useColorVariant(colorVariant);

  const finalSize = size || "medium";

  const compStyle = {
    borderColor: `${colorProp || color.foreground} transparent transparent transparent`
  };

  const icon = (
    <div className={cx(styles.CircularLoader, styles[finalSize])}>
      <div style={compStyle} />
      <div style={compStyle} />
      <div style={compStyle} />
      <div style={compStyle} />
    </div>
  );

  return <Loader icon={icon} size={finalSize as LoaderSize} {...rest} />;
};
