import React, { useState } from "react";
import { Toggle } from "./Toggle";
import { Layout } from "../Layout";
import { Title } from "../Typography";

export const ToggleExamples = () => {
  const [checked, setChecked] = useState<boolean>(true);

  return (
    <Layout.Group vertical>
      <Layout.Group justifyContent="space-between">
        <Title variant="bold">Default</Title>
        <Toggle checked={checked} onChange={setChecked} />
      </Layout.Group>
      <Layout.Group justifyContent="space-between">
        <Title variant="bold">Disabled</Title>
        <Toggle disabled checked={checked} onChange={setChecked} />
      </Layout.Group>
      <Layout.Group justifyContent="space-between">
        <Title variant="bold">Small</Title>
        <Toggle small checked={checked} onChange={setChecked} />
      </Layout.Group>
    </Layout.Group>
  );
};
