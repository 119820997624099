import React from "react";
import { useActions } from "..";
import { Button, H3 } from "../..";

const resolves = () => new Promise(res => setTimeout(res, 1000));
const rejects = () => new Promise((res, rej) => setTimeout(rej, 1000));

export const UseActionsExamples = () => {
  const { onAction, onConfirmAction } = useActions();

  const defaultOnActionResolves = onAction(resolves);
  const defaultOnActionRejects = onAction(rejects);
  const defaultOnConfirmActionResolves = onConfirmAction(resolves);
  const defaultOnConfirmActionRejects = onConfirmAction(rejects);

  const onActionCustomErrorMessage = onAction(rejects, "Whoops!!", true);
  const onActionCustomErrorHandler = onAction(
    rejects,
    () => {
      alert("Whoops");
    },
    true
  );

  const onConfirmActionCustom = onConfirmAction(
    rejects,
    {
      icon: "calendar",
      title: "Do you really want to book a meeting?",
      description: "We will have the meeting if you want...",
      confirmTitle: "Yes I do!",
      cancelTitle: "Nope"
    },
    { title: "Cannot book a meeting", message: "I am not available. Try another day." }
  );

  return (
    <div style={{ display: "grid", gap: "2rem" }}>
      <div style={{ display: "grid", gap: "1rem" }}>
        <H3>Defaults</H3>
        <div style={{ display: "grid", gap: ".5rem" }}>
          <Button onClickWithLoading={defaultOnActionResolves}>default onAction, resolves</Button>
          <Button destructive onClickWithLoading={defaultOnActionRejects}>
            default onAction, rejects
          </Button>
          <Button onClick={defaultOnConfirmActionResolves}>
            default onConfirmAction, resolves
          </Button>
          <Button destructive onClick={defaultOnConfirmActionRejects}>
            default onConfirmAction, rejects
          </Button>
        </div>
      </div>
      <div style={{ display: "grid", gap: "1rem" }}>
        <H3>Defaults</H3>
        <div style={{ display: "grid", gap: ".5rem" }}>
          <Button destructive onClickWithLoading={onActionCustomErrorMessage}>
            custom onAction with error message, rejects
          </Button>
          <Button destructive onClickWithLoading={onActionCustomErrorHandler}>
            custom onAction with error handler, rejects
          </Button>
          <Button destructive onClick={onConfirmActionCustom}>
            custom onConfirmAction, rejects
          </Button>
        </div>
      </div>
    </div>
  );
};
