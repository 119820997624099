import React, { ComponentProps } from "react";
import { NavSection } from "./NavSection";

export interface NavItem {
  key: string;
  section: string;
  href?: string;
  title?: string;
}

export interface CollapsibleNavProps extends ComponentProps<"div"> {
  items: NavItem[];
  selectedKey?: string;
}

const isUnique = (val: string, index: number, self: any) => self.indexOf(val) === index;

export const CollapsibleNav = ({ items, selectedKey, ...rest }: CollapsibleNavProps) => {
  const sections = items.map(i => i.section).filter(isUnique);
  return (
    <div {...rest}>
      {sections.map(section => (
        <NavSection
          key={section}
          items={items.filter(i => i.section === section)}
          selectedKey={selectedKey}
        />
      ))}
    </div>
  );
};
