import React, { ComponentProps, forwardRef } from "react";
import styled, { css } from "styled-components";

export interface ICardProps extends Omit<ComponentProps<"div">, "ref"> {
  variant?: "blended" | "default" | "active";
  mode?: "light" | "dark";
  hoverable?: boolean;
  elevated?: boolean;
  spacing?: "medium" | "large" | "xLarge";
}

type TStyledCardProps = {
  $variant: ICardProps["variant"];
  $mode: ICardProps["mode"];
  $hoverable: ICardProps["hoverable"];
  $elevated: ICardProps["elevated"];
  $spacing: ICardProps["spacing"];
  $clickable?: boolean;
};

const StyledCard = styled.div<TStyledCardProps>`
  border-radius: var(--rounding-medium);
  box-sizing: border-box;

  ${({ $spacing }) => {
    switch ($spacing) {
      case "medium":
        return css`
          padding: var(--spacing-medium);
        `;
      case "xLarge":
        return css`
          padding: var(--spacing-xLarge);
        `;
      default:
      case "large":
        return css`
          padding: var(--spacing-large);
        `;
    }
  }}

  ${({ $variant }) => {
    switch ($variant) {
      case "active":
        return css`
          border: 1px solid var(--palette-primary-default);
        `;
      case "blended":
        return css`
          border: 1px dashed var(--palette-border-default);
        `;
      case "default":
      default:
        return css`
          border: 1px solid var(--palette-border-subdued);
        `;
    }
  }}

  ${({ $mode }) => {
    if ($mode === "dark") {
      return css`
        background: var(--palette-foreground-default);
        color: var(--palette-background-default);
        border-color: transparent;
      `;
    } else {
      return css`
        background: var(--palette-background-default);
        color: var(--palette-foreground-default);
      `;
    }
  }}

  ${({ $hoverable }) =>
    $hoverable &&
    css`
      transition: background-color 160ms ease;
      :hover {
        background: var(--palette-surface-dimmed);
      }
    `};

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `};

  ${({ $elevated }) =>
    $elevated &&
    css`
      box-shadow: var(--shadow-default);
    `}
`;

export const Card = forwardRef<HTMLDivElement, ICardProps>(
  (
    {
      variant = "default",
      hoverable = false,
      elevated = false,
      mode = "light",
      spacing = "large",
      onClick,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledCard
        {...rest}
        $spacing={spacing}
        $variant={variant}
        $hoverable={hoverable}
        $clickable={!!onClick}
        $elevated={elevated}
        $mode={mode}
        onClick={onClick}
      >
        {children}
      </StyledCard>
    );
  }
);
