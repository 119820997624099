import React from "react";
import styled, { css } from "styled-components";

type ProductTagProps = {
  minimized?: boolean;
  size?: "small" | "medium";
};

export const StyledProductTag = styled.span<{
  $title?: string;
  $minimized?: boolean;
  $size?: "small" | "medium";
}>`
  display: block;
  height: 1rem;
  padding: 0 0.25rem;
  background-color: var(--palette-primary-default);
  border-radius: var(--rounding-small);
  cursor: default;

  ${({ $title }) => {
    if ($title === "NEW") {
      return css`
        background-color: var(--palette-primary-default);
        ::after {
          content: "NEW";
        }
      `;
    }
    if ($title === "BETA") {
      return css`
        background-color: var(--palette-purple-default);
        ::after {
          content: "BETA";
        }
      `;
    }
    if ($title === "ALPHA") {
      return css`
        background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);

        ::after {
          content: "ALPHA";
        }
      `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          height: 1rem;
          padding: 0 0.25rem;
          ::after,
          span {
            line-height: 1rem;
            font-size: 9px;
          }
        `;
      default:
        return css`
          height: 1.5rem;
          padding: 0 0.5rem;
          border-radius: var(--rounding-medium);

          ::after,
          span {
            line-height: 1.5rem;
          }
        `;
    }
  }}

  ::after, span {
    font-family: var(--typography-font-text);
    letter-spacing: 0.01em;
    display: block;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
  }

  ${({ $minimized }) =>
    $minimized &&
    css`
      padding: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      ::after {
        content: none !important;
      }
    `}
`;

export const NewTag = ({ minimized, size = "small" }: ProductTagProps) => (
  <StyledProductTag $minimized={minimized} $size={size} $title="NEW" />
);

export const BetaTag = ({ minimized, size = "small" }: ProductTagProps) => (
  <StyledProductTag $minimized={minimized} $size={size} $title="BETA" />
);

export const AlphaTag = ({ minimized, size = "small" }: ProductTagProps) => (
  <StyledProductTag $minimized={minimized} $size={size} $title="ALPHA" />
);

export const ProductTag = ({
  title,
  size = "small",
  minimized,
  ...rest
}: ProductTagProps & { title: string }) => (
  <StyledProductTag {...rest} $size={size} $minimized={minimized}>
    <span>{title}</span>
  </StyledProductTag>
);
