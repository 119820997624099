import { useTheme } from "../useTheme";
import { useMediaQuery } from "react-responsive";

export const useResponsive = () => {
  const { theme } = useTheme();

  const mobileDownQuery = `(max-width: ${theme.breakpoints.mobileDown})`;

  const isMobileLayout = useMediaQuery({
    query: mobileDownQuery
  });

  const desktopUpQuery = `(min-width: ${theme.breakpoints.mobileDown})`;

  const isDesktopLayout = useMediaQuery({
    query: desktopUpQuery
  });

  return { mobileDownQuery, isMobileLayout, isDesktopLayout, desktopUpQuery };
};
