import { styled } from "../../styled";
import cx from "classnames";
import { ComponentProps } from "react";

import styles from "./H3.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface H3Props extends ComponentProps<"h3"> {
  inverted?: boolean;
}

export const H3 = ({ inverted = false, ...props }: H3Props) => {
  return styled(
    "h3",
    props,
    styles.H3,
    typographyStyles.Typography,
    cx({ [typographyStyles.inverted]: inverted })
  );
};
