import { styled } from "../../styled";
import cx from "classnames";
import React, { ComponentProps } from "react";

import styles from "./H1.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface H1Props extends ComponentProps<"h1"> {
  inverted?: boolean;
}

export const H1 = ({ inverted = false, ...props }: H1Props) => {
  return styled(
    "h1",
    props,
    styles.H1,
    typographyStyles.Typography,
    cx({ [typographyStyles.inverted]: inverted })
  );
};
