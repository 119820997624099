import { styled } from "../../styled";
import cx from "classnames";
import { ComponentProps } from "react";

import styles from "./H4.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface H4Props extends ComponentProps<"h4"> {
  inverted?: boolean;
}

export const H4 = ({ inverted = false, ...props }: H4Props) => {
  return styled(
    "h4",
    props,
    styles.H4,
    typographyStyles.Typography,
    cx({ [typographyStyles.inverted]: inverted })
  );
};
