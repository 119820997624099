import React, { useState } from "react";
import { useTheme } from "../useTheme";
import { useOutsideClick } from "./useOutsideClick";

export const UseOutsideClickExamples = () => {
  const { theme } = useTheme();

  const [text, setText] = useState("Click inside or outside");
  const [rootElem, setRootElem] = useState<HTMLElement | null>(null);

  useOutsideClick([rootElem], () => {
    setText("Clicked outside");
  });

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div
        ref={setRootElem}
        style={{
          border: `1px solid ${theme.palette.border.default}`,
          padding: theme.spacing.medium
        }}
        onClick={() => setText("Clicked inside")}
      >
        {text}
      </div>
    </div>
  );
};
