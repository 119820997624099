import { styled } from "../../styled";
import cx from "classnames";
import React, { ComponentProps } from "react";

import styles from "./H2.module.scss";
import typographyStyles from "../Typography.module.scss";

export interface H2Props extends ComponentProps<"h2"> {
  inverted?: boolean;
}

export const H2 = ({ inverted = false, ...props }: H2Props) => {
  return styled(
    "h2",
    props,
    styles.H2,
    typographyStyles.Typography,
    cx({ [typographyStyles.inverted]: inverted })
  );
};
