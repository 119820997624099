import React, { ReactNode } from "react";
import { Stack, Title } from "../../01-atoms";
import styled from "styled-components";

interface EmptyStateProps {
  emptyState: string | ReactNode;
  colspan: number;
}

const StyledEmptyState = styled(Stack)`
  padding: var(--spacing-xxLarge);

  h5 {
    word-wrap: pre-wrap;
  }
`;

const EmptyStateWrapper = styled.div`
  h5 {
    white-space: pre-wrap !important;
  }
`;

export const EmptyState = ({ emptyState, colspan }: EmptyStateProps) => {
  const emptyStateAsMessage = (
    <StyledEmptyState justifyContent="center">
      <Title>{emptyState}</Title>
    </StyledEmptyState>
  );
  return (
    <tr>
      <td colSpan={colspan}>
        {typeof emptyState === "string" ? (
          emptyStateAsMessage
        ) : (
          <EmptyStateWrapper>{emptyState}</EmptyStateWrapper>
        )}
      </td>
    </tr>
  );
};
