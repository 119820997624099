import React, { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useTransition, config, a } from "@react-spring/web";
import chroma from "chroma-js";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

import { CloseIconButton } from "../IconButton";
import { H4 } from "../../01-atoms";
import { Backdrop } from "../../00-assets";
import { TElevationName } from "v2/04-providers";

export type ModalV2Props = {
  open: boolean;
  title?: string;
  titleStartAdornment?: ReactNode;
  titleEndAdornment?: ReactNode;
  hideCloseButton?: boolean;
  onClose?: VoidFunction;
  children?: ReactNode;
  preContent?: ReactNode;
  afterContent?: ReactNode;
  maxWidth?: number;
  maxHeight?: number;
  footer?: ReactNode;
  disableCloseOnOutsideClick?: boolean;
  elevation?: TElevationName;
  fullWidth?: boolean;
};

const SHADOW = {
  open: `${chroma("#0C0A14").alpha(0.22).hex()} 0px 1px 8px -1px, ${chroma("#0C0A14").alpha(
    0.22
  )} 0px 32px 64px -9px`,
  closed: `${chroma("#0C0A14").alpha(0).hex()} 0px 0px 0px -1px, ${chroma("#3e6de5").alpha(
    0
  )} 0px 1px 1px 4px`
};

const StyledModalV2 = styled.div<{ $maxWidth: number; $maxHeight: number; $fullWidth?: boolean }>`
  width: 100%;
  max-width: ${p => p.$maxWidth}px;
  max-height: ${p => p.$maxHeight}vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: var(--rounding-medium);
  background-color: var(--palette-background-default);
  padding: var(--spacing-large);

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      max-width: 100vw;
    `}
`;

const AnimatedModalV2 = a(StyledModalV2);

const ModalHeader = styled.header<{ $justifyContentEnd: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${p => (p.$justifyContentEnd ? "flex-end" : "space-between")};
  margin-bottom: var(--spacing-large);
`;

const Content = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`;

const Footer = styled.footer`
  margin-top: var(--spacing-xxLarge);
`;

const PreContent = styled.div`
  padding-bottom: var(--spacing-large);
`;

const AfterContent = styled.div`
  padding-top: var(--spacing-large);
`;

export const ModalV2 = ({
  title,
  titleStartAdornment,
  titleEndAdornment,
  children,
  open,
  footer,
  maxHeight = 80,
  preContent,
  afterContent,
  maxWidth = 512,
  disableCloseOnOutsideClick,
  hideCloseButton,
  fullWidth,
  elevation = "earth",
  onClose,
  ...rest
}: ModalV2Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const transitions = useTransition(open, {
    from: {
      opacity: 0,
      transform: "scale(0.95)",
      boxShadow: SHADOW.closed
    },
    enter: {
      opacity: 1,
      transform: "scale(1)",
      boxShadow: SHADOW.open
    },
    leave: {
      opacity: 0,
      transform: "scale(0.95)",
      boxShadow: SHADOW.closed
    },
    config: { ...config.stiff, clamp: true, tension: 260 }
  });

  const hasTitle = !!title || !!titleEndAdornment || !!titleStartAdornment;
  const showHeader = hasTitle || !!onClose;

  useEffect(() => {
    if (open) {
      ref.current && disableBodyScroll(ref.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [open]);

  return transitions((style, tOpen) =>
    tOpen ? (
      <Backdrop
        isGlobal
        open={tOpen}
        onClick={() => {
          !disableCloseOnOutsideClick && onClose && onClose();
        }}
      >
        <AnimatedModalV2
          {...rest}
          style={style}
          $maxWidth={maxWidth}
          $maxHeight={maxHeight}
          $fullWidth={fullWidth}
          ref={ref}
        >
          {showHeader && (
            <ModalHeader $justifyContentEnd={!hasTitle}>
              {title && <H4>{title}</H4>}
              {onClose && <CloseIconButton onClick={onClose} />}
            </ModalHeader>
          )}

          {preContent && <PreContent>{preContent}</PreContent>}
          <Content>{children}</Content>
          {afterContent && <AfterContent>{afterContent}</AfterContent>}
          {footer && <Footer>{footer}</Footer>}
        </AnimatedModalV2>
      </Backdrop>
    ) : null
  );
};
