import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { ErrorMessage, Title } from "../../01-atoms/Typography";

export interface IFieldProps {
  error?: string;
  title?: string;
  required?: boolean;
  children?: ReactNode;
}

const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Label = styled(Title)`
  > span {
    color: var(--palette-foreground-subdued);
  }
`;

export const Field: FC<IFieldProps> = ({ error, title, children, required, ...restProps }) => {
  return (
    <StyledField {...restProps}>
      {title && (
        <Label>
          {title}
          {required && <span>*</span>}
        </Label>
      )}
      {children}
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledField>
  );
};
