import React, { useState } from "react";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { Toggle } from "../Toggle/Toggle";
import { Title } from "../Typography";
import { CollapsibleContent } from "./CollapsibleContent";

export const CollapsibleContentExamples = () => {
  const [open, setOpen] = useState<boolean[]>([true, true]);
  return (
    <div style={{ display: "grid", gap: "7.5rem", gridTemplateColumns: "1fr" }}>
      <ComponentSection
        title="Basic collapsible content"
        description="Component section uses collapsible content"
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <Toggle
            checked={!open[0]}
            onChange={() => {
              const [current, ...rest] = open;
              setOpen([!current, ...rest]);
            }}
          />
          <Title>Collapse first</Title>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Toggle
            checked={!open[1]}
            onChange={value => {
              setOpen(open.map(_ => !value));
            }}
          />
          <Title>Collapse all</Title>
        </div>

        <CollapsibleContent open={open[0]}>
          <div>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
          </div>
        </CollapsibleContent>
        <CollapsibleContent open={open[1]}>
          <div>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
            <Title>
              What ever you put inside collapsible content, it can be toggled out and in
            </Title>
          </div>
        </CollapsibleContent>
      </ComponentSection>
    </div>
  );
};
