import React, { useMemo } from "react";
import { Icon, IconProps } from "../../00-assets/Icons";
import { ColorVariants } from "../../04-providers";
import styles from "./IconTag.module.scss";
import { useTheme } from "../../03-hooks";
import cx from "classnames";

export type IconTagVariants = "ghosted" | "filled" | "blended";
export interface IconTagProps extends Omit<IconProps, "color"> {
  color: ColorVariants;
  variant?: IconTagVariants;
  inverted?: boolean;
  size?: "small" | "medium";
}

export const IconTag = ({
  name,
  color,
  inverted = false,
  variant = "ghosted",
  size = "medium",
  ...props
}: IconTagProps) => {
  const { theme } = useTheme();

  const wrapperVariantStyles: Record<IconTagVariants, React.CSSProperties> = useMemo(
    () => ({
      filled: {
        background: color.default
      },
      ghosted: {
        background: color.ghosted
      },
      blended: {
        background: "transparent"
      }
    }),
    [theme.palette, color]
  );

  const iconVariantColor: Record<IconTagVariants, string> = useMemo(
    () => ({
      filled: inverted ? "black" : theme.palette.background.default,
      ghosted: color.default,
      blended: color.default
    }),
    [theme.palette, color]
  );

  const className = cx(styles.Root, {
    [styles.Small]: size === "small",
    [styles.Medium]: size === "medium"
  });

  return (
    <div className={className} style={wrapperVariantStyles[variant]} {...props}>
      <Icon name={name} color={iconVariantColor[variant]} />
    </div>
  );
};
