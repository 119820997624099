import React, { ComponentProps, useState } from "react";
import { NavItem } from "./CollapsibleNav";
import { CollapsibleContent, Title } from "../../01-atoms";

import { Link } from "react-router-dom";

import styles from "./CollapsibleNav.module.scss";
import cx from "classnames";
import { Icon } from "../../00-assets";

export interface NavSectionProps extends ComponentProps<"div"> {
  items: NavItem[];
  selectedKey?: string;
}

export const NavSection = ({
  items,
  className,
  selectedKey,
  onSelect,
  ...rest
}: NavSectionProps) => {
  if (items.length === 0) return null;
  const sectionTitle = items[0].section;

  const [expanded, setExpanded] = useState(
    localStorage.getItem(`intrfnd.${sectionTitle}.open`) === "true"
  );

  const toggleExpanded = () => {
    const newExpanded = !expanded;
    localStorage.setItem(`intrfnd.${sectionTitle}.open`, newExpanded.toString());
    setExpanded(newExpanded);
  };

  return (
    <div className={cx(styles.NavSection, className)} {...rest}>
      <SectionHeader title={sectionTitle} expanded={expanded} onClick={toggleExpanded} />
      <CollapsibleContent open={expanded}>
        <div className={styles.Content}>
          {items.map(i => (
            <NavItemComp key={i.key} item={i} selected={selectedKey === i.key} />
          ))}
        </div>
      </CollapsibleContent>
    </div>
  );
};

const SectionHeader = ({
  title,
  expanded,
  onClick
}: {
  title: string;
  expanded?: boolean;
  onClick: () => unknown;
}) => (
  <div className={styles.NavHeader} onClick={onClick}>
    <Title variant="bold" style={{ color: "white" }}>
      {title}
    </Title>
    <Icon name="chevronDown" className={cx({ [styles.open]: expanded })} color="white" />
  </div>
);

const NavItemComp = ({ item, selected }: { item: NavItem; selected?: boolean }) => (
  <Link to={item.href || ""} className={cx(styles.NavItem, { [styles.Selected]: selected })}>
    <Title style={{ color: selected ? "white" : "rgba(255,255,255,.7)" }}>
      {item.key || item.title}
    </Title>
  </Link>
);
