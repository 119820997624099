import React, { CSSProperties, forwardRef, ReactNode, useState } from "react";
import { Checkbox, Indicator, Title } from "..";
import styles from "./OptionListItem.module.scss";
import cx from "classnames";
import { Icon } from "../../00-assets";
import { useTheme } from "../../03-hooks";

export interface OptionListItemProps {
  selected?: boolean;
  disabled?: boolean;
  label: string;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  selectable?: boolean;
  onClick?: (e: React.MouseEvent<any>) => unknown;
  onRemove?: () => unknown;
  onEdit?: () => unknown;
  markSelected?: boolean;
  showIndicator?: boolean;
  labelStyles?: CSSProperties;
  search?: string;
  disableHoverStyle?: boolean;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

interface HighlightTextProps {
  text: string;
  search: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, search }) => {
  const { theme } = useTheme();
  if (!search) return <>{text}</>;

  const regex = new RegExp(`(${search})`, "gi");
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          style={
            part.toLowerCase() === search.toLowerCase()
              ? {
                  backgroundColor: theme.palette.primary.ghosted,
                  color: theme.palette.primary.default,
                  borderRadius: "2px"
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </>
  );
};

export const OptionListItem = forwardRef<HTMLButtonElement, OptionListItemProps>(
  (
    {
      selected,
      disabled,
      label,
      startAdornment,
      selectable,
      endAdornment,
      onClick,
      onRemove,
      onEdit,
      onMouseEnter,
      onMouseLeave,
      markSelected = true,
      showIndicator = true,
      disableHoverStyle = false,
      labelStyles,
      search
    },
    ref
  ) => {
    const { theme } = useTheme();
    const [isMouseOver, setIsMouseOver] = useState(false);

    const stateStyles = cx({
      [styles.Disabled]: disabled,
      [styles.Clickable]: !!onClick,
      [styles.DisableHoverStyle]: !!disableHoverStyle
    });
    const handleClick = (e: React.MouseEvent<any>) => {
      !disabled && onClick && onClick(e);
      e.preventDefault();
      e.stopPropagation();
    };

    const handleEditClick = (e: React.MouseEvent<any>) => {
      onEdit && onEdit();
      e.preventDefault();
      e.stopPropagation();
    };

    const handleRemoveClick = (e: React.MouseEvent<any>) => {
      onRemove && onRemove();
      e.preventDefault();
      e.stopPropagation();
    };

    const hasAction = onRemove || onEdit;
    const hasStartAdornment = !!startAdornment;
    const hasEndAdornment = !!endAdornment;

    const showEndAdornment = hasEndAdornment && (!hasAction || !isMouseOver);
    const showCheckmark =
      selected && !selectable && !hasEndAdornment && markSelected && !(hasAction && isMouseOver);

    const showRemove = !disabled && onRemove && isMouseOver;
    const showEdit = !disabled && onEdit && isMouseOver;

    return (
      <button
        ref={ref}
        className={cx(styles.OptionListItem, stateStyles)}
        onClick={handleClick}
        onMouseEnter={() => {
          onMouseEnter && onMouseEnter();
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          onMouseLeave && onMouseLeave();
          setIsMouseOver(false);
        }}
      >
        {selected && showIndicator && !selectable && (
          <Indicator className={styles.Indicator} color={theme.palette.primary} vertical />
        )}
        <div className={styles.TitleWrapper}>
          {selectable && (
            <Checkbox
              className={styles.Checkbox}
              checked={!!selected}
              onChange={(_, e) => handleClick(e)}
              disabled={disabled}
            />
          )}
          {hasStartAdornment && (
            <span className={cx(styles.Adornment, styles.Start, stateStyles)}>
              {startAdornment}
            </span>
          )}
          <Title className={cx(styles.Title, stateStyles)} style={labelStyles}>
            {search ? <HighlightText search={search} text={label} /> : label}
          </Title>
        </div>

        <div className={styles.ActionWrapper}>
          {showEdit && (
            <Icon name="pencil" onClick={handleEditClick} className={styles.ActionButton} />
          )}
          {showRemove && (
            <Icon name="crossSmall" onClick={handleRemoveClick} className={styles.ActionButton} />
          )}
          {showEndAdornment && (
            <span className={cx(styles.Adornment, stateStyles)}>{endAdornment}</span>
          )}
          {showCheckmark && <Icon color={theme.palette.primary.default} name="check" />}
        </div>
      </button>
    );
  }
);
