import React from "react";
import { ComponentSection } from "../../09-routes/ComponentExamplesRoute/ComponentSection";
import { TextArea } from "./TextArea";

export const TextAreaExamples = () => {
  return (
    <div style={{ display: "grid", gap: "7.5rem", gridTemplateColumns: "1fr" }}>
      <ComponentSection
        title="Basic textarea"
        description="Plain and simple Textarea component"
        columns={1}
      >
        <TextArea />
        <TextArea value="Value" />
        <TextArea placeholder="Placeholder" />
        <TextArea value="Active" active />
        <TextArea value="Disabled" disabled />
        <TextArea value="Error" error />
        <TextArea value="Readonly" readonly />
      </ComponentSection>
      <ComponentSection
        title="Blended textarea"
        description="Blended Textarea component to blend in wherever we want!"
        columns={1}
      >
        <TextArea variant="blended" />
        <TextArea value="Value" variant="blended" />
        <TextArea placeholder="Placeholder" variant="blended" />
        <TextArea value="Active" active variant="blended" />
        <TextArea value="Disabled" disabled variant="blended" />
        <TextArea value="Error" error variant="blended" />
        <TextArea value="Readonly" readonly variant="blended" />
      </ComponentSection>
    </div>
  );
};
