import React, { ComponentProps, createRef, useState } from "react";

import styles from "./ComponentSection.module.scss";
import { CollapsibleContent, styled, Title } from "../../01-atoms";
import cx from "classnames";
import { Icon } from "../../00-assets";
import { useTheme } from "../../03-hooks";
import { IconButton } from "../../02-blocks";

export interface ComponentSectionProps extends ComponentProps<"div"> {
  title: string;
  description?: string;
  gap?: boolean;
  columns?: number;
  centerItems?: boolean;
}
export const ComponentSection = ({
  title,
  description,
  className,
  children,
  gap = true,
  columns = 1,
  centerItems,
  ...rest
}: ComponentSectionProps) => {
  const { theme } = useTheme();

  const [closed, setClosed] = useState<boolean>(false);

  return (
    <Block
      className={cx(styles.CollapsibleBlock, className, { [styles.Closed]: closed })}
      {...rest}
    >
      <div className={cx(styles.Header)} onClick={() => setClosed(!closed)}>
        <div>
          <Title variant="bold">{title}</Title>
          {description && (
            <Title style={{ color: theme.palette.foreground.subdued }}>{description}</Title>
          )}
        </div>
        <IconButton icon="chevronDown" />
      </div>
      <CollapsibleContent open={!closed}>
        <div
          className={cx(styles.Content, { [styles.Gap]: gap, [styles.centerItems]: centerItems })}
          style={{ gridTemplateColumns: Array(columns).fill(`1fr`).join(" ") }}
        >
          {children}
        </div>
      </CollapsibleContent>
    </Block>
  );
};

export interface BlockProps extends ComponentProps<"div"> {
  blended?: boolean;
}

export const Block = ({ blended, ...props }: BlockProps) =>
  styled("div", props, cx(styles.Block, { [styles.Blended]: !!blended }));
