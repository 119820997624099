import styled, { css } from "styled-components";

type TAlignment = "flex-start" | "flex-end" | "space-between" | "center" | "stretch";
type TSpacing = "xSmall" | "small" | "medium" | "large" | "xLarge" | "xxLarge" | "none";

type TGroupStyledProps = {
  vertical?: boolean;
  justifyContent?: TAlignment;
  alignSelf?: TAlignment;
  alignItems?: TAlignment;
  gap?: TSpacing;
  lastChildToEnd?: boolean;
  flexWrap?: boolean;
};

const getGap = (spacing?: TSpacing) => {
  switch (spacing) {
    case "large":
      return css`
        gap: var(--spacing-large);
      `;
    case "small":
      return css`
        gap: var(--spacing-small);
      `;
    case "xLarge":
      return css`
        gap: var(--spacing-xLarge);
      `;
    case "xSmall":
      return css`
        gap: var(--spacing-xSmall);
      `;
    case "xxLarge":
      return css`
        gap: var(--spacing-xxLarge);
      `;
    case "medium":
    default:
      return css`
        gap: var(--spacing-medium);
      `;
  }
};

const Group = styled.div<TGroupStyledProps>`
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  ${({ gap }) => gap !== "none" && getGap(gap)};

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}

  ${({ vertical, alignItems }) =>
    vertical &&
    css`
      align-items: ${alignItems ? alignItems : "stretch"};
      flex-direction: column;
    `}

    svg {
    flex-shrink: 0;
  }

  ${({ lastChildToEnd, vertical }) => {
    if (lastChildToEnd && vertical) {
      return css`
        > :last-child:not(:first-child) {
          align-self: flex-end;
        }
      `;
    }
    if (lastChildToEnd && !vertical) {
      return css`
        > :last-child:not(:first-child) {
          margin-left: auto;
        }
      `;
    }
  }}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: wrap;
    `}
`;

const Divider = styled.span`
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--palette-border-subdued);
`;

export const scrollListStyle = css`
  overflow: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  :-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  :hover {
    overflow-y: auto;
  }
`;

export const Layout = {
  Divider,
  Group
};
